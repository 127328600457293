import { Component, ElementRef, OnInit, Optional, ViewChild,ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WbsModalComponent } from '../wbs-modal/wbs-modal.component';
import { WBSRecords } from '../wbs-modal/wbs-records.interface';
import { environment } from '../../environments/environment';
import { AppInsightsService } from '../app-insights.service';
import { Observable, of, from } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AuthService } from '../auth.service';
import { UserService } from '../auth.details';
import { SpinnerServiceService } from '../services/spinner-service.service';
import { AlertPopupComponent } from '../notify-popup/notify-popup.component';
import { GlobalHelperServiceService } from '../services/global-helper-service.service';
import { CommonHelperService } from '../services/common-helper.service';
import { debounceTime } from 'rxjs/operators';
import { WindowRefService } from '../services/window.service';
@Component({
  selector: 'app-request-form',
  templateUrl: './request-form.component.html',
  styleUrl: './request-form.component.css',
})
export class RequestFormComponent implements OnInit {
  isAdmin!: boolean;
  showExtendedFields = false;
  isGlobalwbscode: boolean = false;
  validateWBSCode: boolean = false;
  mfengagementname: boolean = false;
  mfengagementpartnername: boolean = false;
  mfwbscode: boolean = false;
  previousDateValue: string | null = null;
  isUserInitiated: boolean = false;
  showNoResults:boolean=false;
  requestForm = new FormGroup({
    serviceOrganization: new FormControl(''),
    serviceSector: new FormControl(''),
    serviceAuditor: new FormControl(''),
    application: new FormControl(''),
    typeOfAudit: new FormControl(''),
    LocationsCoveredInReport: new FormControl(''),
    typeOfOpinion: new FormControl(''),
    reportPeriodStartDate: new FormControl(''),
    reportPeriodEndDate: new FormControl(''),
    SARAuthoritativeGuidance: new FormControl(''),
    SARAuthoritativeGuidanceDropDown: new FormControl(''),
    SARAuthoritativeGuidanceText: new FormControl(''),
    SARAuthoritativeGuidanceChecked: new FormControl(''),
    SARReportType: new FormControl(''),
    requestorsName: new FormControl(''),
    requestorsNameLotus: new FormControl(''),
    businessContact: new FormControl(''),
    requestDate: new FormControl(''),
    needByDate: new FormControl(''),
    commentsForWorkflow: new FormControl(''),
    idNumber: new FormControl(''),
    emphasisOfMatter: new FormControl(''),
    WBS: new FormControl(''),
    title: new FormControl(''),
    MFEngagementName: new FormControl(''),
    MFPartnerName: new FormControl(''),
    MFWBSCode: new FormControl(''),
    attachReport: new FormControl(''),
    RequestorEmail: new FormControl(''),
    AuditSigningPartner: new FormControl(''),
    businessContactEmail: new FormControl(''),
    AuditSigningPartnerEmail: new FormControl('')
  });

  users: { displayName: string, jobTitle: string, mail: string }[] = [];
  filteredUsersBussinessContact: Observable<{ displayName: string, jobTitle: string, mail: string }[]> = of([]);
  filteredUsersRequestors: Observable<{ displayName: string, jobTitle: string, mail: string }[]> = of([]);
  filteredAuditSigningPartner: Observable<{ displayName: string, jobTitle: string, mail: string }[]> = of([]);
  selectedFiles: File[] = [];
  checkClickedWithoutValue = false;
  autoCompleteUserSelected:any={
    requestorsUserSelected:false,
    businessContactUserSelected:false,
    auditSigningUserSelected:false
  };
  dateObjectValid: any={
    requestDate:false,
    needByDate:false,
    reportPeriodStartDate:false,
    reportPeriodEndDate:false
  }
  constructor(private router: Router, private route: ActivatedRoute,
    private appInsightsService: AppInsightsService, @Optional() public dialogRef: MatDialogRef<RequestFormComponent>,
    private http: HttpClient, private dialog: MatDialog,
    private authService: AuthService, private userService: UserService,private spinnerService:SpinnerServiceService,
    public globalHelperService:GlobalHelperServiceService,public commonHelperService:CommonHelperService,private windowService: WindowRefService) { }

  ngOnInit() {
    this.spinnerService.showSpinner(); // Show spinner at the start

    this.appInsightsService.logPageView('RequestForm');
    this.isAdmin = this.userService.isAdmin;
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${('0' + (today.getMonth() + 1)).slice(-2)}-${('0' + today.getDate()).slice(-2)}`;
    this.requestForm = new FormGroup({
      serviceOrganization: new FormControl('', Validators.pattern('.*\\S.*')),
      serviceSector: new FormControl('', Validators.pattern('.*\\S.*')),
      serviceAuditor: new FormControl('', Validators.pattern('.*\\S.*')),
      application: new FormControl('', Validators.pattern('.*\\S.*')),
      typeOfAudit: new FormControl('Financial Audit', Validators.required),
      LocationsCoveredInReport: new FormControl(''),
      typeOfOpinion: new FormControl('Unqualified'),
      reportPeriodStartDate: new FormControl(''),
      reportPeriodEndDate: new FormControl('', Validators.required),
      SARAuthoritativeGuidanceChecked: new FormControl('DropDown'),
      SARAuthoritativeGuidance: new FormControl(''),
      SARAuthoritativeGuidanceDropDown: new FormControl('SSAE 18'),
      SARAuthoritativeGuidanceText: new FormControl(''),
      SARReportType: new FormControl('Type II', Validators.required),
      requestorsName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('.*\S.*')])),
      requestorsNameLotus: new FormControl(''),
      businessContact: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('.*\S.*')])),
      requestDate: new FormControl(formattedDate, Validators.required),
      needByDate: new FormControl(formattedDate, Validators.required),
      commentsForWorkflow: new FormControl(''),
      idNumber: new FormControl(''),
      emphasisOfMatter: new FormControl(''),
      WBS: new FormControl(''),
      title: new FormControl(''),
      MFEngagementName: new FormControl(''),
      MFPartnerName: new FormControl(''),
      MFWBSCode: new FormControl(''),
      attachReport: new FormControl(''),
      RequestorEmail: new FormControl(''),
      businessContactEmail: new FormControl(''),
      AuditSigningPartnerEmail: new FormControl(''),
      AuditSigningPartner: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('.*\S.*')]))
    });

    // initialize the previousDateValue with the current value of needByDate
    this.previousDateValue = this.requestForm.controls['needByDate'].value;
    this.requestForm.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      if (this.isUserInitiated) {
        this.checkDateDifference();
        this.isUserInitiated = false;
      }
    });
    this.requestForm.controls['MFEngagementName'].valueChanges.subscribe(value => {
      if (value) {
        this.mfengagementname = false;
      }
    });

    this.requestForm.controls['MFPartnerName'].valueChanges.subscribe(value => {
      if (value) {
        this.mfengagementpartnername = false;
      }
    });

    this.requestForm.controls['MFWBSCode'].valueChanges.subscribe(value => {
      if (value) {
        this.mfwbscode = false;
      }
    });

    this.route.data.subscribe(data => {
      this.showExtendedFields = data["extended"] || false;
    });

    this.authService.getUsers().then((users: any[]) => {
      this.users = users.map((user: any) => ({ displayName: user.displayName, jobTitle: user.jobTitle, mail: user.mail }));
      this.filteredUsersBussinessContact = this.requestForm.controls['businessContact'].valueChanges.pipe(
        startWith(''),
        switchMap((value: string | null) => this._filter(value || ''))
      );
      this.filteredUsersRequestors = this.requestForm.controls['requestorsName'].valueChanges.pipe(
        startWith(''),
        switchMap((value: string | null) => this._filter(value || ''))
      );
      this.filteredAuditSigningPartner = this.requestForm.controls['AuditSigningPartner'].valueChanges.pipe(
        startWith(''),
        switchMap((value: string | null) => this._filter(value || ''))
      );

      this.spinnerService.hideSpinner(); // Hide spinner after data is loaded
    }).catch(() => {
      this.spinnerService.hideSpinner(); // Hide spinner in case of error
    });
    if (this.windowService.nativeWindow._satellite) {
      //alert("got the satellite obj");           


      let analyticsDataLayer =
      {
        'pageInfo':
        {
          'pageName': "Request Form", // Page Name
          'userID': this.userService.userEmail.split("@")[0], // User Alias, no domain
          'applicationName': "SAR" // Name of application
        }
      }
     
      this.windowService.nativeWindow.analyticsDataLayer = analyticsDataLayer;
      this.windowService.nativeWindow._satellite.track("pageView");
     
    }
  }

  private checkDateDifference(): void {
    const requestDateControl = this.requestForm.controls['requestDate'];
    const needByDateControl = this.requestForm.controls['needByDate'];

    if (requestDateControl && needByDateControl) {
      const requestDateValue = requestDateControl.value;
      const needByDateValue = needByDateControl.value;

      if (requestDateValue && needByDateValue) {
        const requestDate = new Date(requestDateValue);
        const needByDate = new Date(needByDateValue);
        const diffInDays = Math.floor((needByDate.getTime() - requestDate.getTime()) / (1000 * 60 * 60 * 24));

        if ((diffInDays < 14 || needByDate < requestDate) && location.search.length <= 0) {
          this.openAlertDialog('Warning', 'Please Note that the Standard Turnaround Time for New Request is Two weeks. While Need by Date is considered for scheduling resources, it is not a commitment from SAR Review Team. USI will also not be providing interim status updates on this. You may have to write to HYDSAS-70RA@deloitte.com separately for having a status update.', '3');
        }
      }
    }
  }
  onDateChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const newDate = inputElement.value;
  
    if (newDate !== this.previousDateValue) { // check if the date value has actually changed
      this.isUserInitiated = true;
    }
    // update the previousDateValue with the new date value
    this.previousDateValue = newDate;
  }


  private _filter(value: string): Observable<any[]> {
    this.showNoResults=false;
    if (value.length >= 3) { // Only search if the user has typed at least 3 characters
      return from(this.authService.searchUsers(value)).pipe(
        switchMap((users: any[]) => {
          if(users.length==0){this.showNoResults=true;}
          return of(users.map((user: any) => ({ displayName: user.displayName, jobTitle: user.jobTitle, mail: user.mail })));
        })
      );
    } else {

      // If the user hasn't typed enough characters, return an empty array
      return of([]);

    }
  }
setUserSelectionDynamic(field:string,action:string){
  if(field=="requestorsName"){
    this.autoCompleteUserSelected.requestorsUserSelected=action=="remove"?false:true;
  }
  else if(field=="businessContact"){
    this.autoCompleteUserSelected.businessContactUserSelected=action=="remove"?false:true;
  }
  else if(field=="AuditSigningPartner"){
    this.autoCompleteUserSelected.auditSigningUserSelected=action=="remove"?false:true;
  }
}
  onOptionSelected(event: MatAutocompleteSelectedEvent, field: string): void {
    const selectedUser = event.option.value; // This will be the entire user object
    console.log('Selected User:', selectedUser); // Log the selected user object

    if (selectedUser) {
      this.setUserSelectionDynamic(field, "add");
      this.requestForm.controls[field as keyof typeof this.requestForm.controls].setValue(selectedUser.displayName);
      this.requestForm.controls[field as keyof typeof this.requestForm.controls].setErrors(null);

      if (field === 'requestorsName') {
        this.requestForm.controls['RequestorEmail'].setValue(selectedUser.mail);
      } else if (field === 'businessContact') {
        this.requestForm.controls['businessContactEmail'].setValue(selectedUser.mail);
      } else if (field === 'AuditSigningPartner') {
        this.requestForm.controls['AuditSigningPartnerEmail'].setValue(selectedUser.mail);
      }
    } else {
      console.warn('User not found for selected value:', event.option.value); // Log a warning if user is not found
    }
  }


  onCancelClick(): void {
    this.dialogRef.close();
    this.router.navigate(['/userRequestDashboard']);
  }
  removeSelectedUser(field:string){
    this.setUserSelectionDynamic(field,"remove");
    if (field === 'requestorsName') {
      this.requestForm.controls['RequestorEmail'].setValue(null);
    } else if (field === 'businessContact') {
      this.requestForm.controls['businessContactEmail'].setValue(null);
    } else if (field === 'AuditSigningPartner') {
      this.requestForm.controls['AuditSigningPartnerEmail'].setValue(null);
    }
    this.requestForm.controls[field as keyof typeof this.requestForm.controls].setValue(null);
  }
  @ViewChild('fileInput') fileInput: ElementRef | undefined;

  saveChanges(): void {
    this.validateWBSCode = false;
    //this.spinnerService.showSpinner();
    // Before submitting
    if (this.requestForm.controls['SARAuthoritativeGuidanceChecked'].value == 'DropDown') {
      this.requestForm.controls['SARAuthoritativeGuidance'].setValue(this.requestForm.controls['SARAuthoritativeGuidanceDropDown'].value);
    } else if (this.requestForm.controls['SARAuthoritativeGuidanceChecked'].value == 'Text') {
      this.requestForm.controls['SARAuthoritativeGuidance'].setValue(this.requestForm.controls['SARAuthoritativeGuidanceText'].value);
    }

    if (this.requestForm.controls['RequestorEmail'].value == null) {
      this.openAlertDialog('Warning', 'This request cannot be submitted without the requestor email.');
      return;
    }

    // Check if any file is attached
    if (this.selectedFiles.length === 0) {
      this.openAlertDialog('Warning', 'This request cannot be submitted without the report attached.');
      return;
    }

    // Validate MF fields if isGlobalwbscode is true
    if (this.isGlobalwbscode) {
      if (!this.requestForm.controls['MFEngagementName'].value ||
        !this.requestForm.controls['MFPartnerName'].value ||
        !this.requestForm.controls['MFWBSCode'].value) {
        this.requestForm.markAllAsTouched();
        this.mfengagementname = !this.requestForm.controls['MFEngagementName'].value;
        this.mfengagementpartnername = !this.requestForm.controls['MFPartnerName'].value;
        this.mfwbscode = !this.requestForm.controls['MFWBSCode'].value;
        return;
      }
    }
    // Show the note about the standard turnaround time
    this.openAlertDialog('Warning', 'Please Note that the Standard Turnaround Time for New Request is Two weeks. While Need by Date is considered for scheduling resources, it is not a commitment from SAR Review Team. USI will also not be providing interim status updates on this. You may have to write to HYDSAS-70RA@deloitte.com separately for having a status update',"3")
      .subscribe(() => {
        this.spinnerService.showSpinner();
        //validate the form
        if (this.requestForm.valid && this.isDatesValidOnForm()) {
          const formData = new FormData();
          Object.keys(this.requestForm.controls).forEach(key => {
            const control = this.requestForm.get(key);
            if (control) {
              formData.append(key, control.value);
            }
          });
          // Append all the selected files to the form data
          let isFileTooLarge = false;
          this.selectedFiles.forEach((file) => {
            const fileSizeMB = file.size / (1024 * 1024);
            //if (fileSizeMB > 4) {
            //  isFileTooLarge = true;
            //  return;
            //}
            formData.append('files', file);
          });
         let reportPeriod:any= this.commonHelperService.GetReportPeriodData(this.requestForm.controls.reportPeriodEndDate.value);
          formData.append('createdBy', this.userService.userEmail);
          formData.append('createdByName', this.userService.userName);
          formData.append('created', new Date().toISOString().substring(0,10) );
          formData.append('BillingStatus', "Review in Process");
          formData.append('ContentType', "New Request");
          formData.append('RequestStatus', "Report for allotment");
          formData.append('ReportPeriod', reportPeriod);
          formData.append('RequestPriority', "[Delivery Date Required]");
          formData.append('modifiedByName', this.userService.userName);
          formData.append('modifiedBy', this.userService.userEmail);



      

          if (isFileTooLarge) {
            this.openAlertDialog('Warning','File is too large. Maximum file size is 4MB.');
            this.spinnerService.hideSpinner();
            return; // Don't post the form data if any file is too large
          }
          // Submit the form
          this.http.post(environment.baseUrl + '/RequestList/Create', formData)
            .subscribe(response => {
              this.appInsightsService.logEvent("Request Form Save:", { Response: response });
              // alert('Data saved');
              if (this.dialogRef) {
                this.dialogRef.close();
              }
              this.spinnerService.hideSpinner();
              this.router.navigate(['/userRequestDashboard']);
            }, error => {
              this.appInsightsService.logException(error);
              this.openAlertDialog('Warning','Failed to save data');
            });
        } else {
          // Mark the form as touched to trigger the error message
          this.requestForm.markAllAsTouched();
          this.spinnerService.hideSpinner();
        }
      });
  }
  //Show popup
  openAlertDialog(title: string, message: string,msglength:string = "2"): Observable<void> {
    const dialogRef = this.dialog.open(AlertPopupComponent, {
      data: { title, message  },
      width: '43%' ,
      height: msglength == "2" ? '30%' : msglength == "1" ? "35%" : '48%',
      disableClose: true
    });

    return new Observable<void>(observer => {
      dialogRef.afterClosed().subscribe(() => {
        observer.next();
        observer.complete();
      });
    });
  }
  isInvalidDate(selectedDate: any,controlName:string): any {
    const isValidDate=this.commonHelperService.ValidateDates(selectedDate);
    if(controlName=="reportPeriodStartDate"){
      this.dateObjectValid.reportPeriodStartDate=isValidDate;
    }
    else if(controlName=="reportPeriodEndDate"){
      this.dateObjectValid.reportPeriodEndDate=isValidDate;
    }
   else if(controlName=="requestDate"){
    this.dateObjectValid.requestDate=isValidDate;
    }
    else if(controlName=="needByDate"){
      this.dateObjectValid.needByDate=isValidDate;
    }
    return isValidDate;
  }
  isDatesValidOnForm(){
    return !this.dateObjectValid.reportPeriodStartDate&&!this.dateObjectValid.reportPeriodEndDate&&!this.dateObjectValid.requestDate&&!this.dateObjectValid.needByDate;
  }
  openFailedSaveDialog(title: string, message: string): Observable<void> {
    const dialogRef = this.dialog.open(AlertPopupComponent, {
      data: { title, message },
      height: '400px',
      width: '200px'
    });

    return new Observable<void>(observer => {
      dialogRef.afterClosed().subscribe(() => {
        observer.next();
        observer.complete();
      });
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(WbsModalComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isGlobalwbscode = false;
        const wbsControl = this.requestForm.get('WBS');
        if (wbsControl) {
          wbsControl.setValue((result as WBSRecords).WBSElement);
          this.validateWBSCode = false;
        }
      }
    });
  }
  onMouseLeave() {
    const wbsControl = this.requestForm.get('WBS');
    if (wbsControl && wbsControl.value &&  wbsControl.value.trim() != '') {

      this.isWbsExists(wbsControl.value);
    }
  }
  searchWBSRecords() {
    // If the WBS field is empty
    if (!this.requestForm.controls['WBS'].value) {
      this.checkClickedWithoutValue = true;
    } else {
      this.checkClickedWithoutValue = false;

      const wbsControl = this.requestForm.get('WBS');
      if (wbsControl && wbsControl.value) {
        this.isWbsExists(wbsControl.value);
       
      }
    }
  }

  isWbsExists(wbscode: string) {
    this.spinnerService.showSpinner();
    this.http.get(environment.baseUrl + '/GlobalMFWBSCodes/IsWBSCodeExist?WBSCode=' + wbscode, { responseType: 'text' })
      .subscribe(
        response => {
          console.log(response);
          this.requestForm.controls['WBS'].setErrors(null);
          this.spinnerService.hideSpinner();
          if (response !=null) {
            if (response.toLowerCase() === "global wbs code already exists") {
              this.isGlobalwbscode = true;
              this.validateWBSCode = false;

            } else {
              this.isGlobalwbscode = false;
            }
          } else {
            this.isGlobalwbscode= false;
            this.validateWBSCode = true;
            this.requestForm.controls['WBS'].setErrors({ 'incorrect': true });
          }
        },
        error => {
          // Handle the error here
          console.error(error);
        }
      );


  }

  onFileSelected(event: Event):any {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files) {
      //Validate the file Extension
    let isExtValid=this.commonHelperService.IsFileExtensionValid(inputElement.files[0].name);
      //Validatate the Attachment
      if(isExtValid){
       let isFileNameInValid=this.commonHelperService.IsFileNameInValid(inputElement.files[0].name);
       if(isFileNameInValid){
        this.openAlertDialog('Warning',this.commonHelperService.fileNameInvalidMsg,"1");
        inputElement.value = '';
        return false;
       }
      }
      else{
        this.openAlertDialog('Warning','Invalid file format. Please upload a valid file format.');
        inputElement.value = '';
        return false;
      }
      // Add the newly selected files to the existing ones
      this.selectedFiles.push(...Array.from(inputElement.files));
      // Clear the input to allow selecting the same file again if needed
      inputElement.value = '';
    }
  }


  removeFile(index: number) {
    this.selectedFiles.splice(index, 1);
  }
  getModalStyles(){
    var overflowX="hidden";
    var marginleft= "3%";
    var maxHeight= "100vh"; 
    if(window.location.hash.includes("requestForm")){
      return {"margin-left":marginleft};
    }
    else{
      return {'overflow-x':overflowX,'max-height':maxHeight,"margin-left":marginleft};
    }
   
  }
  getCorssMarkStyles(field:any):any{
  let selectedData=this.requestForm.controls[field as keyof typeof this.requestForm.controls].value?.toString();
   selectedData=selectedData==null||selectedData==undefined?"":selectedData;
   return this.commonHelperService.getCorssMarkStyles(selectedData);
  }
}
