import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonHelperService {

  constructor() { }
  public fileExtensionList:any= ['pdf','doc','docx','txt','xls','xlsx','xlsm','msg','png','jpeg','zip','csv','jpg','gif','tif','tiff','ppt','pptx'];
  public filterAppliedFields:any=[];
  public fileNameInvalidMsg:any="";
  LoadFilterAppliedFields(checkboxDataId:any,column:any){
    let data={
      key:checkboxDataId,
      value:column
    }
    this.filterAppliedFields.push(data);
  }
  RemoveFilterAppliedFields(checkboxDataId:any,column:any){
    this.filterAppliedFields.forEach((element:any, index:any) => {
      if(element.key==checkboxDataId && element.value==column) {
        this.filterAppliedFields.splice(index,1);
      }   
    });
  }
  IsFileExtensionValid(fileName:any) {
    let ext = fileName.split('.').pop();
    if(this.fileExtensionList.indexOf(ext.toLowerCase()) == -1) {
      return false;
    }
    return true;
  }
  IsFileNameInValid(fileName:any) {
    this.fileNameInvalidMsg="";
            let spchar, prevChar;
            spchar = "~#%&*{}|\\:\"<>?/";
            prevChar = '';
            let spchars = " ~, #, %, &, *, {, }, |, \\, :, \", <,  >, ?, /";
            if (fileName.indexOf("\\") != -1) {
              fileName = fileName.substr(fileName.lastIndexOf("\\") + 1);
            }
            if (fileName.length != 0) {
                let firstChar = fileName.charAt(0);
                if (firstChar == ' ' || firstChar == '.') {
                    this.fileNameInvalidMsg='Do not use special characters (% & * .) or any spaces within the file name.';
                    return true;
                }
                var lastChar = fileName.charAt(fileName.length - 1);
                if (lastChar == '.') {
                   this.fileNameInvalidMsg='Do not use special characters (% & * .) or any spaces within the file name.';
                    return true;
                }
            }
            for (var i = 0; i < fileName.length; i++) {
                for (var j = 0; j < spchar.length; j++) {
                    if (fileName.charAt(i) == spchar.charAt(j)) {
                        this.fileNameInvalidMsg='Do not use special characters (% & * .) or any spaces within the file name.';
                        return true;
                    }
                    else {
                        if (i != 0 && fileName.charAt(i) == '.' && fileName.charAt(i - 1) == '.') {
                            this.fileNameInvalidMsg='Do not use special characters (% & * .) or any spaces within the file name.';
                            return true;
                        }

                    }
                }
            }
            return false;

        }
        GetReportPeriodData(reportPeriodEndDate:any){
            const dateObject = new Date(reportPeriodEndDate);
            const year = dateObject.getFullYear();
            let reportPeriodDate=(year-2000)<0?"0":(year-2000)<10?"0"+(year-2000):(year-2000);
            return reportPeriodDate.toString();
        }
        GetRequestPriorityData(requestDate:any,deliveryDate:any){
          const dateObjectReqDate = new Date(requestDate);
          const dateObjectDelDate = new Date(deliveryDate);
          let requestPriorityData=dateObjectDelDate.valueOf()>=dateObjectReqDate.valueOf()?this.getDatesDiffInDays(dateObjectDelDate,dateObjectReqDate)<=10?"High":"Normal":"[Delivery Date Required]";
          return requestPriorityData.toString();
        }
        getDatesDiffInDays(delDate:any,reqDate:any):number{
         var diff = Math.abs(delDate.getTime() - reqDate.getTime());
         var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
         return diffDays
     }
     generateArrayOfYears(){
      let max = new Date().getFullYear();
      let years:any = [];
    //Range considering from 2017 to current year as per Siva
      for (var i = max; i >= 2017; i--) {
        years.push(i.toString());
      }
      return years;
     }
     defaultYearLoader(){
      let year = new Date().getFullYear();
      return year.toString();
     }
     // Load Page wise Data
     loadPageWiseData(pageIndex:any,pageSize:any,initialLoadData:any){
      let loadPageIndexWiseData:any=[];
      let loadRemainingData:any=[];
       for(let i=0;i<initialLoadData.length-1;i++){
          if(i>=(pageSize*pageIndex)&&i<((pageIndex*pageSize)+pageSize)){
            loadPageIndexWiseData.push(initialLoadData[i]);
          }
          else{
            loadRemainingData.push(initialLoadData[i]);
          }
       }
       return [ ...loadPageIndexWiseData, ...loadRemainingData];
    }
  replaceNullValueswithHiphen(data: any) {
    if (data.length > 0) {
      data.forEach((element: any) => {
        Object.keys(element).forEach((key: any) => {
          if (element[key] == null || element[key] == undefined || element[key] == "") {
            element[key] = "-";
          }
        });
      });
    }
    return data;
  }
  loadFiltersEmptyDataValues(tempFilters:any,column: any) {
    let data=["null","",null,"NULL"];
    data.forEach((element: any) => {
    if (tempFilters[column]) {
      tempFilters[column].push(element);
    } else {
      tempFilters[column] = [element];
    }
  });
    return tempFilters;
  }
  removeFiltersEmptyDataValues(tempFilters:any,column: any) {
    let data=["null","",null,"NULL"];
    data.forEach((element: any) => {
    const index = tempFilters[column].indexOf(element);
    if (index >= 0) {
      tempFilters[column].splice(index, 1);
    }
  });
  return tempFilters;
  }
  ValidateFilterData(filteredData:string){
    if(filteredData==null||filteredData==undefined||filteredData=="null"||filteredData=="NULL"||typeof filteredData=="undefined"){
      return "";
    }else{
    return filteredData;
      }
    }
    getCorssMarkStyles(selectedData:any):any{
      let selectedDataLength:any=selectedData?.length;
        var marginleft= "-35%";
        if(selectedDataLength>=8&&selectedDataLength<=13){marginleft= "-68%";}
        else if(selectedDataLength>=14&&selectedDataLength<=19){marginleft= "-60%";}
        else if(selectedDataLength>=20&&selectedDataLength<=25){marginleft= "-50%";}
        else if(selectedDataLength>=26&&selectedDataLength<=31){marginleft= "-40%";}
        return {"margin-left":marginleft};
      }
      ValidateDates(selectedDate:any):boolean{
        let isInValid=false;
        if(selectedDate==null){
          isInValid= false;
        }
        else{
          if(selectedDate?.validationMessage=="Please enter a valid value. The field is incomplete or has an invalid date."){
              isInValid= true;
          }
          else if(selectedDate?.value!=""||selectedDate?.value!=null){
            const year = new Date(selectedDate?.value).getFullYear();
            isInValid=year.toString().length>4;
          }
        }
        return isInValid;     
      }
}
