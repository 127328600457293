<div mat-dialog-content [ngStyle]="getModalStyles()">
  <div class="ms-dlgTitle" style="cursor: move;" >
    <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;" >
      <div style="flex-grow: 1; text-align: center;">
        <h1 title="Request a New Review" mat-dialog-title class="review-form-title">Request a New Review</h1>
      </div>
    </div>
  </div>
    <form [formGroup]="requestForm">
      <table style="width:50%">
        <tr *ngIf="!showExtendedFields">
          <td style="width:40%" nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label" for="Note">NOTE: </label></td>
          <td align="left" valign="top" class="ms-formbody" width="350px">
            <span>
              <select name="note" id="note">
                <option value="New Request">New Request</option>
              </select>
              <br>
            </span>
            <span class="ms-metadata" id="notetext" style="font-size:11.7px;">
              Attach Service Auditor Report :  <br>
              Use the Attach File feature above to attach the report.&nbsp;
              Without the report attachment the review team will not be able to initiate and process the request for the review. Attach only one report per request.  <br> <br>
              Do not use special characters (% &amp; * .) or any spaces within the file name.
            </span>
          </td>
        </tr>
        <tr *ngIf="!showExtendedFields">
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label  required" for="serviceorganization">Service Organization  </label></td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" class="form-control" maxlength="255" formControlName="serviceOrganization" required class="wide-input">
            <div *ngIf="requestForm.controls['serviceOrganization'].invalid && requestForm.controls['serviceOrganization'].touched" class="error">
              You can't leave this blank.
            </div>
          </td>
        </tr>
        <tr *ngIf="!showExtendedFields">
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label required" for="servicesector">Service Sector  </label></td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" class="form-control" maxlength="255" formControlName="serviceSector" required class="wide-input">
            <div *ngIf="requestForm.controls['serviceSector'].invalid && requestForm.controls['serviceSector'].touched" class="error">
              You can't leave this blank.
            </div>
          </td>
        </tr>
        <tr *ngIf="!showExtendedFields">
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label required" for="serviceauditor">Service Auditor  </label></td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" class="form-control" maxlength="255" formControlName="serviceAuditor" required class="wide-input">
            <div *ngIf="requestForm.controls['serviceAuditor'].invalid && requestForm.controls['serviceAuditor'].touched" class="error">
              You can't leave this blank.
            </div>
          </td>
        </tr>
        <tr *ngIf="!showExtendedFields">
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label required" for="application">Application  </label></td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" class="form-control" maxlength="255" formControlName="application" required class="wide-input">
            <div *ngIf="requestForm.controls['application'].invalid && requestForm.controls['application'].touched" class="error">
              You can't leave this blank.
            </div>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label" for="typeofaudit">Type of Audit: </label></td>
          <td valign="top" class="ms-formbody" width="350px">
            <select name="typeOfAudit" id="typeofaudit" class="form-control" formControlName="typeOfAudit">
              <option value="Financial Audit">Financial Audit</option>
              <option value="Employee Benifit Plan (EBP) Audit">Employee Benifit Plan (EBP) Audit</option>
              <option value="Inverstment Audit">Inverstment Audit</option>
              <option value="SOX Audi">SOX Audit</option>
              <option value="Integrated Audit">Integrated Audit</option>
              <option value="Other">Other</option>
            </select>
          </td>
        </tr>
        <tr *ngIf="!showExtendedFields">
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label" for="Location(s) Covered in Report">Location(s) Covered in Report </label></td>
          <td valign="top" class="ms-formbody" width="350px"><input type="text" class="form-control" maxlength="255" formControlName="LocationsCoveredInReport" class="wide-input"></td>
        </tr>
        <tr *ngIf="!showExtendedFields">
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label" for="Type of Opinion">Type of Opinion </label></td>
          <td valign="top" class="ms-formbody" width="350px">
            <select name="typeOfOpinion" id="TypeofOpinion" class="form-control" formControlName="typeOfOpinion">
              <option value="Qualified">Qualified</option>
              <option value="Unqualified">Unqualified</option>
            </select>
          </td>
        </tr>
        <tr *ngIf="!showExtendedFields">
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label" for="Report Period Start Date">Report Period Start Date </label></td>
          <td valign="top" class="ms-formbody" width="350px"><input type="date" class="form-control" formControlName="reportPeriodStartDate" #reportPeriodStartDate>
            <span class="error" *ngIf="isInvalidDate(reportPeriodStartDate,'reportPeriodStartDate')"> Date is Invalid</span></td>
        </tr>
        <tr *ngIf="!showExtendedFields">
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label required" for="Report Period End Date">Report Period End Date </label></td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="date" class="form-control" formControlName="reportPeriodEndDate" #reportPeriodEndDate>
            <div *ngIf="requestForm.controls['reportPeriodEndDate'].invalid && requestForm.controls['reportPeriodEndDate'].touched" class="error">
              You can't leave this blank.
            </div>
            <span class="error" *ngIf="isInvalidDate(reportPeriodEndDate,'reportPeriodEndDate')"> Date is Invalid</span>
          </td>
        </tr>
        <tr *ngIf="!showExtendedFields">
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label class="ms-h3 ms-standardheader label required">SAR Authoritative Guidance</label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <span>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <input id="SARAuthoritativeGuidanceDropDownButton" type="radio" name="SARAuthoritativeGuidanceChecked"
                             (change)="onRadioButtonChange('DropDown')" value="DropDown" formControlName="SARAuthoritativeGuidanceChecked" checked>
                      <select id="SARAuthoritativeGuidanceDropDownChoice" title="SAR Authoritative Guidance" class="ms-RadioText" class="form-control"
                              (click)="onSelectChange('DropDown')" formControlName="SARAuthoritativeGuidanceDropDown" onclick="document.getElementById('SARAuthoritativeGuidanceDropDownButton').checked = true;">
                        <option value="SSAE 18" selected="selected">SSAE 18</option>
                        <option value="CSAE 3416">CSAE 3416</option>
                        <option value="ISAE 3402">ISAE 3402</option>
                        <option value="SOC 2">SOC 2</option>
                      </select>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span class="ms-RadioText" title="SAR Authoritative Guidance: Specify your own value:">
                        <input id="SARAuthoritativeGuidanceFillInButton" type="radio" name="SARAuthoritativeGuidanceChecked" value="Text"
                               (change)="onRadioButtonChange('Text')" formControlName="SARAuthoritativeGuidanceChecked">
                        <label class="ms-h3 ms-standardheader label" for="SARAuthoritativeGuidanceFillInButton">Specify your own value:</label>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td><input type="text" class="form-control" maxlength="255" id="SARAuthoritativeGuidanceFillInChoice" tabindex="-1" value="" title="SAR Authoritative Guidance: Specify your own value:" formControlName="SARAuthoritativeGuidanceText"
                               (focus)="OnTextFocus()"></td>                   
                  </tr>
                  <tr>
                    <td>
                      <div *ngIf="requestForm.get('SARAuthoritativeGuidanceChecked')?.value === 'Text' &&
                     requestForm.controls['SARAuthoritativeGuidanceText'].invalid
                     && requestForm.controls['SARAuthoritativeGuidanceText'].touched" class="error">
                        You can't leave this blank.
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label">SAR Report Type. </label></td>
          <td valign="top" class="ms-formbody" width="350px">
            <select id="DropDownChoice" title="SAR Report Type." class="form-control" formControlName="SARReportType">
              <option value="Type I">Type I</option>
              <option value="Type II" selected="selected">Type II</option>
            </select>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label required">Requestor's Name  </label></td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" class="form-control" required placeholder="Enter a name or email address..." class="wide-input" formControlName="requestorsName" [matAutocomplete]="auto2" [readOnly]="autoCompleteUserSelected.requestorsUserSelected">
            <span *ngIf="autoCompleteUserSelected.requestorsUserSelected" class="clearBtn" (click)="removeSelectedUser('requestorsName')" [ngStyle]="getCorssMarkStyles('requestorsName')">X</span>
            <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="onOptionSelected($event, 'requestorsName')">
              <mat-option *ngFor="let option of filteredUsersRequestors | async" [value]="option">
                <div>{{option.displayName}}</div>
                <small>{{option.jobTitle}}</small><br />
                <small>{{option.mail}}</small>
              </mat-option>
              <mat-option *ngIf="showNoResults">
                <div>{{"No Results Found.."}}</div>
              </mat-option>
            </mat-autocomplete>
            <div *ngIf="!_isRequestorNameValid && requestForm.controls['requestorsName'].touched && requestForm.controls['requestorsName']?.value" class="error">
              Please enter valid name.
            </div>
            <div *ngIf="!requestForm.controls['requestorsName']?.value && requestForm.controls['requestorsName']?.touched" class="error">
              You can't leave this blank.
            </div>
          </td>
        </tr>
        <tr *ngIf="!showExtendedFields">
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label">Requestor's Name (Lotus Notes) </label></td>
          <td valign="top" class="ms-formbody" width="350px"><input type="text" class="form-control" maxlength="255" formControlName="requestorsNameLotus" class="wide-input"></td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label required" for="BusinessContact">Business Contact  </label></td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" class="form-control wide-input" formControlName="businessContact" required placeholder="Enter a name or email address..." [matAutocomplete]="auto1" [readOnly]="autoCompleteUserSelected.businessContactUserSelected">
            <span *ngIf="autoCompleteUserSelected.businessContactUserSelected" class="clearBtn" (click)="removeSelectedUser('businessContact')" [ngStyle]="getCorssMarkStyles('businessContact')">X</span>
            <mat-autocomplete #auto1="matAutocomplete" (optionSelected)="onOptionSelected($event, 'businessContact')">
              <mat-option *ngFor="let user of filteredUsersBussinessContact | async" [value]="user">
                <div>{{user.displayName}}</div>
                <small>{{user.jobTitle}}</small><br />
                <small>{{user.mail}}</small>
              </mat-option>
              <mat-option *ngIf="showNoResults">
                <div>{{"No Results Found.."}}</div>
              </mat-option>
            </mat-autocomplete>
            <div *ngIf="!_isBusinessNameValid && requestForm.controls['businessContact'].touched && requestForm.controls['businessContact']?.value" class="error">
              Please enter valid name.
            </div>
            <div *ngIf="!requestForm.controls['businessContact']?.value && requestForm.controls['businessContact']?.touched" class="error">
              You can't leave this blank.
            </div>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label required">Request Date  </label></td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="date" class="form-control" formControlName="requestDate" required #requestDate>
           <span class="error" *ngIf="isInvalidDate(requestDate,'requestDate')"> Date is Invalid</span></td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label class="ms-h3 ms-standardheader label required">Need by Date</label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="date" class="form-control" formControlName="needByDate" required (change)="onDateChange($event)" #needByDate>
            <span class="error" *ngIf="isInvalidDate(needByDate,'needByDate')"> Date is Invalid</span>
          </td>
        </tr>
        <tr *ngIf="!showExtendedFields">
          <td style="vertical-align:text-top" nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label">Comments for Workflow </label></td>
          <td valign="top" class="ms-formbody" width="350px">
            <table>
              <tr><td valign="top" class="ms-formbody" width="350px"><textarea rows="6" cols="20" maxlength="2500" id="TextField" title="Rich text editor Comments for Workflow" class="form-control" formControlName="commentsForWorkflow" class="wide-input"></textarea></td></tr>
              <!--<tr id="notetext"><td><a style="color:  #337AB7;" href="javascript:HelpWindowKey('nsrichtext')">Click for help about adding basic HTML formatting.</a></td></tr>-->
              <!--<tr *ngIf="isAdmin" id="notetext"><td><span class="ms-metadata" style="font-size:11.7px;">(ID Number to be completed by SAR Review team Only)</span></td></tr>-->
            </table>
          </td>
        </tr>
        <!--<tr *ngIf="isAdmin">
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label">ID Number</label></td>
          <td valign="top" class="ms-formbody" width="350px"><input type="text" class="form-control" formControlName="idNumber" class="wide-input"></td>
        </tr>-->
        <tr *ngIf="isAdmin">
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label" for="emphasisOfMatter">Emphasis Of Matter</label></td>
          <td valign="top" class="ms-formbody" width="350px">
            <select name="emphasisOfMatter" id="emphasisOfMatter" class="form-control" formControlName="emphasisOfMatter">
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label required">WBS#  </label></td>
          <td valign="top" class="ms-formbody" width="350px">
            <div style="display: flex; align-items: center;">
              <input type="text" id="wbs" formControlName="WBS" class="wide-input" style="margin-right: 10px;" class="wide-input" (blur)="onMouseLeave()">
              <img title="Check if External Item exists" src="../../assets/img/EntityInstanceResolver.png" alt="Check if External Item exists" style="margin-right: 5px;" (click)="searchWBSRecords()">
              <img title="Select External Item(s)" src="../../assets/img/EntityInstancePicker.png" alt="Select External Item(s)" (click)="openDialog()">
            </div>
            <div *ngIf="requestForm.controls['WBS'].invalid && requestForm.controls['WBS'].touched && !checkClickedWithoutValue && !validateWBSCode" class="error">
              You can't leave this blank (or) The WBS Code you entered is invalid.
            </div>
            <div *ngIf="validateWBSCode && !checkClickedWithoutValue" class="error">
              No exact match was found. Click the item(s) that did not resolve for more options. You can also use Select button to choose External Data
            </div>
            <div *ngIf="checkClickedWithoutValue" class="error">
              You must specify a value before using the Check button. You can also use Select button to choose External Data.
            </div>


          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label required" for="AuditSigningPartner">Audit Signing Partner</label></td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" class="form-control wide-input" formControlName="AuditSigningPartner" required placeholder="Enter a name or email address..." [matAutocomplete]="auto3" [readOnly]="autoCompleteUserSelected.auditSigningUserSelected">
            <span *ngIf="autoCompleteUserSelected.auditSigningUserSelected" class="clearBtn" (click)="removeSelectedUser('AuditSigningPartner')" [ngStyle]="getCorssMarkStyles('AuditSigningPartner')">X</span>
            <mat-autocomplete #auto3="matAutocomplete" (optionSelected)="onOptionSelected($event, 'AuditSigningPartner')">
              <mat-option *ngFor="let user of filteredAuditSigningPartner | async" [value]="user">
                <div>{{user.displayName}}</div>
                <small>{{user.jobTitle}}</small><br />
                <small>{{user.mail}}</small>
              </mat-option>
              <mat-option *ngIf="showNoResults">
                <div>{{"No Results Found.."}}</div>
              </mat-option>
            </mat-autocomplete>
            <div *ngIf="!_isAuditNameValid && requestForm.controls['AuditSigningPartner'].touched && requestForm.controls['AuditSigningPartner']?.value" class="error">
              Please enter valid name.
            </div>
            <div *ngIf="!requestForm.controls['AuditSigningPartner']?.value && requestForm.controls['AuditSigningPartner']?.touched" class="error">
              You can't leave this blank.
            </div>
          </td>
        </tr>
        <tr *ngIf="showExtendedFields">
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label required">Title  </label></td>
          <td valign="top" class="ms-formbody" width="350px"><input type="text" class="form-control" formControlName="title" required placeholder="View Details" class="wide-input"></td>
        </tr>

        <tr *ngIf="showExtendedFields || isGlobalwbscode">
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label required">MF Engagement Name </label></td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" class="form-control" formControlName="MFEngagementName" class="wide-input">
            <div *ngIf="mfengagementname" class="error">
              Value Shouldnot be empty
            </div>
          </td>

        </tr>

        <tr *ngIf="showExtendedFields || isGlobalwbscode">
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label required">MF Partner Name </label></td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" class="form-control" formControlName="MFPartnerName" class="wide-input">
            <div *ngIf="mfengagementpartnername" class="error">
              Value Shouldnot be empty
            </div>
          </td>

        </tr>

        <tr *ngIf="showExtendedFields  || isGlobalwbscode ">
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label required">MF WBS Code </label></td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" class="form-control" formControlName="MFWBSCode" class="wide-input">
            <div *ngIf="mfwbscode" class="error">
              Value Shouldnot be empty
            </div>
          </td>

        </tr>

        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label">Attachments</label></td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="file" multiple style="display: none;" (change)="onFileSelected($event)" #fileInput>
            <button mat-raised-button (click)="fileInput.click()" style="font-size: 1em; padding: 5px 10px;">Choose Files</button>
            <label>  {{selectedFiles.length ? selectedFiles.length + ' file(s) selected' : 'No file chosen'}}</label>

            <div *ngFor="let file of selectedFiles; let i = index">
              {{file.name}}
              <a href="#" (click)="removeFile(i); $event.preventDefault()">Delete</a>
            </div>
          </td>
        </tr>


        <tr>
          <td></td>
          <td>
            <div style="display: flex; justify-content: center; align-items: flex-start;">
              <div mat-dialog-actions>
                <button mat-button (click)="saveChanges()" class="ms-button" style="color: #FFFFFF;" [disabled]="isSaving">Save</button>
                <button mat-button mat-dialog-close (click)="onCancelClick()" class="ms-button" style="color: #FFFFFF;">Cancel</button>
              </div>
            </div>
          </td>
        </tr>

      </table>
    </form>
</div>

