import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _userEmail: any;
  private _userName!: string;
  private _userPhoto: any;
  private _isAdmin!: boolean;

  private _baseUrl!: string;
  private _clientId!: string;
  private _authority!: string;
  private _redirectUri!: string;
  private _groupId!: string;
  private _appInsightsConnection!: string;

  set userEmail(email: any) {
    this._userEmail = email;
  }

  get userEmail(): any {
    return this._userEmail;
  }

  set userName(name: string) {
    this._userName = name;
  }

  get userName(): string {
    return this._userName;
  }

  set userPhoto(photo: any) {
    this._userPhoto = photo;
  }

  get userPhoto(): any {
    return this._userPhoto;
  }

  set isAdmin(admin: boolean) {
    this._isAdmin = admin;
  }

  get isAdmin(): boolean {
    return this._isAdmin;
  }

  set baseUrl(baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  get baseUrl(): string {
    return this._baseUrl;
  }

  set clientId(clientId: string) {
    this._clientId = clientId;
  }

  get clientId(): string {
    return this._clientId;
  }

  set authority(authority: string) {
    this._authority = authority;
  }

  get authority(): string {
    return this._authority;
  }

  set redirectUri(redirectUri: string) {
    this._redirectUri = redirectUri;
  }

  get redirectUri(): string {
    return this._redirectUri;
  }

  set groupId(groupId: string) {
    this._groupId = groupId;
  }

  get groupId(): string {
    return this._groupId;
  }

  set appInsightsConnection(appInsightsConnection: string) {
    this._appInsightsConnection = appInsightsConnection;
  }

  get appInsightsConnection(): string {
    return this._appInsightsConnection;
  }

}
