<div class="mat-dialog-container">
    <div class="ms-dlgBorder">
      <div class="ms-dlgTitle" style="cursor: move;">
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <h1 title="SAR Master List - {{data.ServiceOrganization}}" mat-dialog-title class="master-form-title">SAR Master List - <span class="truncate">{{data.ServiceOrganization}}</span></h1>
          <button class="icon-button" (click)="onCloseClick()"><mat-icon class="ms-icon">highlight_off</mat-icon></button>
        </div>
      </div>
        <div mat-dialog-content>
            <table>
                <tr>
                    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
                        <span class="ms-h3 ms-standardheader">Service Organization</span>
                    </td>
                    <td valign="top" class="ms-formbody" width="350px">
                        <input *ngIf="isEditMode" type="text" [(ngModel)]="data.ServiceOrganization" class="wide-input">
                        <span *ngIf="!isEditMode">{{data.ServiceOrganization}}</span>
                    </td>
                </tr>
                <tr>
                    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
                        <span class="ms-h3 ms-standardheader">Application</span>
                    </td>
                    <td valign="top" class="ms-formbody" width="350px">
                        <input *ngIf="isEditMode" type="text" [(ngModel)]="data.Application" class="wide-input">
                        <span *ngIf="!isEditMode">{{data.Application}}</span>
                    </td>
                </tr>
                <tr>
                    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
                        <span class="ms-h3 ms-standardheader">Service Sector</span>
                    </td>
                    <td valign="top" class="ms-formbody" width="350px">
                        <input *ngIf="isEditMode" type="text" [(ngModel)]="data.ServiceSector" class="wide-input">
                        <span *ngIf="!isEditMode">{{data.ServiceSector}}</span>
                    </td>
                </tr>
                <tr>
                    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
                        <span class="ms-h3 ms-standardheader">Service Auditor</span>
                    </td>
                    <td valign="top" class="ms-formbody" width="350px">
                        <input *ngIf="isEditMode" type="text" [(ngModel)]="data.ServiceAuditor" class="wide-input">
                        <span *ngIf="!isEditMode">{{data.ServiceAuditor}}</span>
                    </td>
                </tr>
                <tr>
                    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
                        <span class="ms-h3 ms-standardheader">Exclusive to Client?</span>
                    </td>
                    <td valign="top" class="ms-formbody" width="350px">
                        <input *ngIf="isEditMode" type="checkbox" [(ngModel)]="data.ExclusivetoClient" class="wide-input">
                        <span *ngIf="!isEditMode">{{data.ExclusivetoClient ? 'Yes' : 'No'}}</span>
                    </td>
                </tr>
                <tr>
                    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
                        <span class="ms-h3 ms-standardheader">Location(s) Covered in Report</span>
                    </td>
                    <td valign="top" class="ms-formbody" width="350px">
                        <input *ngIf="isEditMode" type="text" [(ngModel)]="data.LocationsCovered" class="wide-input">
                        <span *ngIf="!isEditMode">{{data.LocationsCovered}}</span>
                    </td>
                </tr>
                <tr>
                    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
                        <span class="ms-h3 ms-standardheader">Type of Opinion</span>
                    </td>
                    <td valign="top" class="ms-formbody" width="350px">
                        <input *ngIf="isEditMode" type="text" [(ngModel)]="data.TypeOfOpinion" class="wide-input">
                        <span *ngIf="!isEditMode">{{data.TypeOfOpinion}}</span>
                    </td>
                </tr>
                <tr>
                    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
                        <span class="ms-h3 ms-standardheader">Report Period Start Date</span>
                    </td>
                    <td valign="top" class="ms-formbody" width="350px">
                        <input *ngIf="isEditMode" type="date" [ngModel]="formattedStartDate" (ngModelChange)="onStartDateChange($event)" class="wide-input">
                        <span *ngIf="!isEditMode">{{data.ReportPeriodStartDate | date:'MM/dd/yyyy'}}</span>
                    </td>
                </tr>
                <tr>
                    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
                        <span class="ms-h3 ms-standardheader">Report Period End Date</span>
                    </td>
                    <td valign="top" class="ms-formbody" width="350px">
                        <input *ngIf="isEditMode" type="date" [ngModel]="formattedEndDate" (ngModelChange)="onEndDateChange($event)" class="wide-input">
                        <span *ngIf="!isEditMode">{{data.ReportPeriodEndDate | date:'MM/dd/yyyy'}}</span>
                    </td>
                </tr>
                <tr>
                    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
                        <span class="ms-h3 ms-standardheader">ID Number</span>
                    </td>
                    <td valign="top" class="ms-formbody" width="350px">
                        <input *ngIf="isEditMode" type="text" [(ngModel)]="data.IDNumber" class="wide-input">
                        <span *ngIf="!isEditMode">{{data.IDNumber}}</span>
                    </td>
                </tr>
                <tr>
                    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
                        <span class="ms-h3 ms-standardheader">Request Review?</span>
                    </td>
                    <td valign="top" class="ms-formbody" width="350px">
                        <span (click)="navigateToRequestReview()" style="color: #0072c6; cursor: pointer;"><u>{{data.RequestReview}}</u></span>
                    </td>
                </tr>
                <tr>
                    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
                        <span class="ms-h3 ms-standardheader">Date Created</span>
                    </td>
                    <td valign="top" class="ms-formbody" width="350px">
                        <input *ngIf="isEditMode" type="datetime-local" [(ngModel)]="data.Created" class="wide-input">
                        <div style="font-size:11.7px;font-family:'Segoe UI'" *ngIf="isEditMode">The date on which this resource was created</div>
                        <span *ngIf="!isEditMode">{{data.Created | date:'MM/dd/yyyy, h:mm a'}}</span>
                    </td>
                </tr>
                <tr>
                    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
                        <span class="ms-h3 ms-standardheader">Date Modified</span>
                    </td>
                    <td valign="top" class="ms-formbody" width="350px">
                        <input *ngIf="isEditMode" type="datetime-local" [(ngModel)]="data.Modified" class="wide-input">
                        <div style="font-size:11.7px;font-family:'Segoe UI'" *ngIf="isEditMode">The date on which this resource was last modified</div>
                        <span *ngIf="!isEditMode">{{data.Modified | date:'MM/dd/yyyy, h:mm a'}}</span>
                    </td>
                </tr>

                <tr>
                    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
                        <span class="ms-h3 ms-standardheader">SAR Authoritative Guidance</span>
                    </td>
                    <td valign="top" class="ms-formbody" width="350px">
                        <input *ngIf="isEditMode" type="text" [(ngModel)]="data.SARAuthoritativeGuidance" class="wide-input">
                        <span *ngIf="!isEditMode">{{data.SARAuthoritativeGuidance}}</span>
                    </td>
                </tr>
            </table>
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <span class="ms-formbody">
                    Created at {{data.Created | date:'MM/dd/yyyy, h:mm a'}} by {{data.CreatedBy}}<br />
                    Last modified at {{data.Modified | date:'MM/dd/yyyy, h:mm a'}} by {{data.ModifiedBy}}
                </span>
                <div mat-dialog-actions>
                    <button *ngIf="!isEditMode" mat-button mat-dialog-close (click)="onCloseClick()" class="ms-button" style="color: #FFFFFF;" >Close</button>
                </div>
            </div>
            <div style="display: flex; justify-content: right; align-items: flex-end;">
                <div mat-dialog-actions>
                    <button *ngIf="isEditMode" mat-button (click)="saveChanges()" class="ms-button" style="color: #FFFFFF;" >Save</button>
                    <button *ngIf="isEditMode" mat-button mat-dialog-close (click)="onCloseClick()" class="ms-button" style="color: #FFFFFF;" >Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
