import { Component, OnInit,Inject,Renderer2 } from '@angular/core';
import { UserService } from '../auth.details';
import { SidebarService } from '../../../sidebar.service';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent implements OnInit {
  isMenuOpen = false;
  isAdmin!: boolean;
  userName: any;
  userPhoto: any;
  isSidebarOpen!: boolean;
  constructor(private userService: UserService, private sidebarService: SidebarService, @Inject(DOCUMENT) private _document: Document, private _renderer2: Renderer2, private router: Router) {
    this.sidebarService.isSidebarOpen$.subscribe(isOpen => {
      this.isSidebarOpen = isOpen;
    });
  }

  async ngOnInit() {
    this.userName = this.userService.userName;
    this.userPhoto = this.userService.userPhoto;
    this.isAdmin = this.userService.isAdmin;
    this.loadScript();
    this.sidebarService.toggleSidebar();
  }

  onMenuClick(): void {
    this.sidebarService.toggleSidebar();
  }

  onSidenavClose() {
    this.sidebarService.toggleSidebar();
  }

  loadScript() {
    let scriptExists = this._document.querySelector(`script[src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"]`);

    if (!scriptExists) {
      let script = this._renderer2.createElement('script');
      script.type = `text/javascript`;
      script.src = `https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`;
      script.charset = `UTF-8`;
      script.setAttribute("data-domain-script", "4710df1c-6647-4c51-beb7-e3d09032728d-test");
      this._renderer2.appendChild(this._document.body, script);
    }

    let optanonScriptExists = this._document.querySelector(`script[innerHTML="function OptanonWrapper() { }"]`);

    if (!optanonScriptExists) {
      let optanonScript = this._renderer2.createElement('script');
      optanonScript.type = `text/javascript`;
      optanonScript.innerHTML = `function OptanonWrapper() { }`;
      this._renderer2.appendChild(this._document.body, optanonScript);
    }
  }

  navigateToNewRequest(event: Event) {
    event.preventDefault();
    if (this.router.url === '/requestForm') {
      this.router.navigateByUrl('/temporary', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/requestForm']);
      });
    } else {
      this.router.navigate(['/requestForm']);
    }
  }
}


