<div id="loadingAuto" style="display: none;">
    <div class="loader"></div>
  </div>
<div *ngIf="!isAuthenticated" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0); display: flex; justify-content: center; align-items: center;">
    <!-- <div class="spinner"></div>
    <p class="authenticating-text">Hang tight! We're processing.</p> -->
</div>
<div *ngIf="isAuthenticated && whitelisted">
  <app-header></app-header>
  <br> <br>
  <div class="main-content">
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
</div>

<div *ngIf="maintainencePage && !whitelisted">
  <br> <br>
  <div class="main-content" style="padding-left:16%">
    <app-maintenance></app-maintenance>
  </div>
</div>
