import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { AppInsightsService } from '../app-insights.service';

@Component({
  selector: 'app-request-review-list-view',
  templateUrl: './request-review-list-view.component.html',
  styleUrl: './request-review-list-view.component.css'
})
export class RequestReviewListViewComponent implements OnInit {
  selectedFiles: File[] = [];
  selectedFilesEx: File[] = [];
  constructor(public dialogRef: MatDialogRef<RequestReviewListViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient, private appInsightsService: AppInsightsService,) {
    let dat = data;
  }
  ngOnInit() {
    this.appInsightsService.logPageView('RequestReviewEditFormComponent');
    this.fetchFiles();
  }
  onCloseClick(): void {
    this.dialogRef.close();
    // this.router.navigate(['/masterDashboard']);
  }
  fetchFiles() {
    this.selectedFiles = [];
    this.selectedFilesEx = [];
    this.http.get<{ FileName: string, Content: ArrayBuffer }[]>(environment.baseUrl + '/Email/FetchFiles/' + this.data.ID)
      .subscribe(
        files => {
          if (files && Array.isArray(files)) {
            files.forEach(file => {
              const blob = new Blob([file.Content]);
              const fileObj = new File([blob], file.FileName);
              // Check if the file with the same name already exists in selectedFiles
              if (!this.selectedFiles.some(f => f.name === fileObj.name)) {
                this.selectedFiles.push(fileObj);
              }

              // Check if the file with the same name already exists in selectedFilesEx
              if (!this.selectedFilesEx.some(f => f.name === fileObj.name)) {
                this.selectedFilesEx.push(fileObj);
              }
            });
          } else {
            this.appInsightsService.logEvent('Error: files is not an array', files);
          }
        },
        error => {
          this.appInsightsService.logEvent('Error fetching files', error);
        }
      );
  }

}
