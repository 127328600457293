<a class="pageTitle" title="Request Review List">Lotus Notes</a>
<div class="right-section">
  <label>Request Date (greater than or equal to):</label>
    <input 
        type="date" 
        class="form-control" 
        [(ngModel)]="fromDate"  
        min="1990-01-01"  
        max="2050-12-31" 
        (change)="onDateChange($event)" #reqDate
    >   
    <button 
        *ngIf="isAdmin" 
        [disabled]="!enableButton" 
        class="primary icon-button blue-button" 
        type="button" 
        (click)="exportToExcel()"
    >
        Generate Report
    </button>   
    <div *ngIf="invalidDateMsg" class="error-message">
        <span class="msg-invalid-date">{{ invalidDateMsg }}</span>
    </div> 
    <div *ngIf="ValidateDates(reqDate)" class="error-message">
        <span class="msg-invalid-date">{{ invalidDateMsg }}</span>
    </div> 
</div>
