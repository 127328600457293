import { Component,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpinnerServiceService } from '../../services/spinner-service.service';
import { MAT_DIALOG_DATA,MatDialog,MatDialogRef } from '@angular/material/dialog';
import { AdminviewServiceService } from '../../services/adminview-service.service';
import { UserService } from '../../auth.details';
import { AlertPopupComponent } from '../../notify-popup/notify-popup.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-multi-existing-request-form',
  templateUrl: './multi-existing-request-form.component.html',
  styleUrl: './multi-existing-request-form.component.css'
})
export class MultiExistingRequestFormComponent {
  formGroup: FormGroup;
  isUpdate: boolean = false;

  constructor(
    private fb: FormBuilder,
    private adminViewService: AdminviewServiceService,
    private spinnerService: SpinnerServiceService,
    public dialogRef: MatDialogRef<MultiExistingRequestFormComponent>,
    private userService: UserService,private dialog: MatDialog,
     @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.formGroup = this.fb.group({
      billingStatus: [ '', Validators.required],
      deliveryDate: ['', Validators.required],
    });
    //Default Initialization
    this.formGroup.controls['billingStatus'].setValue("Select Option");
  }
  validateIsFieldsUpdated(){
    if(this.formGroup.value.billingStatus=="Select Option" && !this.formGroup.value.deliveryDate){
      return false;
    }
    else{
      return true;
    }
  }
  onUpdate(): void {
    if (this.validateIsFieldsUpdated() && !this.isUpdate) {
      this.isUpdate = true;
      this.spinnerService.showSpinner(); // Show the spinner
      const result:any = {
        BillingStatus: this.formGroup.value.billingStatus=="Select Option"?"":this.formGroup.value.billingStatus,
        DeliveryDate: this.formGroup.value.deliveryDate,
        UpdatedIDList: this.data,
        UpdatedBy: this.userService.userEmail
      }; 
        this.updateMultiExistingRequest(result);
    }
    else{
      this.openAlertDialog("Error", "Please fill atleast one field to Update", false).subscribe();
    }
  }
  openAlertDialog(title: string, message: string, isGlobalMFWBSCodesPage: boolean, msglength: string = "2"): Observable<boolean> {
    const dialogRef = this.dialog.open(AlertPopupComponent, {
      data: { title, message, isGlobalMFWBSCodesPage },
      width: '40%',
      height: msglength == "2" ? '30%' : msglength == "1" ? "35%" : '48%',
      disableClose: true
    });
    return new Observable<boolean>(observer => {
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          observer.next(true);
        } else {
          observer.next(false);
        }
        observer.complete();
      });
    });
  }
  private updateMultiExistingRequest(result: any): void {
    const data=JSON.stringify(result)
    this.adminViewService.updateMultiExistingRequest(data).subscribe({
      next: response => {
        this.isUpdate = false;
        this.spinnerService.hideSpinner(); // Hide the spinner
        this.dialogRef.close("Success"); // Close the dialog and pass the result
      },
      error: error => {
        console.error('There was an error while updating the item!', error);
        this.isUpdate = false;
        this.spinnerService.hideSpinner(); // Hide the spinner
        this.dialogRef.close("Failed");
      }
    });
  }
  isInvalidYear():any{
    if(this.formGroup.value.deliveryDate){
      const year = new Date(this.formGroup.value.deliveryDate).getFullYear();
      if((year < 1990&&year.toString().length==4)||year.toString().length>4){
        return true;
      }
      else{
        return false;
      }
    }
  }
  onCancel(): void {
    if (!this.isUpdate) {
      this.dialogRef.close("Cancel");
    }
  }
}
