import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppInsightsService } from '../../app-insights.service';
import { DatePipe } from '@angular/common';
import { UserService } from '../../auth.details';
import { SpinnerServiceService } from '../../services/spinner-service.service';
import { CommonHelperService } from '../../services/common-helper.service';
import { timeout } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { adminRequestDashboard } from '../../request-dashboard/_interface/request-dashboard.model';
import { WindowRefService } from '../../services/window.service';

@Component({
  selector: 'app-lotus-notes',
  templateUrl: './lotus-notes.component.html',
  styleUrl: './lotus-notes.component.css'
})
export class LotusNotesComponent  implements OnInit
{
  fromDate: any;
  isAdmin!: boolean;
  isLoading: boolean | undefined;
  inValidDaterange: boolean | undefined;
  shownorecords: boolean | undefined;
  enableButton: boolean | undefined;
  invalidDateMsg = '';
  msgFormatError = 'Please select appropriate dates';
constructor(private http: HttpClient, private dialog: MatDialog, private appInsightsService: AppInsightsService,
  private datePipe: DatePipe, private userService: UserService,private spinnerService:SpinnerServiceService,
  private commonHelperService:CommonHelperService,private windowService: WindowRefService) { }

ngOnInit() {  
  this.isAdmin = this.userService.isAdmin;  
  if (this.windowService.nativeWindow._satellite) {
    //alert("got the satellite obj");           


    let analyticsDataLayer =
    {
      'pageInfo':
      {
        'pageName': "Lotus Notes", // Page Name
        'userID': this.userService.userEmail.split("@")[0], // User Alias, no domain
        'applicationName': "SAR" // Name of application
      }
    }
   
    this.windowService.nativeWindow.analyticsDataLayer = analyticsDataLayer;
    this.windowService.nativeWindow._satellite.track("pageView");
   
  }
}


// onDateChange(event:any)
// {

//   const fromDate = new Date(this.fromDate);    
//   this.enableButton = true;
//   if (!(this.fromDate.trim() == '')) {
//     if (this.fromDate && !this.isValidDate(fromDate)) {
//       this.enableButton = false;
//       this._showErrorMessage(event, this.msgFormatError);
//     }
//   } else {
//     this.enableButton = false;
//   }
// }
// private _showErrorMessage(event:any, errorMessage: string) {
//   event.currentTarget.value = '';
//   this.invalidDateMsg = errorMessage;
// }
ValidateDates(startDate:any):boolean{
  let isInValid=false;
  this.enableButton = true;
  if(startDate==null){
    isInValid= false;
    this.invalidDateMsg = '';
  }
  else{
    if(startDate?.validationMessage=="Please enter a valid value. The field is incomplete or has an invalid date."){
        isInValid= true;
        this.invalidDateMsg="Date is Invalid";
        this.enableButton = false;
    }
  }
  return isInValid;

}
onDateChange(event: any): void {
  const input = event.target as HTMLInputElement;
  const dateValue = input.value.trim();
  this.enableButton = true;

  if (dateValue === '') {
    this.enableButton = false;
    this._showErrorMessage(event, this.msgFormatError);
    return;
  }

  const fromDate = new Date(dateValue);
  if (!this.isValidDate(fromDate)) {
    this.enableButton = false;
    this._showErrorMessage(event, this.msgFormatError);
  } else {
    this.enableButton = true;
    this.invalidDateMsg = ''; // Clear the error message
  }
}

private _showErrorMessage(event: any, errorMessage: string): void {
  event.currentTarget.value = this.fromDate; // Keep the current value
  this.invalidDateMsg = errorMessage;
}


isValidDate(date: Date): boolean {
  return (date instanceof Date && !isNaN(date.getTime()) && (date.getFullYear() >= 1990 && date.getFullYear()<=2050));
}


public exportToExcel()
 {   
   var dat2 = this.fromDate;
    this.isLoading = true;
    this.spinnerService.showSpinner();// show spinner
    let params = new HttpParams();

   this.http.get<Array<adminRequestDashboard>>(environment.baseUrl + '/RequestList/GetLotusNotesData?requestDate='+dat2,{ params: params }).pipe(timeout(1200000)).subscribe(
     response => {
       this.shownorecords = false;
        if(response!=null && response!=undefined && response.length > 0)
        {
          this.isLoading = false;
        this.spinnerService.hideSpinner();// hide spinner

        var jsonData:Array<any> = [];
        jsonData = response.map(doc => Object.values(doc));
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
        
        worksheet['A1'].v = "ID";
        worksheet['B1'].v = "Created";
        worksheet['C1'].v = "Billing Status";
        worksheet['D1'].v = "Report Period End Date";
        worksheet['E1'].v = "Request Status";
        worksheet['F1'].v = "Request Date";
        worksheet['G1'].v = "ID Number";
        worksheet['H1'].v = "Report Length";
        worksheet['I1'].v = "Verify Service Organization";
        worksheet['J1'].v = "SAR Report Type";
        worksheet['K1'].v = "Third Preparer";
        worksheet['L1'].v = "Service Organization Deloitte Client";
        worksheet['M1'].v = "Content Type";
        worksheet['N1'].v = "SAR Authoritative Guidance";
        worksheet['O1'].v = "Number Of Requests Received For Prior Period Review";
        worksheet['P1'].v = "Allotted Date";
        worksheet['Q1'].v = "Allotted To Old";
        worksheet['R1'].v = "Application";
        worksheet['S1'].v = "Business Contact";
        worksheet['T1'].v = "Comments For Workflow";
        worksheet['U1'].v = "Cost";
        worksheet['V1'].v = "Created By";
        worksheet['W1'].v = "Date Time Of Last Action";
        worksheet['X1'].v = "Delivery Date";
        worksheet['Y1'].v = "Exclusive To Client";
        worksheet['Z1'].v = "Existing";
        worksheet['AA1'].v = "First Preparer";
        worksheet['AB1'].v = "Folder Child Count";
        worksheet['AC1'].v = "Locations Covered In Report";        
        worksheet['AD1'].v = "Modified By";
        worksheet['AE1'].v = "Need By Date";
        worksheet['AF1'].v = "Number Of Review Comments";
        worksheet['AG1'].v = "Opinion Date";
        worksheet['AH1'].v = "Report Period";
        worksheet['AI1'].v = "Report Period Start Date";
        worksheet['AJ1'].v = "Request Priority";
        worksheet['AK1'].v = "Requestor Department";
        worksheet['AL1'].v = "Requestor Title";
        worksheet['AM1'].v = "Requestors Name";
        worksheet['AN1'].v = "Requestors Name Lotus Notes";
        worksheet['AO1'].v = "Second Preparer";
        worksheet['AP1'].v = "Service Auditor";
        worksheet['AQ1'].v = "Service Sector";
        worksheet['AR1'].v = "SRCD";
        worksheet['AS1'].v = "Target Audiences";
        worksheet['AT1'].v = "Title";
        worksheet['AU1'].v = "Type Of Audit";
        worksheet['AV1'].v = "Type Of Opinion";
        worksheet['AW1'].v = "XYZ Code";
        worksheet['AX1'].v = "WBS";
        worksheet['AY1'].v = "Service Organization";
        worksheet['AZ1'].v = "WBS Client Name";
        worksheet['BA1'].v = "Requestor Email";
        worksheet['BB1'].v = "Requestor Email2";
        worksheet['BC1'].v = "MF Engagement Name";
        worksheet['BD1'].v = "MF Partner Name";
        worksheet['BE1'].v = "MF WBS Code";
        worksheet['BF1'].v = "Test Date";
        worksheet['BG1'].v = "FRCD";
        worksheet['BH1'].v = "FRRD";
        worksheet['BI1'].v = "Modified";
        worksheet['BJ1'].v = "Emphasis Of Matter";
        worksheet['BK1'].v = "Publish?";
        worksheet['BL1'].v = "Audit Signing Partner";
        worksheet['BM1'].v = "Previous Period Review W/P Available";    

        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data: Blob = new Blob([excelBuffer], { type: "xlsx" });
          FileSaver.saveAs(data, 'Lotus_Notes_' + new Date().getTime() + ".xlsx");
          //this.enableButton = false;
          this.inValidDaterange = false;
        }
        else
        {
           //put alert message here.
          this.isLoading = false;
          this.spinnerService.hideSpinner();
          this.shownorecords=true;

        }
        
      },
      error => {
        this.appInsightsService.logException(error);
        this.appInsightsService.logEvent('There was an error while exporting excel data!', error);
        console.log('export error data:', error);
        this.enableButton = false;
        this.inValidDaterange = false;
        this.shownorecords = false;
      }
    );
  }




}
