import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
  appInsights: ApplicationInsights;
  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.appInsightsConnection
      }
    });
    this.appInsights.loadAppInsights();
  }

  logPageView(name?: string, url?: string) { 
    this.appInsights.trackPageView({ name, uri: url });
  }

  logException(error: Error) { 
    this.appInsights.trackException({ exception: error });
  }

  logEvent(name: string, properties?: { [key: string]: any }) { 
    this.appInsights.trackEvent({ name: name, properties: properties });
  }

}
