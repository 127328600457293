import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-notify-popup',
  templateUrl: './notify-popup.component.html',
  styleUrls: ['./notify-popup.component.css']
})
export class AlertPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<AlertPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      isLargeMessage: any; title: string, message: string, isGlobalMFWBSCodesPage: boolean
    }
  ) { }

  getOkButtonStyle(): any {
    if (this.data.isGlobalMFWBSCodesPage) {
      return { right: '-62%' };
    } else if (this.data.isLargeMessage) {
      return { right: '-87%' };
    } else {
      return { right: '-82%' };
    }
  }
  onClose(): void {
    this.dialogRef.close(true);
  }
  onCancel(): void {
    this.dialogRef.close(false); // Emit false when the user clicks "Cancel"
  }
}
