<div class="footer" style="height:50%;width:60%;margin:auto;justify-content:center;display:flex;">
    <br /><br />
    <p style="font-size:small">
        Copyright © 2025
        Deloitte Financial Advisory Services LLP. All rights reserved.<br>
        Member of Deloitte Touche Tohmatsu Limited&nbsp;|&nbsp;<a style="color: #337AB7;" href="https://deloittenet.deloitte.com/system/Pages/DeloitteNet_Web_Privacy_statement.aspx">Deloitte Web Privacy Notice</a>
        &nbsp;|&nbsp;<a style="color: #337AB7;" target="_blank" href="https://cookienotice.deloitte.com">Cookies</a>
        &nbsp;|&nbsp;
        <a style="cursor: pointer; color: #337AB7;" class="optanon-toggle-display">Cookie Settings</a>
    </p>
</div>


