import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private renderer: Renderer2;
  private isSidebarOpen = new BehaviorSubject(false);
  isSidebarOpen$ = this.isSidebarOpen.asObservable();

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  toggleSidebar() {
    this.isSidebarOpen.next(!this.isSidebarOpen.value);
    if (this.isSidebarOpen.value) {
      this.renderer.addClass(document.body, 'sidebar-open');
    } else {
      this.renderer.removeClass(document.body, 'sidebar-open');
    }
  }
}
