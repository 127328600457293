import { AfterViewInit, Component, OnInit, QueryList, ViewChild, viewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { adminRequestDashboard } from '../request-dashboard/_interface/request-dashboard.model';
import { environment } from '../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { AdminRequestEditFormComponent } from '../admin-request-edit-form/admin-request-edit-form.component';
import { AppInsightsService } from '../app-insights.service';
import { MatSort } from '@angular/material/sort';
import { } from '@angular/material/select'
import { RequestReviewListViewComponent } from '../request-review-list-view/request-review-list-view.component';
import { SpinnerServiceService } from '../services/spinner-service.service';
import { DatePipe } from '@angular/common';
import { MatCheckbox } from '@angular/material/checkbox';
import { CommonHelperService } from '../services/common-helper.service';
import { AlertPopupComponent } from '../notify-popup/notify-popup.component';
import { Observable, of, from } from 'rxjs';
import { UserService } from '../auth.details';
import { WindowRefService } from '../services/window.service';
import { MultiExistingRequestFormComponent } from './multi-existing-request-form/multi-existing-request-form.component';
interface ColumnDef<T> {
  columnDef: string;
  header: string;
  cell: (element: T) => string;
}
@Component({
  selector: 'app-request-filter',
  templateUrl: './request-filter.component.html',
  styleUrl: './request-filter.component.css'
})
export class RequestFilterComponent implements OnInit, AfterViewInit {
  criteria: any;
  columns!: ColumnDef<any>[];
  searchText: string = '';
  dataSource: any;
  headerListName:any="";
  isGridDataAvailable:boolean=true;
  displayedColumns: any;
  filters: { [key: string]: any[] } = {};
  allotment: boolean = false;
  isAllFiltersNotRemoved:boolean=false;
  firstAppliedFilterColumn:any=[];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  uniqueValues: any;
  selectedValues: string[] = [];
  TotalCountforReportforallotment: number = 0;
  TotalCost: any;
  filterShownData:any=[];
  currentPageIndex = 0;
  initialAdminRequestData:any=[];
  isLoading = false;
  multiUpdateList:any=[];
  ispageWiseSelectAllChecked:boolean=false;
  multiSelectCheckBoxValuesPerPage: any = [];
  isBulkUpdateenable:boolean= true; 
  isDashboardPage:boolean = false;
  mailStatusMessage: string = '';
  isEmailinProgress: boolean = false;
  isExistingRequestPage: boolean = false;
  constructor(private activeroute: ActivatedRoute, private http: HttpClient, private dialog: MatDialog, private appInsightsService: AppInsightsService, 
    private spinnerService: SpinnerServiceService,private datePipe: DatePipe,private commonHelperService:CommonHelperService,private userService: UserService,private windowService: WindowRefService) {
    //this.activeroute.paramMap.subscribe((params: ParamMap) => {
    //  this.criteria = params.get('filtercriteria')?.toString();
    //})
  }

  adminDisplayedColumns = ['Select','ID', 'Created', 'BillingStatus', 'ReportPeriodEndDate', 'RequestStatus', 'RequestDate', 'IDNumber', 'ReportLength',
    'VerifyServiceOrganization', 'SARReportType', 'ThirdPreparer', 'ServiceOrganizationDeloitteClient', 'ContentType', 'SARAuthoritativeGuidance',
    'NumberOfRequestsReceivedForPriorPeriodReview', 'AllottedDate', 'AllottedToOld', 'Application', 'BusinessContact', 'CommentsForWorkflow',
    'Cost', 'CreatedBy', 'DateTimeOfLastAction', 'DeliveryDate', 'ExclusiveToClient', 'Existing', 'FirstPreparer', 'FolderChildCount',
    'LocationsCoveredInReport', 'ModifiedByName', 'NeedByDate', 'NumberOfReviewComments', 'OpinionDate', 'ReportPeriod', 'ReportPeriodStartDate',
    'RequestPriority', 'RequestorDepartment', 'RequestorTitle', 'RequestorsName', 'RequestorsNameLotusNotes', 'SecondPreparer', 'ServiceAuditor',
    'ServiceSector', 'SRCD', 'TargetAudiences', 'Title', 'TypeOfAudit', 'TypeOfOpinion', 'XYZCode', 'WBS', 'ServiceOrganization', 'WBSClientName',
    'RequestorEmail', 'RequestorEmail2', 'MFEngagementName', 'MFPartnerName', 'MFWBSCode', 'TestDate', 'FRCD', 'FRRD', 'Modified', 'EmphasisOfMatter']
  adminColumnDisplayNames = this.adminDisplayedColumns.reduce((acc, column) => {
    acc[column] = acc[column] || column.split(/(?=[A-Z])/).join(' ');
    return acc;
  }, {
    'ID': 'ID',
    'IDNumber': 'ID Number',
    'SARReportType': 'SAR Report Type',
    'SARAuthoritativeGuidance': 'SAR Authoritative Guidance',
    'SRCD': 'SRCD',
    'XYZCode': 'XYZ Code',
    'WBS': 'WBS',
    'MFEngagementName': 'MF Engagement Name',
    'MFPartnerName': 'MF Partner Name',
    'MFWBSCode': 'MF WBS Code',
    'FRCD': 'FRCD',
    'FRRD': 'FRRD'
  } as Record<string, string>);
  adminColumns = this.adminDisplayedColumns.map(column => ({
    columnDef: column,
    header: this.adminColumnDisplayNames[column] || column.split(/(?=[A-Z])/).join(' '),
    cell: (element: any) => `${element[column]}`
  }));

  adminDisplayedReportforallotmentColumns = ['ServiceOrganization', 'Application', 'ReportPeriodStartDate', 'ReportPeriodEndDate', 'RequestorsName', 'Title', 'ServiceAuditor', 'WBS', 'RequestDate', 'RequestStatus', 'AllottedDate', 'ContentType', 'ExclusiveToClient', 'FirstPreparer', 'IDNumber', 'OpinionDate', 'ReportLength', 'SARAuthoritativeGuidance', 'SARReportType', 'TypeOfOpinion', 'XYZCode', 'RequestorEmail'];

  adminDisplayedReviewinprocessColumns = ['IDNumber', 'ServiceOrganization', 'Application', 'NeedByDate', 'RequestDate', 'RequestStatus', 'FRRD', 'FirstPreparer', 'FRCD', 'SecondPreparer', 'SRCD', 'ThirdPreparer', 'ReportLength', 'RequestorsName', 'Title', 'AllottedDate', 'BillingStatus', 'DeliveryDate', 'RequestorEmail'];

  adminDisplayedexistinreportsColumns = ['Select','Created', 'ServiceOrganization', 'Application', 'IDNumber', 'ReportPeriodStartDate', 'Title', 'BillingStatus', 'ReportPeriodEndDate', 'RequestorsName', 'RequestDate', 'WBS', 'ServiceAuditor', 'DeliveryDate', 'ExclusiveToClient', 'RequestorEmail'];

  adminDisplayedtobebilledColumns = ['Select','ServiceOrganization', 'Application', 'ReportPeriodStartDate', 'ReportPeriodEndDate', 'RequestorsName', 'IDNumber', 'WBS', 'WBSClientName', 'Title', 'BillingStatus', 'RequestDate', 'Cost', 'DeliveryDate', 'Modified', 'ModifiedByName', 'RequestorEmail', 'MFEngagementName', 'MFPartnerName', 'MFWBSCode'];

  adminDisplayeddashboardColumns = ['IDNumber', 'ServiceOrganization', 'Application', 'NeedByDate', 'RequestDate', 'RequestStatus', 'FirstPreparer', 'SecondPreparer', 'ThirdPreparer', 'ReportPeriodStartDate', 'ReportPeriodEndDate', 'RequestorsName', 'Title', 'ExclusiveToClient', 'FRRD', 'FRCD', 'SARAuthoritativeGuidance', 'SARReportType', 'ServiceAuditor', 'TypeOfOpinion', 'AllottedDate', 'CommentsForWorkflow'];
  @ViewChildren(MatCheckbox) checkboxes!: QueryList<MatCheckbox>;
  ngOnInit() {
    this.columns = this.adminColumns;
    //this.displayedColumns = this.adminDisplayedColumns;
    this.dataSource = new MatTableDataSource<adminRequestDashboard>();
    // this.dataSource.paginator = this.paginator;
   // this.dataSource.sort = this.sort;
    this.activeroute.paramMap.subscribe((params: ParamMap) => {
      //this.isLoading = true;
     // this.spinnerService.showSpinner();
     this.multiUpdateList=[];//Clearing the multiupdate list
     this.multiSelectCheckBoxValuesPerPage=[];//Clearing the multiSelectCheckBoxValuesPerPage list
     this.clearAllAppliedActions();//Clearing the filters and search text
     this.isBulkUpdateenable=true;
      this.criteria = params.get('filtercriteria')?.toString();
      if (this.criteria.toLowerCase() == "report for allotment") {
        // this.allotment = true;
        this.displayedColumns = this.adminDisplayedReportforallotmentColumns;
        this.headerListName = "Request Review List";
        this.trackPageView("Report For Allotment");
      } else if (this.criteria.toLowerCase() == "review in process") {
        this.displayedColumns = this.adminDisplayedReviewinprocessColumns;
        this.headerListName = "Request Review List";
        this.trackPageView("Review In Process");
      } else if (this.criteria.toLowerCase() == "existing requests") {
        this.displayedColumns = this.adminDisplayedexistinreportsColumns;
        this.headerListName = "Request Review List";
        this.trackPageView("Existing Requests");
      } else if (this.criteria.toLowerCase() == "to be billed") {
        this.displayedColumns = this.adminDisplayedtobebilledColumns;
        this.headerListName = "Request Review List";
        this.trackPageView("To Be Billed");
      } else if (this.criteria.toLowerCase() == "dashboard") {
        this.displayedColumns = this.adminDisplayeddashboardColumns;
        this.headerListName = "Request Review List";
        this.trackPageView("Dashboard");
      }
      this.dataSource.filter = "";
      
      setTimeout(() => {
        this.getAdminRequestData(this.criteria, 1, 50, "ID", true);
},0)
    })
  }

  trackPageView(pageName: string) {
    if (this.windowService.nativeWindow._satellite) {
      //alert("got the satellite obj");
  
      let analyticsDataLayer = {
        'pageInfo': {
          'pageName': pageName, // Page Name
          'userID': this.userService.userEmail.split("@")[0], // User Alias, no domain
          'applicationName': "SAR" // Name of application
        }
      };
  
      this.windowService.nativeWindow.analyticsDataLayer = analyticsDataLayer;
      this.windowService.nativeWindow._satellite.track("pageView");
    }
    //show initiate email button when dashboard or existing requests
    this.isDashboardPage = pageName.toLowerCase() === 'dashboard';
    this.isExistingRequestPage = pageName.toLowerCase() === 'existing requests';
  }
  initiateEmailForExistingRequests() {
    this.isEmailinProgress = true;
    this.http.post(`${environment.baseUrl}/Email/ProcessAndSendEmailsForExistingRequests`, {})
      .subscribe(
        (response: any) => {
          this.isEmailinProgress = false;
          this.openAlertDialog('Info', response.message, false, '2');
        },
        (error) => {
          this.isEmailinProgress = false;
          this.openAlertDialog('Error', 'An error occurred while sending the email', false, '2');
        }
      );
  }
  initiateEmail() {
    // Show the spinner 
    //this.spinnerService.showSpinner();
    //Updated the mail status message for the Action initiative Browser
    this.mailStatusMessage="Email processing in progress";
    this.loadmailStatusDetails();
    // Call  API 
    this.http.get(`${environment.baseUrl}/RequestList/InitiateDashboardEmails`,
      { responseType: 'text' }
    ).subscribe({
      next:(response:any) => {
        // Hide the spinner
        //this.spinnerService.hideSpinner();
        //Updated the mail status message for the Action initiative Browser
        this.mailStatusMessage="Emails have been sent for "+this.datePipe.transform(new Date(), 'MM/dd/yyyy');
        this.loadmailStatusDetails();
        console.log('Mail Sent successfully:', response);
        this.openAlertDialog('Success', 'Mail Sent successfully.', false, '2');
        this.isEmailinProgress = true;
      },
      error:error => {
        //this.spinnerService.hideSpinner();
        this.mailStatusMessage="Emails Failed to Send";
        this.loadmailStatusDetails();
        console.error('Failed to Send the Mail', error);
        this.openAlertDialog('Error', 'Failed to Send the Mails', false, '2');
      }}
    );
  }
  clearAllAppliedActions(){ 
    this.searchText = '';
    this.tempFilters = {};
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = this.customFilter();
    if (this.paginator) {
      this.paginator.page.subscribe((pageEvent: PageEvent) => {
        this.currentPageIndex = pageEvent.pageIndex;
        this.dataSource.data= this.initialAdminRequestData;//this.commonHelperService.loadPageWiseData(pageEvent.pageIndex + 1, pageEvent.pageSize,this.initialAdminRequestData);
        this.updatePageWiseSelectAllCheckBox();
        setTimeout(() => {
          this.paginator.pageIndex = pageEvent.pageIndex + 1 - 1;
          this.paginator.pageSize = pageEvent.pageSize;
          this.paginator.length = this.dataSource.data.length;
        }, 0);
      });
    }
  }

  updatePageWiseSelectAllCheckBox(){
    this.ispageWiseSelectAllChecked=false;
   if(this.multiSelectCheckBoxValuesPerPage.length>0){
    this.multiSelectCheckBoxValuesPerPage.forEach((element:any) => {
      if(element.pageIndex==this.paginator.pageIndex){
        this.ispageWiseSelectAllChecked=element.isPageLevelSelectAllChecked;
      }     
    });
   }
  }
  public getAdminRequestData = (criteria: string, pageNumber: number, pageSize: number, sortBy: string, sortDescending: boolean) => {
    this.isLoading = true;
    this.spinnerService.showSpinner();
    let params = new HttpParams();

    params = params.append('criteria', criteria.toString());
   // params = params.append('searchText', this.selectedYear.toString());
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());
    params = params.append('sortBy', sortBy);
    params = params.append('sortDescending', sortDescending.toString());

    this.http.get<{ Data: adminRequestDashboard[], Total: number, TotalCost: any,DashboardEmailStatus:string }>(environment.baseUrl + '/RequestList/GetAdminRequestFilterList', { params: params }).subscribe(
      response => {
        this.isLoading = false;
        this.spinnerService.hideSpinner();
        response.Data.forEach((column:any)=>{
          column.RequestorEmail=column.RequestorEmail==null?"":column.RequestorEmail;
          if(!column.RequestorEmail.includes("@")){
            let reqEmail=column.RequestorEmail;
            column.RequestorEmail=column.RequestorEmail2;
            column.RequestorEmail2=reqEmail;
          }
          if(this.criteria.toLowerCase() == 'existing requests' || criteria.toLowerCase() == 'to be billed'){
            column.IsSelected=false;
          }
        });
        this.ispageWiseSelectAllChecked = false;
        if(response.Data.length==0){this.isGridDataAvailable=false;}else{this.isGridDataAvailable=true;}
        this.initialAdminRequestData=response.Data;
        let defaultData={
          key:"",
          value:response.Data
        }
        this.filterShownData.push(defaultData);
        this.dataSource.data = response.Data;
        this.dataSource.sort = this.sort;
        this.TotalCountforReportforallotment = response.Total;
        this.TotalCost = response.TotalCost;
        //Updated the mail status message
        this.mailStatusMessage=response.DashboardEmailStatus
        if(response.DashboardEmailStatus.includes("Emails have been sent for"))
        {
          let emailDate=response.DashboardEmailStatus.split("Emails have been sent for ")[1];
          let emailStatusDate=this.datePipe.transform(new Date(emailDate), 'MM/dd/yyyy');
          this.mailStatusMessage = "Emails have been sent for "+emailStatusDate;
        }
        else
        {
          this.mailStatusMessage=response.DashboardEmailStatus;
        }
        if (this.criteria.toLowerCase() == "dashboard") {
          this.loadmailStatusDetails();
          this.disableEmailGenerateBtn(response.DashboardEmailStatus);
        }
        //this.dataSource.data = this.dataSource.data.map((obj: adminRequestDashboard) => {
        //  if (obj.BillingStatus == null || obj.BillingStatus == "null" || obj.BillingStatus == "NULL") {
        //    obj.BillingStatus = "";
        //  }
        //  if (obj.RequestStatus == null || obj.RequestStatus == "null" || obj.RequestStatus == "NULL") {
        //    obj.RequestStatus = "";
        //  }
        //  if (obj.IDNumber == null || obj.IDNumber == "null" || obj.IDNumber == "NULL") {
        //    obj.IDNumber = "";
        //  }
        //  if (obj.ReportLength == null || obj.ReportLength == "null" || obj.ReportLength == "NULL") {
        //    obj.ReportLength = "";
        //  }
        //  if (obj.VerifyServiceOrganization == null || obj.VerifyServiceOrganization == "null" || obj.VerifyServiceOrganization == "NULL") {
        //    obj.VerifyServiceOrganization = "";
        //  }
        //  if (obj.ThirdPreparer == null || obj.ThirdPreparer == "null" || obj.ThirdPreparer == "NULL") {
        //    obj.VerifyServiceOrganization = "";
        //  }
        //  if (obj.ServiceOrganizationDeloitteClient == null || obj.ServiceOrganizationDeloitteClient == "null" || obj.ServiceOrganizationDeloitteClient == "NULL") {
        //    obj.ServiceOrganizationDeloitteClient = "";
        //  }
        //  if (obj.ContentType == null || obj.ContentType == "null" || obj.ContentType == "NULL") {
        //    obj.ContentType = "";
        //  }
        //  if (obj.NumberOfRequestsReceivedForPriorPeriodReview == null || obj.NumberOfRequestsReceivedForPriorPeriodReview == "null" || obj.NumberOfRequestsReceivedForPriorPeriodReview == "NULL") {
        //    obj.NumberOfRequestsReceivedForPriorPeriodReview = "";
        //  }
        //  if (obj.AllottedDate == null || obj.AllottedDate == "null" || obj.ContentType == "NULL") {
        //    obj.AllottedDate = "";
        //  }
        //  if (obj.AllottedDate == null || obj.AllottedDate == "null" || obj.AllottedDate == "NULL") {
        //    obj.AllottedDate = "";
        //  }
        //  if (obj.AllottedToOld == null || obj.AllottedToOld == "null" || obj.AllottedToOld == "NULL") {
        //    obj.AllottedToOld = "";
        //  }
        //  if (obj.Application == null || obj.Application == "null" || obj.Application == "NULL") {
        //    obj.Application = "";
        //  }
        //  if (obj.BusinessContact == null || obj.BusinessContact == "null" || obj.BusinessContact == "NULL") {
        //    obj.BusinessContact = "";
        //  }
        //  if (obj.CommentsForWorkflow == null || obj.CommentsForWorkflow == "null" || obj.CommentsForWorkflow == "NULL") {
        //    obj.CommentsForWorkflow = "";
        //  }
        //  if (obj.Cost == null || obj.Cost == "null" || obj.Cost == "NULL") {
        //    obj.Cost = "";
        //  }
        //  if (obj.DateTimeOfLastAction == null || obj.DateTimeOfLastAction == "null" || obj.DateTimeOfLastAction == "NULL") {
        //    obj.DateTimeOfLastAction = "";
        //  }
        //  if (obj.DeliveryDate == null || obj.DeliveryDate == "null" || obj.DeliveryDate == "NULL") {
        //    obj.DeliveryDate = "";
        //  }
        //  if (obj.ExclusiveToClient == null || obj.ExclusiveToClient == "null" || obj.ExclusiveToClient == "NULL") {
        //    obj.ExclusiveToClient = "";
        //  }
        //  if (obj.Existing == null || obj.Existing == "null" || obj.Existing == "NULL") {
        //    obj.Existing = "";
        //  }
        //  if (obj.FirstPreparer == null || obj.FirstPreparer == "null" || obj.FirstPreparer == "NULL") {
        //    obj.FirstPreparer = "";
        //  }
        //  if (obj.FolderChildCount == null || obj.FolderChildCount == "null" || obj.FolderChildCount == "NULL") {
        //    obj.FolderChildCount = "";
        //  }
        //  if (obj.LocationsCoveredInReport == null || obj.LocationsCoveredInReport == "null" || obj.LocationsCoveredInReport == "NULL") {
        //    obj.LocationsCoveredInReport = "";
        //  }
        //  if (obj.ModifiedBy == null || obj.ModifiedBy == "null" || obj.ModifiedBy == "NULL") {
        //    obj.ModifiedBy = "";
        //  }
        //  if (obj.NeedByDate == null || obj.NeedByDate == "null" || obj.NeedByDate == "NULL") {
        //    obj.NeedByDate = "";
        //  } else {
        //    obj.NeedByDate = obj.NeedByDate.substring(0, 10);
        //  }
        //  if (obj.NumberOfReviewComments == null || obj.NumberOfReviewComments == "null" || obj.NumberOfReviewComments == "NULL") {
        //    obj.NumberOfReviewComments = "";
        //  }
        //  if (obj.OpinionDate == null || obj.OpinionDate == "null" || obj.OpinionDate == "NULL") {
        //    obj.OpinionDate = "";
        //  }
        //  if (obj.ReportPeriod == null || obj.ReportPeriod == "null" || obj.ReportPeriod == "NULL") {
        //    obj.ReportPeriod = "";
        //  }
        //  if (obj.ReportPeriodStartDate == null || obj.ReportPeriodStartDate == "null" || obj.ReportPeriodStartDate == "NULL") {
        //    obj.ReportPeriodStartDate = "";
        //  }
        //  if (obj.RequestPriority == null || obj.RequestPriority == "null" || obj.RequestPriority == "NULL") {
        //    obj.RequestPriority = "";
        //  }
        //  if (obj.RequestorDepartment == null || obj.RequestorDepartment == "null" || obj.RequestorDepartment == "NULL") {
        //    obj.RequestorDepartment = "";
        //  }
        //  if (obj.RequestorTitle == null || obj.RequestorTitle == "null" || obj.RequestorTitle == "NULL") {
        //    obj.RequestorTitle = "";
        //  }
        //  if (obj.RequestorsName == null || obj.RequestorsName == "null" || obj.RequestorsName == "NULL") {
        //    obj.RequestorsName = "";
        //  }
        //  if (obj.RequestorsNameLotusNotes == null || obj.RequestorsNameLotusNotes == "null" || obj.RequestorsNameLotusNotes == "NULL") {
        //    obj.RequestorsNameLotusNotes = "";
        //  }
        //  if (obj.SecondPreparer == null || obj.SecondPreparer == "null" || obj.SecondPreparer == "NULL") {
        //    obj.SecondPreparer = "";
        //  }
        //  if (obj.ServiceAuditor == null || obj.ServiceAuditor == "null" || obj.ServiceAuditor == "NULL") {
        //    obj.ServiceAuditor = "";
        //  }
        //  if (obj.ServiceSector == null || obj.ServiceSector == "null" || obj.ServiceSector == "NULL") {
        //    obj.ServiceSector = "";
        //  }
        //  if (obj.SRCD == null || obj.SRCD == "null" || obj.SRCD == "NULL") {
        //    obj.SRCD = "";
        //  }
        //  if (obj.TargetAudiences == null || obj.TargetAudiences == "null" || obj.TargetAudiences == "NULL") {
        //    obj.TargetAudiences = "";
        //  }

        //  if (obj.Title == null || obj.Title == "null" || obj.Title == "NULL") {
        //    obj.Title = "";
        //  }
        //  if (obj.TypeOfAudit == null || obj.TypeOfAudit == "null" || obj.TypeOfAudit == "NULL") {
        //    obj.TypeOfAudit = "";
        //  }
        //  if (obj.TypeOfOpinion == null || obj.TypeOfOpinion == "null" || obj.TypeOfOpinion == "NULL") {
        //    obj.TypeOfOpinion = "";
        //  }
        //  if (obj.XYZCode == null || obj.XYZCode == "null" || obj.XYZCode == "NULL") {
        //    obj.XYZCode = "";
        //  }
        //  if (obj.WBS == null || obj.WBS == "null" || obj.WBS == "NULL") {
        //    obj.WBS = "";
        //  }
        //  if (obj.ServiceOrganization == null || obj.ServiceOrganization == "null" || obj.ServiceOrganization == "NULL") {
        //    obj.ServiceOrganization = "";
        //  }
        //  if (obj.WBSClientName == null || obj.WBSClientName == "null" || obj.WBSClientName == "NULL") {
        //    obj.WBSClientName = "";
        //  }
        //  if (obj.RequestorEmail == null || obj.RequestorEmail == "null" || obj.RequestorEmail == "NULL") {
        //    obj.RequestorEmail = "";
        //  }
        //  if (obj.RequestorEmail2 == null || obj.RequestorEmail2 == "null" || obj.RequestorEmail2 == "NULL") {
        //    obj.RequestorEmail2 = "";
        //  }
        //  if (obj.MFEngagementName == null || obj.MFEngagementName == "null" || obj.MFEngagementName == "NULL") {
        //    obj.MFEngagementName = "";
        //  }
        //  if (obj.MFPartnerName == null || obj.MFPartnerName == "null" || obj.MFPartnerName == "NULL") {
        //    obj.MFPartnerName = "";
        //  }
        //  if (obj.MFWBSCode == null || obj.MFWBSCode == "null" || obj.MFWBSCode == "NULL") {
        //    obj.MFWBSCode = "";
        //  }
        //  if (obj.TestDate == null || obj.TestDate == "null" || obj.TestDate == "NULL") {
        //    obj.TestDate = "";
        //  }
        //  if (obj.FRCD == null || obj.FRCD == "null" || obj.FRCD == "NULL") {
        //    obj.FRCD = "";
        //  }
        //  if (obj.FRRD == null || obj.FRRD == "null" || obj.FRRD == "NULL") {
        //    obj.FRRD = "";
        //  }
        //  if (obj.Modified == null || obj.Modified == "null" || obj.Modified == "NULL") {
        //    obj.Modified = "";
        //  } else {
        //    obj.Modified = obj.Modified.substring(0, 10);
        //  }
        //  if (obj.EmphasisOfMatter == null || obj.EmphasisOfMatter == "null" || obj.EmphasisOfMatter == "NULL") {
        //    obj.EmphasisOfMatter = "";
        //  }
        //  if (obj.ThirdPreparer == null || obj.ThirdPreparer == "null" || obj.ThirdPreparer == "NULL") {
        //    obj.ThirdPreparer = "";
        //  }
        //  if (obj.RequestDate == null || obj.RequestDate == "null" || obj.RequestDate == "NULL") {
        //    obj.RequestDate = "";

        //  } else {
        //    obj.RequestDate = obj.RequestDate.substring(0, 10);
        //  }
        //  if (obj.SARAuthoritativeGuidance == null || obj.SARAuthoritativeGuidance == "null" || obj.SARAuthoritativeGuidance == "NULL") {
        //    obj.SARAuthoritativeGuidance = "";
        //  }
        //  // obj.Created = this.datePipe.transform(obj.Created, 'yyyy-MM-dd');
        //  if (obj.Created == null || obj.Created == "null" || obj.Created == "NULL") {
        //    obj.Created = "";

        //  } else {
        //    obj.Created = obj.Created.substring(0, 10);
        //  }
        //  //  obj.Created = obj.Created.substring(0, 10);


        //  return obj
        //})
        // this.uniqueValues = [...new Set(this.dataSource.data.map((item: { BillingStatus: any }) => item.BillingStatus))];
        // this.dataSource.data.foreach
        //this.isLoading = false;
        //setTimeout(() => {
        //  this.paginator.pageIndex = pageNumber - 1;
        //  this.paginator.pageSize = pageSize;
        //  this.paginator.length = response.Total;
        //}, 0);
      },
      //error => {
      //  this.appInsightsService.logException(error);
      //  this.appInsightsService.logEvent('There was an error while fetching data!', error);
      //}
    );
  }
  disableEmailGenerateBtn(emailStatus:string){
    if(emailStatus.includes("Emails have been sent for")){
      let emailDate=emailStatus.split("Emails have been sent for ")[1];
      let currentDate=this.datePipe.transform(new Date(), 'MM/dd/yyyy');
      let emailStatusDate=this.datePipe.transform(new Date(emailDate), 'MM/dd/yyyy');
      emailStatus = emailStatus.split("Emails have been sent for ")[0]+emailStatusDate;
      if(emailStatusDate==currentDate){
        this.isEmailinProgress=true;
      }
      else{
        this.isEmailinProgress=false;
      }
    }

  }
loadmailStatusDetails(){
  if(this.mailStatusMessage.includes("Email processing in progress")){
    this.isEmailinProgress=true;
  }
  else{
    this.isEmailinProgress=false;
  }
}
  loadoptions(columnname: string) {
    //this.uniqueValues = [...new Set(this.dataSource.data.map((item: { columnname: any }) => item[columnname]))];
    //this.uniqueValues = ["valuew"];
    this.uniqueValues = [...new Set(this.dataSource.data.map((item: { [x: string]: any; }) => item[columnname]))];
    this.uniqueValues = this.uniqueValues.filter((item: any) => item != '');

  }
  onCheckboxChange(event: any, value: string) {
    if (event.checked) {
      this.selectedValues.push(value);
    } else {
      const index = this.selectedValues.indexOf(value);
      if (index >= 0) {
        this.selectedValues.splice(index, 1);
      }
    }
    this.applyFilter();
  }

  //EDIT ITEM
  editItem(element: any) {
    const dialogRef = this.dialog.open(AdminRequestEditFormComponent, {
      data: {
        ...element,
        pagename: this.criteria // added a pagename field
      },
      panelClass: 'custom-dialog',
      height: '510px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // fetching the updated data after the dialog is closed
        this.getAdminRequestData(this.criteria, 1, 50, "ID", true);
      }
    });
  }
  //Multi Edit
  multiUpdateItem(element: any) {
    const dialogRef = this.dialog.open(MultiExistingRequestFormComponent, {
      data: [
        ...this.multiUpdateList
      ],
      panelClass: 'custom-dialog',
      width: '450px',
      height:'250px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result=="Success") {
        // fetching the updated data after the dialog is closed
        this.isBulkUpdateenable=true;
        this.multiUpdateList=[];
       this.getAdminRequestData(this.criteria, 1, 50, "ID", true);
      this.openAlertDialog("Success", "The selected records have been updated successfully.", false, "2");
      }
      else if (result=="Failed") {
       this.openAlertDialog("Failure", "The selected records have been failed to updated.", false, "2");
      }

    });
  }
  //VIEW ITEM
  viewItem(element: any) {
    // this.isEditMode = false;
    const dialogRef = this.dialog.open(RequestReviewListViewComponent, {
      data: {
        // element
        ...element,
        //  isEditMode: this.isEditMode
      }, panelClass: 'custom-dialog', height: '510px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  openAlertDialog(title: string, message: string, isGlobalMFWBSCodesPage: boolean, msglength: string = "2"): Observable<boolean> {
    const dialogRef = this.dialog.open(AlertPopupComponent, {
      data: { title, message, isGlobalMFWBSCodesPage },
      width: '40%',
      height: msglength == "2" ? '30%' : msglength == "1" ? "35%" : '48%',
      disableClose: true
    });

    return new Observable<boolean>(observer => {
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          observer.next(true);
        } else {
          observer.next(false);
        }
        observer.complete();
      });
    });
  }

  deleteItem(element: any) {
    const isGlobalMFWBSCodesPage = true;
    this.openAlertDialog('Warning', 'Are you sure you want to delete the record?', isGlobalMFWBSCodesPage).subscribe(confirmation => {
      if (confirmation) {
      // User clicked 'OK', perform the deletion
        this.http.delete(environment.baseUrl + `/RequestList/DeleteRequestList/${element.ID}`).subscribe(
          () => {
            // Handle successful deletion here, e.g., refresh the data
            this.getAdminRequestData(this.criteria, 1, 50, "ID", true);
          },
          error => {
            this.appInsightsService.logException(error);
            this.appInsightsService.logEvent('There was an error while deleting the item!', error);
          }
        );
      } else {
      // User clicked 'Cancel', do nothing
      }
    });
  }
  ValidateIsFilterExists(){
    this.displayedColumns.forEach((column:any)=>{
      if(this.tempFilters[column]?.length>0){this.isAllFiltersNotRemoved=true;}
      if(this.tempFilters[column]?.length==0){delete this.tempFilters[column];}
    })
  }
  applyFilter() {
    this.dataSource.filterPredicate = (data: Element, filter: string) => {
      const filterArray = filter.split(',');
      return Object.values(data).some(val => filterArray.includes(val?.toString()));
    };
    this.dataSource.filter = this.selectedValues.join(',');
  }
  Filterchange(data: Event) {
    const value = (data.target as HTMLInputElement).value;
    this.dataSource.filter = value;
  }

  getUniqueColumnValues(columnName: string): string[] {
    // Get the filtered data
    let filteredData: any;
    this.dataSource.filteredData = this.dataSource.filteredData.map((obj: adminRequestDashboard) => {
      if (obj.Created == null || obj.Created == "null" || obj.Created == "NULL") {
        obj.Created = "";

      } else {
        obj.Created = obj.Created.substring(0, 10);
      }
      return obj;
    });
    filteredData=this.dataSource.filteredData;
    if(this.firstAppliedFilterColumn.length>0){
      this.filterShownData.forEach((element: any) => {
        if (element.key == columnName) {
          filteredData = element.value;
        }
      })
   }

    const columnValues = filteredData.map((item: any) => {
      if (item[columnName] instanceof Date) {
        // Format the date
        const date = new Date(item[columnName]);
        const formattedDate = this.datePipe.transform(date, 'MM/dd/yyyy');
        // Check if formattedDate is not null before using it
        if (formattedDate) {
          return this.commonHelperService.ValidateFilterData(formattedDate);
        }
      }
      return this.commonHelperService.ValidateFilterData(item[columnName]);
    });
    return [...new Set(columnValues as string[])];
  }

  getfiltercolour(column: string) {
    const hasIdKey = Object.prototype.hasOwnProperty.call(this.tempFilters, column);
    return hasIdKey;
  }
  selectedFilters: any = {};

  tempFilters: any = {};

  filterOptions: { [key: string]: string[] } = {};

  applyColumnFilter(event: any, option: any, column: string) {
    // Convert option to a string
    this.isAllFiltersNotRemoved=false;
    let columnWiseFilterOptions:any={};
    let filteredValueAlreadyExists=false;
    const optionStr = option==null||option==undefined||option=="null"||option=="NULL"?"":String(option);
    if(this.firstAppliedFilterColumn.length==0){
      this.firstAppliedFilterColumn.push(column);
      columnWiseFilterOptions = {
        key: column,
        value: this.dataSource.filteredData
      }
      this.filterShownData.push(columnWiseFilterOptions);
    }
      else{
        this.firstAppliedFilterColumn.forEach((element:any) => {
          if(element==column){
            filteredValueAlreadyExists=true;
          }
        });
        if(!filteredValueAlreadyExists){this.firstAppliedFilterColumn.push(column);
          columnWiseFilterOptions={ 
            key:column,
            value:this.dataSource.filteredData
           }
              this.filterShownData.push(columnWiseFilterOptions);
        }
      }

    // If the checkbox is checked, add the option to the temporary filters
    if (event.checked) {
      this.commonHelperService.LoadFilterAppliedFields(event?.source?.id,column);
      this.isAllFiltersNotRemoved=true;
      if(optionStr==""){
        this.tempFilters=this.commonHelperService.loadFiltersEmptyDataValues(this.tempFilters,column);}
      else{
      if (this.tempFilters[column]) {
        this.tempFilters[column].push(optionStr ? optionStr.toLowerCase() : '');
      } else {
        this.tempFilters[column] = [optionStr ? optionStr.toLowerCase() : ''];
      }
    }
    } else {
      this.commonHelperService.RemoveFilterAppliedFields(event?.source?.id,column);
      // If the checkbox is not checked, remove the option from the temporary filters
      if(optionStr==""){
        this.tempFilters=this.commonHelperService.removeFiltersEmptyDataValues(this.tempFilters,column);}
      else{
      const index = this.tempFilters[column].indexOf(optionStr ? optionStr.toLowerCase() : '');
      if (index >= 0) {
        this.tempFilters[column].splice(index, 1);
      }
    }
      this.ValidateIsFilterExists();
    }
    if(this.isAllFiltersNotRemoved){this.applyFilters();}else{this.clearFilter("");}
  }


  customFilterBck(): (data: any, filter: string) => boolean {
    const filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      return Object.keys(searchTerms).every(name => {
        if (searchTerms[name].length === 0) {
          return true;  // If no filters are set on this column, then return true
        }
        let dataStr = '';
        if (data[name] === null) {
          // If data[name] is null, set dataStr to "null"
          dataStr = 'null';
        }
        else if (data[name]) {
          // Convert data[name] to a string
          dataStr = String(data[name]).trim().toLowerCase();
        }
        return searchTerms[name].includes(dataStr);
      });
    };
    return filterFunction;
  }
  customFilter(): (data: any, filter: string) => boolean {
    return (data: any, filter: string): boolean => {
      const { searchText, filters } = JSON.parse(filter);
      const globalMatch = !searchText || Object.values(data).some(value =>
        String(value).toLowerCase().includes(searchText)
      );
 
      const columnMatch = Object.keys(filters).every(column => {
        return filters[column].includes(String(data[column]).toLowerCase());
      });
 
      return globalMatch && columnMatch;
    };
  }
  applyFilters() {
    this.ValidateIsFilterExists();
    // Copy tempFilters to selectedFilters
    this.selectedFilters = JSON.parse(JSON.stringify(this.tempFilters));
    // Trigger the filter
   // this.dataSource.filter = JSON.stringify(this.selectedFilters);
   if(!this.isAllFiltersNotRemoved){
    this.dataSource.filter = JSON.stringify({ searchText: this.searchText, filters: {} });
   }else{
    this.dataSource.filter = JSON.stringify({ searchText: this.searchText, filters: this.selectedFilters });
   }
    if(this.dataSource.filteredData.length==0){this.isGridDataAvailable=false;}else{this.isGridDataAvailable=true;}
    //Update Count and Cost
    this.CalculateCostAndCount();
    // Update the filter options for all columns
    this.columns.forEach(column => {
      const uniqueValues = this.getUniqueColumnValues(column.columnDef);
      // Update the filter options for the column
      this.updateFilterOptions(column.columnDef, uniqueValues);
    });
  }

  updateFilterOptions(column: string, uniqueValues: string[]) {
    this.filterOptions[column] = uniqueValues;
  }


  clearFilter(column: string) {
    this.isAllFiltersNotRemoved=false;   
    // Remove the filters for the column
    delete this.selectedFilters[column];
    delete this.tempFilters[column];
    this.ValidateIsFilterExists();
    // Reset the checkboxes
    this.clearCheckboxes(column);
    // this.checkboxes.forEach((checkbox:any) => {
    //   this.commonHelperService.filterAppliedFields.forEach((element:any) => {
    //     if(element.value==column&&checkbox.id==element.key||column==""){
    //       checkbox.checked = false;
    //     }
    //   });
    // });

    // Reset the filter predicate to the original one
   // this.dataSource.filterPredicate = this.customFilter();
    if(this.isAllFiltersNotRemoved||this.searchText!=""){
      this.applyFilters();
    }
    else{
   this.firstAppliedFilterColumn=[];
    this.filterShownData=[];
    // Trigger the filter
    this.dataSource.filter = "";

    // Fetch the data without any filters
    this.dataSource.data = this.initialAdminRequestData;
    this.dataSource.sort = this.sort;
    this.CalculateCostAndCount();
   // this.getAdminRequestData(this.criteria, 1, 50, "ID", true);
    }
  
  }
  clearCheckboxes(column:any) {
    this.ispageWiseSelectAllChecked = false;
    this.multiSelectCheckBoxValuesPerPage=[];
    this.isBulkUpdateenable = true;
    this.checkboxes.forEach((checkbox: MatCheckbox) => {
      this.commonHelperService.filterAppliedFields.forEach((element:any) => {
        if(element.value==column&&checkbox.id==element.key||column==""){
          checkbox.checked = false;
        }
      });
    });
  }
  applyGlobalFilter(filterValue: string) {
    // Show the spinner
    this.spinnerService.showSpinner();
    // delay to ensure spinner is visible
    setTimeout(() => {
    this.searchText = filterValue.trim().toLowerCase();
      this.applyFilters();
      // Hide the spinner after data is loaded
      this.spinnerService.hideSpinner();
    }, 500); //delay
  }
  
  resetFilters() {
    this.firstAppliedFilterColumn=[];
    this.filterShownData=[];
    this.searchText = '';
    this.filters = {};
    this.selectedFilters = {};
    this.tempFilters = {};
    this.applyFilters();
    this.clearCheckboxes("");
    this.resetCheckBoxSelection();
  }
  applyGlobalFilterBck(filterValue: any) {
    // Reset the filter predicate to the default
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      // Transform the data into a lowercase string of key value pairs.
      const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
        // Check if data[key] is not null before concatenating
        return currentTerm + (data[key] ? data[key] : '') + '◬';
      }, '').toLowerCase();

      // Transform the filter by converting it to lowercase and removing whitespace.
      const transformedFilter = filter.trim().toLowerCase();

      return dataStr.indexOf(transformedFilter) != -1;
    };

    this.dataSource.filter = filterValue.trim().toLowerCase();
    if(this.dataSource.filteredData.length==0){this.isGridDataAvailable=false;}else{this.isGridDataAvailable=true;}
    this.CalculateCostAndCount();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
CalculateCostAndCount(){
  if(this.criteria.toLowerCase() == 'to be billed'){
    let totalCost:any=0;
  this.dataSource.filteredData.forEach((element:any) => {
     totalCost+=element.Cost==null||element.Cost==""?0:Number(element.Cost);
  });
  this.TotalCost=totalCost.toString();
  }
  this.TotalCountforReportforallotment=this.dataSource.filteredData.length;
}
  resetFiltersBck() {
    this.firstAppliedFilterColumn=[];
    this.filterShownData=[];
    // Clear the filter value
    this.dataSource.filter = '';
    this.searchText = '';

    // Clear the selected and temporary filters
    this.selectedFilters = {};
    this.tempFilters = {};

    // Reset the checkboxes
    this.checkboxes.forEach(checkbox => {
      checkbox.checked = false;
    });

    // Reset the filter predicate to the original one
    this.dataSource.filterPredicate = this.customFilter();

    this.sort.active = '';
    this.sort.direction = '';

    // Fetch the data without any filters
    this.dataSource.data = this.initialAdminRequestData;
    if(this.initialAdminRequestData.length==0){this.isGridDataAvailable=false;}else{this.isGridDataAvailable=true;}
    this.dataSource.sort = this.sort;
    this.CalculateCostAndCount();
   // this.getAdminRequestData(this.criteria, 1, 50, "ID", true);
  }
  UpdateCheckBoxSelection(id:any,checked:boolean){
    var Index = this.dataSource.data.findIndex((x:any) => x.ID === id);
    if(checked){
      this.dataSource.data[Index].IsSelected=true;
    }
    else{
      this.dataSource.data[Index].IsSelected=false;
    }
  }
  resetCheckBoxSelection(){
    this.multiUpdateList.forEach((element:any,index:any) => {
      var Index = this.dataSource.data.findIndex((x:any) => x.ID === element);
      this.dataSource.data[Index].IsSelected=false;
    })
    this.multiUpdateList=[];
  }
  onCheckboxChecked(selectedRow:any,event:any){
    this.isBulkUpdateenable=true;
    if(event.checked){
      this.multiUpdateList.push(selectedRow?.ID);
    }else{
      const index = this.multiUpdateList.indexOf(selectedRow?.ID);
      if (index >= 0) {
        this.multiUpdateList.splice(index, 1);
      }
    }
    this.UpdateCheckBoxSelection(selectedRow?.ID,event.checked);
    if(this.multiUpdateList.length>0){
      this.isBulkUpdateenable=false;
    }
  }
  validateIndex(index:any){
    let isValidData=false;
    if(this.paginator.pageIndex==0){
      if(index<this.paginator.pageSize){
        isValidData=true;
      }
    }
    else{
      if(index>=this.paginator.pageSize*this.paginator.pageIndex&&index<this.paginator.pageSize*(this.paginator.pageIndex+1)){
        isValidData=true;
      }
    }
    return isValidData;
  }
  onSelectAllChecked(event:any){
    this.isBulkUpdateenable=true;
    this.dataSource.filteredData.forEach((element:any,index:any) => {
      if (this.validateIndex(index)) {
        if (event.checked) {
          this.ispageWiseSelectAllChecked = true;
          this.multiUpdateList.push(element?.ID);
        } else {
          this.ispageWiseSelectAllChecked = false;
          const index = this.multiUpdateList.indexOf(element?.ID);
          if (index >= 0) {
            this.multiUpdateList.splice(index, 1);
          }
        }
        this.UpdateCheckBoxSelection(element?.ID, event.checked);
      }
    });
    this.LoadMultiSelectCheckBoxData(event.checked);
    if(this.multiUpdateList.length>0){
      this.isBulkUpdateenable=false;
    }
  }
  LoadMultiSelectCheckBoxData(isChecked:boolean):any{
   let ispageIndexAlreadyExists=false;
    if(this.multiSelectCheckBoxValuesPerPage.length==0){  
      let dataObject={
        pageIndex:this.paginator.pageIndex,
        isPageLevelSelectAllChecked:isChecked
      }
      this.multiSelectCheckBoxValuesPerPage.push(dataObject);
    }
      else{
        this.multiSelectCheckBoxValuesPerPage.forEach((element:any) => {
          if(element.pageIndex==this.paginator.pageIndex){
            element.isPageLevelSelectAllChecked=isChecked;
            ispageIndexAlreadyExists=true;
          }
        });
        if(!ispageIndexAlreadyExists){
          let dataObject={
            pageIndex:this.paginator.pageIndex,
            isPageLevelSelectAllChecked:isChecked
          }
          this.multiSelectCheckBoxValuesPerPage.push(dataObject);
        }
      }
  }
  getMailMsgStyles():any{
    let color="grey";let marginRight="58%";
     if(this.mailStatusMessage.includes("Email processing in progress")){
      color="grey";
      marginRight="35%"
     }
     else if(this.mailStatusMessage==""){
        color="grey";
        marginRight="58%"
     }
     else if (this.mailStatusMessage == "Emails Failed to Send") {
       color = "red";
       marginRight = "40%"
     }
     else{
      marginRight="30%"
      color="green";
     }
       return {color:color,marginRight:marginRight};
  }
}
