import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';
import { ClientRecords } from './client-records.interface';
import { environment } from '../../environments/environment';
import { AppInsightsCore } from '@microsoft/applicationinsights-web';
import { AppInsightsService } from '../app-insights.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-client-modal',
  templateUrl: './client-modal.component.html',
  styleUrls: ['./client-modal.component.css']
})
export class ClientModalComponent implements OnInit {
  private searchSubscription!: Subscription;
  isLoading = false;
  clientRecords: ClientRecords[] = [];
  selectedRecord: ClientRecords = {
    ClientName: '',
    ClientNumber: '',
    IndustryDesc: '',
    ParentClientName: ''
  };

  searchTerm: string = '';

  constructor(private http: HttpClient, public dialogRef: MatDialogRef<ClientModalComponent>, public appInsightsService: AppInsightsService,
  ) { }

  ngOnInit(): void {
    this.getClientRecords();
  }

  getClientRecords() {
    this.http.get<ClientRecords[]>(environment.baseUrl + '/RequestList/GetClientRecords').subscribe((data: ClientRecords[]) => {
      this.clientRecords = data;
    }, error => {
      this.appInsightsService.logException(error);
      this.appInsightsService.logEvent('There was an error while fetching data!', error);
    });
  }

  searchClientRecords(clientelement: string) {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (clientelement.trim() === "") {
      this.getClientRecords();
      return;
    }
    this.isLoading = true;
    this.http.get<ClientRecords[]>(environment.baseUrl + `/RequestList/SearchClientRecords?clientelement=${clientelement}`).subscribe(
      data => {
        this.clientRecords = data;
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
        this.appInsightsService.logException(error);
        this.appInsightsService.logEvent('There was an error while fetching data!', error);
      }
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  selectRecordAndClose(): void {
    this.dialogRef.close(this.selectedRecord);
  }

  selectRecord(record: ClientRecords): void {
    this.selectedRecord = record;
  }

}
