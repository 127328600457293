import { Component, OnInit, ViewChild, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AddEditItemDialogComponent } from '../globalMFWBSCodes-form/globalMFWBSCodes-form.component';
import { globalMFWBSCodesDashboard } from './interface/globalMFWBSCodes-dashboard.model';
import { AppInsightsService } from '../app-insights.service';
import { GlobalMFWBSCodesService } from '../globalMFWBSCodes-form/globalMFWBSCodes.service';
import { AlertPopupComponent } from '../notify-popup/notify-popup.component';
import { Observable, of, from } from 'rxjs';
import { SpinnerServiceService } from '../services/spinner-service.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { UserService } from '../auth.details';
import { CommonHelperService } from '../services/common-helper.service';
import { WindowRefService } from '../services/window.service';

@Component({
  selector: 'app-globalMFWBSCodes-dashboard',
  templateUrl: './globalMFWBSCodes-dashboard.component.html',
  styleUrls: ['./globalMFWBSCodes-dashboard.component.css']
})
export class GlobalMfWbsCodesDashboardComponent implements OnInit, AfterViewInit {
  isAdmin!: boolean;
  searchText: string = '';
  firstAppliedFilterColumn:any=[];
  filterShownData:any=[];
  isAllFiltersNotRemoved:boolean=false;
  isLoading: boolean = false;
  currentPageIndex = 0;
  private lastId: number = 0;
  filters: { [key: string]: any[] } = {};
  selectedFilters: any = {};
  tempFilters: any = {};
  filterOptions: { [key: string]: string[] } = {};
  yearsList:any=[];
  selectedYear:any="";
  initialLoadData:any=[];
  displayedColumns: string[] = ['ID', 'Title', 'WBSCode'];
  dataSource = new MatTableDataSource<globalMFWBSCodesDashboard>();
  isGridDataAvailable:boolean=true;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChildren(MatCheckbox) checkboxes!: QueryList<MatCheckbox>;

  constructor(private http: HttpClient, private dialog: MatDialog, private appInsightsService: AppInsightsService, private globalMFWBSCodesService: GlobalMFWBSCodesService,
    private spinnerService:SpinnerServiceService,
    private userService: UserService,private commonHelperService:CommonHelperService,private windowService: WindowRefService) { }

  ngOnInit() {
    this.selectedYear=this.commonHelperService.defaultYearLoader();
    this.yearsList=this.commonHelperService.generateArrayOfYears();
    this.spinnerService.showSpinner(); // Show spinner when navigating to this page
    this.isAdmin = this.userService.isAdmin;
    this.appInsightsService.logPageView('GlobalMFWBSCodes');
    this.getGlobalMFWBSCodes(1, 50, "ID", false);
    if (this.windowService.nativeWindow._satellite) {
      //alert("got the satellite obj");           


      let analyticsDataLayer =
      {
        'pageInfo':
        {
          'pageName': "GlobalMF WBSCodes Dashboard", // Page Name
          'userID': this.userService.userEmail.split("@")[0], // User Alias, no domain
          'applicationName': "SAR" // Name of application
        }
      }
     
      this.windowService.nativeWindow.analyticsDataLayer = analyticsDataLayer;
      this.windowService.nativeWindow._satellite.track("pageView");
     
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = this.customFilter();
    if (this.paginator) {
      this.paginator.page.subscribe((pageEvent: PageEvent) => {
        this.currentPageIndex = pageEvent.pageIndex;
        //this.getGlobalMFWBSCodes(pageEvent.pageIndex + 1, pageEvent.pageSize, "ID", false);
        this.dataSource.data= this.initialLoadData;//this.commonHelperService.loadPageWiseData(pageEvent.pageIndex + 1, pageEvent.pageSize,this.initialLoadData);
        setTimeout(() => {
         this.paginator.pageIndex = pageEvent.pageIndex + 1 - 1;
         this.paginator.pageSize = pageEvent.pageSize;
         this.paginator.length = this.dataSource.data.length;
       }, 0);
      });
    }
  }
  onYearChange(){
    this.getGlobalMFWBSCodes(1, 50, "ID", false);
  }

  getfiltercolour(column: string) {
    const hasIdKey = Object.prototype.hasOwnProperty.call(this.tempFilters, column);
    return hasIdKey;
  }
  // Data source
  public getGlobalMFWBSCodes(pageNumber: number, pageSize: number, sortBy: string, sortDescending: boolean, id?: number) {
    this.isLoading = true;
    let params = new HttpParams();
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());
    params = params.append('sortBy', sortBy);
    params = params.append('sortDescending', sortDescending.toString());
   // params = params.append('searchText', this.selectedYear.toString());

    if (id !== undefined) {
      params = params.append('id', id.toString());
    }

    this.http.get<{ Data: any[], Total: number }>(environment.baseUrl + '/GlobalMFWBSCodes/GetGlobalMFWBSCodes', { params: params }).subscribe(
      response => {
        if(response.Data.length==0){this.isGridDataAvailable=false;}else{this.isGridDataAvailable=true;}
        if (response && response.Data) {
          this.dataSource.data = response.Data;
          this.initialLoadData=response.Data;
          let defaultData={
            key:"",
            value:response.Data
          }
          this.filterShownData.push(defaultData);
          setTimeout(() => {
            this.paginator.pageIndex = pageNumber - 1;
            this.paginator.pageSize = pageSize;
            this.paginator.length = response.Total;
          }, 0);
        } else {
          console.error('Unexpected response format:', response);
        }
        this.isLoading = false;
        this.spinnerService.hideSpinner(); // Hide spinner after data is loaded
      },
      error => {
        this.isLoading = false;
        console.error('There was an error while fetching data!', error);
        this.isLoading = false;
        this.spinnerService.hideSpinner(); // Hide spinner if there is an error
      }
    );
  }

 public exportToExcel()
 {
    this.isLoading = true;
    this.spinnerService.showSpinner();// show spinner
    let params = new HttpParams();

    this.http.get<Array<any>>(environment.baseUrl + '/GlobalMFWBSCodes/GetExportList', { params: params }).subscribe(
      response => {

        if(response!=null && response!=undefined && response.length > 0)
        {
          this.isLoading = false;
          this.spinnerService.hideSpinner();// hide spinner
  
          var jsonData:Array<any> = [];
          jsonData = response.map(doc => Object.values(doc));
          const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
          
          worksheet['A1'].v = "ID";
          worksheet['B1'].v = "Title";
          worksheet['C1'].v = "WBSCode";
          
          const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
          const data: Blob = new Blob([excelBuffer], { type: "xlsx" });
          FileSaver.saveAs(data, 'GlobalMF_WBSCodes_List_' + new Date().getTime() + ".xlsx");
        }
        else
        {
          //put alert message here.
          this.isLoading = false;
        }       
      },
      error => {
        this.isLoading = false;
        this.appInsightsService.logException(error);
        this.appInsightsService.logEvent('There was an error while exporting excel data!', error);
      }
    );
  }

  applyGlobalFilter(filterValue: string) {
    // Show the spinner
    this.spinnerService.showSpinner();
    // delay to ensure spinner is visible
    setTimeout(() => {
    this.searchText = filterValue.trim().toLowerCase();
      this.applyFilters();
      // Hide the spinner after data is loaded
      this.spinnerService.hideSpinner();
    }, 500); //delay
  }

  resetFilters() {
    this.firstAppliedFilterColumn=[];
    this.filterShownData=[];
    this.searchText = '';
    this.filters = {};
    this.selectedFilters = {};
    this.tempFilters = {};
    this.applyFilters();
    this.clearCheckboxes("");
  }

  getUniqueColumnValues(column: keyof globalMFWBSCodesDashboard): string[] {
    const uniqueValues:any = new Set<string>();
    let filteredData:any;
    filteredData=this.dataSource.filteredData;
 if(this.firstAppliedFilterColumn.length>0){
   this.filterShownData.forEach((element: any) => {
     if (element.key == column) {
       filteredData = element.value;
     }
   })
    }
    filteredData.forEach((row:any) => {
      if (row[column] !== undefined) {
        uniqueValues.add(this.commonHelperService.ValidateFilterData(row[column]) as unknown as string);
      }
    });
    return Array.from(uniqueValues);
  }

  applyFilter(event: any, filterValue: string, column: keyof globalMFWBSCodesDashboard) {
    const filterValueStr = filterValue==null||filterValue==undefined||filterValue=="null"||filterValue=="NULL"?"":String(filterValue).toLowerCase();
    this.isAllFiltersNotRemoved=false;
    let columnWiseFilterOptions:any={};
    let filteredValueAlreadyExists=false;
    if(this.firstAppliedFilterColumn.length==0){
      this.firstAppliedFilterColumn.push(column);
      columnWiseFilterOptions = {
        key: column,
        value: this.dataSource.filteredData
      }
      this.filterShownData.push(columnWiseFilterOptions);
    }
      else{
        this.firstAppliedFilterColumn.forEach((element:any) => {
          if(element==column){
            filteredValueAlreadyExists=true;
          }
        });
        if(!filteredValueAlreadyExists){this.firstAppliedFilterColumn.push(column);
          columnWiseFilterOptions={ 
            key:column,
            value:this.dataSource.filteredData
           }
              this.filterShownData.push(columnWiseFilterOptions);
        }
      }
    if (event.checked) {
      this.commonHelperService.LoadFilterAppliedFields(event?.source?.id,column);
      this.isAllFiltersNotRemoved=true;
      if (!this.tempFilters[column]) {
        this.tempFilters[column] = [];
      }
      this.tempFilters[column].push(filterValueStr);
    } else {
      this.commonHelperService.RemoveFilterAppliedFields(event?.source?.id,column);
      this.tempFilters[column] = this.tempFilters[column].filter((value: string) => value !== filterValueStr);
      if (this.tempFilters[column].length === 0) {
        delete this.tempFilters[column];
      }
      this.ValidateIsFilterExists();
    }
    if(this.isAllFiltersNotRemoved){this.applyFilters();}else{this.clearFilter("" as any);}
  }
  ValidateIsFilterExists(){
    this.displayedColumns.forEach((column:any)=>{
      if(this.tempFilters[column]?.length>0){this.isAllFiltersNotRemoved=true;}
      if(this.tempFilters[column]?.length==0){delete this.tempFilters[column];}
    })
  }
  applyFilters() {
    this.selectedFilters = JSON.parse(JSON.stringify(this.tempFilters));
    this.dataSource.filter = JSON.stringify({ searchText: this.searchText, filters: this.selectedFilters });
    if(this.dataSource.filteredData.length==0){this.isGridDataAvailable=false;}else{this.isGridDataAvailable=true;}
    this.displayedColumns.forEach(column => {
      const uniqueValues = this.getUniqueColumnValues(column as keyof globalMFWBSCodesDashboard);
      this.updateFilterOptions(column, uniqueValues);
    });
  }

  updateFilterOptions(column: string, uniqueValues: string[]) {
    this.filterOptions[column] = uniqueValues;
  }


  clearFilter(column: keyof globalMFWBSCodesDashboard) {
    this.isAllFiltersNotRemoved=false;
    delete this.selectedFilters[column];
    delete this.tempFilters[column];
    this.ValidateIsFilterExists();
    if(this.isAllFiltersNotRemoved||this.searchText!=""){                       
    this.applyFilters();
    }
    else{
      this.firstAppliedFilterColumn=[];
      this.filterShownData=[];
      this.dataSource.filter="";
      this.getGlobalMFWBSCodes(1, 50, "ID", false);
    }
    this.clearCheckboxes(column);
  }

  clearCheckboxes(column:any) {
    this.checkboxes.forEach((checkbox: MatCheckbox) => {
      this.commonHelperService.filterAppliedFields.forEach((element:any) => {
        if(element.value==column&&checkbox.id==element.key||column==""){
          checkbox.checked = false;
        }
      });
    });
  }
  openAlertDialog(title: string, message: string, isGlobalMFWBSCodesPage: boolean,msglength:string="2"): Observable<boolean> {
    const dialogRef = this.dialog.open(AlertPopupComponent, {
      data: { title, message, isGlobalMFWBSCodesPage },
      width: '40%' ,
      height: msglength == "2" ? '30%' : '48%',
      disableClose: true
    });

    return new Observable<boolean>(observer => {
      dialogRef.afterClosed().subscribe(result => {
        observer.next(result); // Emit the result from the dialog
        observer.complete();
      });
    });
  }

  // Delete item
  deleteItem(element: any) {
    const isGlobalMFWBSCodesPage = true;
    this.openAlertDialog('Warning', 'Are you sure you want to Delete the item?', isGlobalMFWBSCodesPage)
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.http.delete(environment.baseUrl + `/GlobalMFWBSCodes/DeleteGlobalMFWBSCodes/${element.ID}`).subscribe(
            () => {
              const index = this.dataSource.data.indexOf(element);
              if (index >= 0) {
                this.dataSource.data.splice(index, 1);
                this.dataSource._updateChangeSubscription(); // Refresh the data source
              }
              // Show success popup
              this.openAlertDialog('Success', 'GlobalMF WBSCodes deleted successfully', false);
            },
            error => {
              console.error('There was an error while deleting the item!', error);
            }
          );
        }
      });
  }


  //add item
  addItem(): void {
    const dialogRef = this.dialog.open(AddEditItemDialogComponent, {
      width: '400px',
      data: { isEdit: false }, // Passing data to the dialog, isEdit is false for adding new item
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Fetch the entire list of items after adding a new item
        this.http.get<{ Data: globalMFWBSCodesDashboard[], Total: number }>(`${environment.baseUrl}/GlobalMFWBSCodes/GetGlobalMFWBSCodes`).subscribe(
          response => {
            if (response && response.Data) {
              this.dataSource.data = response.Data;
              this.dataSource._updateChangeSubscription(); // Refresh the data source

              if (this.paginator) {
                this.paginator.firstPage();
              }
            } else {
              console.error('Unexpected response format:', response);
            }
          },
          error => {
            console.error('There was an error while fetching the data!', error);
          }
        );
      }
    });
  }

  // Edit item
  editItem(element: any): void {
    const dialogRef = this.dialog.open(AddEditItemDialogComponent, {
      width: '400px',
      data: {
        item: element,
        isEdit: true
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const updatedItem: globalMFWBSCodesDashboard = {
          ID: result.ID,
          Title: result.Title,
          WBSCode: result.WBSCode
        };

        // Update item in the data source
        const index = this.dataSource.data.findIndex(item => item.ID === updatedItem.ID);
        if (index !== -1) {
          this.dataSource.data[index] = updatedItem;
          this.dataSource._updateChangeSubscription(); // Refresh the data source
        }
      }
    });
  }

  customFilter(): (data: any, filter: string) => boolean {
    return (data: any, filter: string): boolean => {
      const { searchText, filters } = JSON.parse(filter);
      const globalMatch = !searchText || Object.values(data).some(value =>
        String(value).toLowerCase().includes(searchText)
      );

      const columnMatch = Object.keys(filters).every(column => {
        return filters[column].includes(String(data[column]).toLowerCase());
      });

      return globalMatch && columnMatch;
    };
  }
}
