import { Injectable } from '@angular/core';

@Injectable()
export class WindowRefService {
  get nativeWindow() {
    return getWindow();
  }
}

function getWindow(): any {
  return window;
}
