<div mat-dialog-content>
    <form [formGroup]="form">
      <table>
        <tr *ngIf="isAdmin">
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="idNumber" class="ms-h3 ms-standardheader label required">ID Number </label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" id="idNumber" formControlName="idNumber" class="wide-input">
            <div *ngIf="form.controls['idNumber'].invalid && form.controls['idNumber'].touched" class="error">
              You can't leave this blank.
            </div>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="businessContact" class="ms-h3 ms-standardheader label required">Business Contact </label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" id="businessContact" formControlName="businessContact" placeholder="Enter a name or email address..." class="wide-input" [matAutocomplete]="auto1" [readOnly]="autoCompleteUserSelected.businessContactUserSelected">
            <span *ngIf="autoCompleteUserSelected.businessContactUserSelected" class="clearBtn" (click)="removeSelectedUser('businessContact')" [ngStyle]="getCorssMarkStyles('businessContact')">X</span>
            <mat-autocomplete #auto1="matAutocomplete" (optionSelected)="onOptionSelected($event, 'businessContact')">
              <mat-option *ngFor="let user of filteredUsersBussinessContact | async" [value]="user">
                <div>{{user.displayName}}</div>
                <small>{{user.jobTitle}}</small><br />
                <small>{{user.mail}}</small>
              </mat-option>
              <mat-option *ngIf="showNoResults">
                <div>{{"No Results Found.."}}</div>
              </mat-option>
            </mat-autocomplete>
            <div *ngIf="form.controls['businessContact'].invalid && form.controls['businessContact'].touched" class="error">
              You can't leave this blank.
            </div>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="needByDate" class="ms-h3 ms-standardheader label required">Need by Date </label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="date" id="needByDate" formControlName="needByDate" class="form-control" #needByDate>
            <div *ngIf="form.controls['needByDate'].invalid && form.controls['needByDate'].touched" class="error">
              You can't leave this blank.
            </div>
            <span class="error" *ngIf="isInvalidDate(needByDate,'needByDate')"> Date is Invalid</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="requestorsName" class="ms-h3 ms-standardheader label required">Requestor's Name </label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" id="requestorsName" formControlName="requestorsName" placeholder="Enter a name or email address..." class="wide-input" [matAutocomplete]="auto2" [readOnly]="autoCompleteUserSelected.requestorsUserSelected">
            <span *ngIf="autoCompleteUserSelected.requestorsUserSelected" class="clearBtn" (click)="removeSelectedUser('requestorsName')" [ngStyle]="getCorssMarkStyles('requestorsName')">X</span>
            <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="onOptionSelected($event, 'requestorsName')">
              <mat-option *ngFor="let option of filteredUsersRequestors | async" [value]="option">
                <div>{{option.displayName}}</div>
                <small>{{option.jobTitle}}</small><br />
                <small>{{option.mail}}</small>
              </mat-option>
              <mat-option *ngIf="showNoResults">
                <div>{{"No Results Found.."}}</div>
              </mat-option>
            </mat-autocomplete>
            <div *ngIf="form.controls['requestorsName'].invalid && form.controls['requestorsName'].touched" class="error">
              You can't leave this blank.
            </div>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="typeOfAudit" class="ms-h3 ms-standardheader label required">Type Of Audit </label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <select formControlName="typeOfAudit">
              <option value="Financial Audit" selected="selected">Financial Audit</option>
              <option value="Employee Benefit Plan (EBP) Audit">Employee Benefit Plan (EBP) Audit</option>
              <option value="Investment Audit">Investment Audit</option>
              <option value="SOX Audit">SOX Audit</option>
              <option value="Integrated Audit">Integrated Audit</option>
              <option value="Other">Other</option>
            </select>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="title" class="ms-h3 ms-standardheader label required">Title </label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" id="title" formControlName="title" class="wide-input">
            <div *ngIf="form.controls['title'].invalid && form.controls['title'].touched" class="error">
              You can't leave this blank.
            </div>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="requestDate" class="ms-h3 ms-standardheader label required">Request Date </label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="date" id="requestDate" formControlName="requestDate" class="form-control" #reqDate>
            <div *ngIf="form.controls['requestDate'].invalid && form.controls['requestDate'].touched" class="error">
              You can't leave this blank.
            </div>
            <span class="error" *ngIf="isInvalidDate(reqDate,'requestDate')"> Date is Invalid</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="sarReportType" class="ms-h3 ms-standardheader label">SAR Report Type.</label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <select formControlName="sarReportType">
              <option value="Type I" selected="selected">Type I</option>
              <option value="Type II">Type II</option>
            </select>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="WBS" class="ms-h3 ms-standardheader label required">WBS# </label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <div style="display: flex; align-items: center;">
              <input type="text" id="WBS" formControlName="WBS" class="wide-input" style="margin-right: 10px;" (blur)="onMouseLeave()">
              <img title="Check if External Item exists" src="../../assets/img/EntityInstanceResolver.png" alt="Check if External Item exists" style="margin-right: 5px;" (click)="searchWBSRecords()">
              <img title="Select External Item(s)" src="../../assets/img/EntityInstancePicker.png" alt="Select External Item(s)" (click)="openDialog()">
            </div>
            <div *ngIf="form.controls['WBS'].invalid && form.controls['WBS'].touched && !checkClickedWithoutValue && !validateWBSCode" class="error">
              You can't leave this blank (or) The WBS Code you entered is invalid.
            </div>
            <div *ngIf="validateWBSCode && !checkClickedWithoutValue" class="error">
              No exact match was found. Click the item(s) that did not resolve for more options. You can also use Select button to choose External Data
            </div>
            <div *ngIf="checkClickedWithoutValue" class="error">
              You must specify a value before using the Check button. You can also use Select button to choose External Data.
            </div>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label required" for="AuditSigningPartner">Audit Signing Partner</label></td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" class="form-control wide-input" formControlName="AuditSigningPartner" required placeholder="Enter a name or email address..." [matAutocomplete]="auto3" [readOnly]="autoCompleteUserSelected.auditSigningUserSelected">
            <span *ngIf="autoCompleteUserSelected.auditSigningUserSelected" class="clearBtn" (click)="removeSelectedUser('AuditSigningPartner')" [ngStyle]="getCorssMarkStyles('AuditSigningPartner')">X</span>
            <mat-autocomplete #auto3="matAutocomplete" (optionSelected)="onOptionSelected($event, 'AuditSigningPartner')">
              <mat-option *ngFor="let user of filteredAuditSigningPartner | async" [value]="user">
                <div>{{user.displayName}}</div>
                <small>{{user.jobTitle}}</small><br />
                <small>{{user.mail}}</small>
              </mat-option>
              <mat-option *ngIf="showNoResults">
                <div>{{"No Results Found.."}}</div>
              </mat-option>
            </mat-autocomplete>
            <div *ngIf="form.controls['AuditSigningPartner'].invalid && form.controls['AuditSigningPartner'].touched" class="error">
              You can't leave this blank.
            </div>
          </td>
        </tr>
        <tr *ngIf="isGlobalwbscode">
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="mfEngagementName" class="ms-h3 ms-standardheader label required">MF Engagement Name</label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" id="mfEngagementName" formControlName="mfEngagementName" class="wide-input">
            <div *ngIf="mfengagementname" class="error">
              Value Shouldnot be empty
            </div>
          </td>
        </tr>
        <tr *ngIf="isGlobalwbscode">
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="mfPartnerName" class="ms-h3 ms-standardheader label required">MF Partner Name</label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" id="mfPartnerName" formControlName="mfPartnerName" class="wide-input">
            <div *ngIf="mfengagementpartnername" class="error">
              Value Shouldnot be empty
            </div>
          </td>
        </tr>
        <tr *ngIf="isGlobalwbscode">
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="mfWBSCode" class="ms-h3 ms-standardheader label required">MF WBS Code</label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" id="mfWBSCode" formControlName="mfWBSCode" class="wide-input">
            <div *ngIf="mfwbscode" class="error">
              Value Shouldnot be empty
            </div>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <div style="display: flex; justify-content: center; align-items: flex-start;">
              <div mat-dialog-actions>
                <button mat-button (click)="saveChanges()" class="ms-button" style="color: #FFFFFF;">Save</button>
                <button mat-button mat-dialog-close (click)="onCancelClick()" class="ms-button" style="color: #FFFFFF;">Cancel</button>
              </div>
            </div>
          </td>
        </tr>
      </table>
   </form>
</div>
