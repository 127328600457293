<div class="well">
  <div class="container-fluid">
    <div class="jumbotron">
      <div style="text-align:center;">
        <h2 style="line-height: 2.0;">
          The SAR application is currently undergoing an upgrade and is temporarily unavailable. The new portal will be available for use by <strong>11/05/2024 6:30 AM CST.</strong>
          <br> For any questions or further assistance, please write to us at &nbsp;
          <a href="mailto:HYDSAS-70RA@deloitte.com" title="Advisory Monitoring Team">{{"HYDSAS-70RA@deloitte.com"}}</a>
        </h2>
      </div>
    </div>
  </div>
</div>
