import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalMFWBSCodesService } from './globalMFWBSCodes.service';
import { SpinnerServiceService } from '../services/spinner-service.service';

@Component({
  selector: 'app-globalMFWBSCodes-form',
  templateUrl: './globalMFWBSCodes-form.component.html',
})
export class AddEditItemDialogComponent {
  formGroup: FormGroup;
  isEditMode: boolean;
  isSubmit: boolean = false;

  constructor(
    private fb: FormBuilder,
    private globalMFWBSCodesService: GlobalMFWBSCodesService,
    private spinnerService: SpinnerServiceService,
    public dialogRef: MatDialogRef<AddEditItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isEditMode = data.isEdit;
    this.formGroup = this.fb.group({
      title: [data.item?.Title || '', Validators.required],
      wbsCode: [data.item?.WBSCode || '', Validators.required],
    });
  }

  onSave(): void {
    if (this.formGroup.valid && !this.isSubmit) {
      this.isSubmit = true;
      this.spinnerService.showSpinner(); // Show the spinner
      const result = {
        ID: this.data.item?.ID || null, // include ID if available, otherwise null
        Title: this.formGroup.value.title,
        WBSCode: this.formGroup.value.wbsCode
      };

      if (this.isEditMode) {
        this.updateGlobalMFWBSCodes(result, this.data.item.ID);
      } else {
        this.addGlobalMFWBSCodes(result);
      }
    }
  }

  private updateGlobalMFWBSCodes(result: any, id: number): void {
    this.globalMFWBSCodesService.updateGlobalMFWBSCodes(result, id).subscribe({
      next: response => {
        this.isSubmit = false;
        this.spinnerService.hideSpinner(); // Hide the spinner
        this.dialogRef.close(result); // Close the dialog and pass the result
      },
      error: error => {
        console.error('There was an error while updating the item!', error);
        this.isSubmit = false;
        this.spinnerService.hideSpinner(); // Hide the spinner
      }
    });
  }

  private addGlobalMFWBSCodes(result: any): void {
    this.globalMFWBSCodesService.addGlobalMFWBSCodes(result).subscribe({
      next: response => {
        this.isSubmit = false;
        this.spinnerService.hideSpinner(); // Hide the spinner
        this.dialogRef.close(result); // Close the dialog and pass the result
      },
      error: error => {
        console.error('There was an error while adding the item!', error);
        this.isSubmit = false;
        this.spinnerService.hideSpinner(); // Hide the spinner
      }
    });
  }

  onCancel(): void {
    if (!this.isSubmit) {
      this.dialogRef.close();
    }
  }
}
