import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { globalMFWBSCodesDashboard } from '../globalMFWBSCodes-dashboard/interface/globalMFWBSCodes-dashboard.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalMFWBSCodesService {
  constructor(private http: HttpClient) { }

  addGlobalMFWBSCodes(item: Partial<globalMFWBSCodesDashboard>): Observable<globalMFWBSCodesDashboard> {
    return this.http.post<globalMFWBSCodesDashboard>(`${environment.baseUrl}/GlobalMFWBSCodes/AddGlobalMFWBSCodes`, item);
  }

  updateGlobalMFWBSCodes(item: Partial<globalMFWBSCodesDashboard>, id: number): Observable<globalMFWBSCodesDashboard> {
    return this.http.post<globalMFWBSCodesDashboard>(`${environment.baseUrl}/GlobalMFWBSCodes/UpdateGlobalMFWBSCodes`, item);
  }
}
