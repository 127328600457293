<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css">
<a class="pageTitle" title="GlobalMF WBSCodes">GlobalMF WBSCodes</a>
<br/><br/>
<!-- <div style="margin-left:-1%;">
  <label for="Request Creation Year">Year Of Request Creation </label>&nbsp;
  <select style="height: 25px;"  name="yearOfRequest" aria-placeholder="Select Year" [(ngModel)]="selectedYear" (change)="onYearChange()" id="yearOfRequest" class="form-control">
    <option value="" selected disabled>Select Year</option>
    <option *ngFor="let option of yearsList" [value]="option">
      {{option}}
    </option>
  </select>
</div> -->
<div class="toolbar">
  <div style="display: flex;text-align: left;color: #337AB7;">
    <button class="primary icon-button blue-button" type="button" (click)="addItem(); $event.preventDefault()">
      Add New WBS
    </button></div>
  <div class="search-bar">
    <input type="text" [(ngModel)]="searchText" #filter>
    <button class="primary" type="button" (click)="applyGlobalFilter(filter.value)"><mat-icon>search</mat-icon></button>
    <button class="primary" type="button" (click)="resetFilters()"><mat-icon>clear all</mat-icon></button>
    <button *ngIf="isAdmin" class="primary" type="button" (click)="exportToExcel()"><mat-icon>save_alt</mat-icon></button>
  </div>
</div>

<br>
<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>
    <thead>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    </thead>
    <tbody>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </tbody>

    <ng-container matColumnDef="ID">
      <th mat-header-cell *matHeaderCellDef>
        <div style="display: flex; align-items: center;">
          <span mat-sort-header>ID</span>
          <button mat-icon-button [matMenuTriggerFor]="menuID">
            <!-- <mat-icon>filter_list</mat-icon> -->
            <i [ngStyle]="{'color':getfiltercolour('ID')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
          </button>
        </div>

        <mat-menu #menuID="matMenu" class="customize">
          <div class="filter-options">
            <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
            <span (click)="clearFilter('ID'); $event.stopPropagation();" class="filter-icon-style">
              Clear All Filters
            </span>
            <button mat-menu-item *ngFor="let option of getUniqueColumnValues('ID')">
              <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'ID')">
                {{ option }}
              </mat-checkbox>
            </button>
          </div>
          <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
            <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
              Apply
            </button>
            <button mat-menu-item (click)="clearFilter('ID'); $event.stopPropagation();" class="filter-button">
              Clear
            </button>
          </div> -->
        </mat-menu>
      </th>
      <td mat-cell *matCellDef="let element" class="wrap-text">
        <div class="id-container">
          <span>{{element.ID}}</span>
          <button mat-icon-button [matMenuTriggerFor]="menu" class="more-options-button">
            <mat-icon style="font-size: 16px;">more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="editItem(element)">
              <span class="edit-menu">Edit Item</span>
            </button>
            <button mat-menu-item (click)="deleteItem(element)">
              <span class="edit-menu">Delete Item</span>
            </button>
          </mat-menu>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Title" style="margin-left: -20%;">
      <th mat-header-cell *matHeaderCellDef>
        <div style="display: flex; align-items: center;">
          <span mat-sort-header >Title</span>
          <button mat-icon-button [matMenuTriggerFor]="menuTitle">
            <!-- <mat-icon>filter_list</mat-icon> -->
            <i [ngStyle]="{'color':getfiltercolour('Title')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
          </button>
        </div>

        <mat-menu #menuTitle="matMenu" class="customize">
          <div class="filter-options">
            <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
            <span (click)="clearFilter('Title'); $event.stopPropagation();" class="filter-icon-style">
              Clear All Filters
            </span>
            <button mat-menu-item *ngFor="let option of getUniqueColumnValues('Title')">
              <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'Title')">
                {{ option|replaceNullWithText }}
              </mat-checkbox>
            </button>
          </div>
          <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
            <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
              Apply
            </button>
            <button mat-menu-item (click)="clearFilter('Title'); $event.stopPropagation();" class="filter-button">
              Clear
            </button>
          </div> -->
        </mat-menu>
      </th>
      <td mat-cell *matCellDef="let element" class="wrap-text"> {{element.Title}} </td>
    </ng-container>

    <ng-container matColumnDef="WBSCode">
      <th mat-header-cell *matHeaderCellDef>
        <div style="display: flex; align-items: center;">
          <span mat-sort-header>WBSCode</span>
          <button mat-icon-button [matMenuTriggerFor]="menuWBSCodes">
            <!-- <mat-icon>filter_list</mat-icon> -->
            <i [ngStyle]="{'color':getfiltercolour('WBSCode')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
          </button>
        </div>

        <mat-menu #menuWBSCodes="matMenu" class="customize">
          <div class="filter-options">
            <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
            <span (click)="clearFilter('WBSCode'); $event.stopPropagation();" class="filter-icon-style">
              Clear All Filters
            </span>
            <button mat-menu-item *ngFor="let option of getUniqueColumnValues('WBSCode')">
              <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'WBSCode')">
                {{ option|replaceNullWithText }}
              </mat-checkbox>
            </button>
          </div>
          <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
            <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
              Apply
            </button>
            <button mat-menu-item (click)="clearFilter('WBSCode'); $event.stopPropagation();" class="filter-button">
              Clear
            </button>
          </div> -->
        </mat-menu>
      </th>
      <td mat-cell *matCellDef="let element" class="wrap-text"> {{element.WBSCode}} </td>
    </ng-container>
  </table>
</div>
<br>
<span class="noValues" *ngIf="!isGridDataAvailable">There are no items to show in this view</span>
<mat-paginator [length]="dataSource.data.length" [pageSize]="50" showFirstLastButtons></mat-paginator>
<div *ngIf="isLoading" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0); display: flex; justify-content: center; align-items: center;">
  <!-- <mat-spinner [diameter]="40"></mat-spinner> -->
</div>
