<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css">
<a class="pageTitle" title="Request Review List">Request Review List</a>
<br><br>
    <div style="display: flex; justify-content: flex-end;">
      <!--<label for="Request Creation Year">Year Of Request Creation </label>&nbsp;
  <select style="height: 25px;"  name="yearOfRequest" aria-placeholder="Select Year" [(ngModel)]="selectedYear" (change)="onYearChange()" id="yearOfRequest" class="form-control">
    <option value="" selected disabled>Select Year</option>
    <option *ngFor="let option of yearsList" [value]="option">
      {{option}}
    </option>
  </select>-->
      <button class="primary icon-button blue-button" type="button" (click)="openDialog(); $event.preventDefault()">
    Add New Review
  </button>
    </div>
<br>
<div class="search-bar">
  <!-- <div style="display: flex;text-align: left;color: #337AB7;"><mat-icon>add</mat-icon><a style="color:  #337AB7;;" href="#" (click)="openDialog(); $event.preventDefault()">Add new item</a></div> -->
  <div style="display: flex;text-align: left;">
    <!--<button class="primary icon-button blue-button" type="button" (click)="openDialog(); $event.preventDefault()">
    Add New Review
  </button>-->
    <label for="Request Creation Year">Year Of Request Creation: </label>&nbsp;
    <select style="height: 25px;" name="yearOfRequest" aria-placeholder="Select Year" [(ngModel)]="selectedYear" (change)="onYearChange()" id="yearOfRequest" class="form-control">
      <option value="" selected disabled>Select Year</option>
      <option *ngFor="let option of yearsList" [value]="option">
        {{option}}
      </option>
    </select>
  </div>
  <div class="left-section">
    <input type="text" class="form-control" [(ngModel)]="searchText" #filter style="height: 22px;">
    <button class="primary icon-button" type="button" (click)="applyGlobalFilter(filter.value)" style="height: 30px;margin-left: -15px;">
      <mat-icon>search</mat-icon>
    </button>
    <button class="primary icon-button" type="button" (click)="resetFilters()" style="height: 30px;margin-left: -2%;">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="right-section">
    <label>From:</label>
    <input type="date" class="form-control" [(ngModel)]="fromDateValue" (change)="onDateChange($event)" min="1990-01-01"  max="2050-12-31" #fromDate>
    <label>To:</label>
    <input type="date" class="form-control" [(ngModel)]="toDateValue" (change)="onDateChange($event)" min="1990-01-01" max="2050-12-31" #toDate>
    <button *ngIf="isAdmin" [disabled]="!enableButton" class="primary icon-button blue-button" type="button" (click)="exportToExcel()">
      <!--<mat-icon>save_alt</mat-icon>-->
      Generate Report
    </button>
  </div>
</div>

<div *ngIf="inValidDaterange" class="search-bar" style="margin-left:62%">
  <span style="color:red;text-align:center">ToDate should be greater than FromDate.</span>
</div>
<div *ngIf="invalidDate" class="search-bar" style="margin-left:62%">
  <span style="color:red;text-align:center">Please select appropriate dates.</span>
</div>
<div *ngIf="ValidateDates(fromDate,toDate)" class="search-bar" style="margin-left:62%">
<span style="color:red;text-align:center"> Date is Invalid</span></div>
<div *ngIf="shownorecords" class="search-bar" style="margin-left:62%">
  <span style="color:red;text-align:center">There are no records for the selected date range.Please select valid date range.</span>
</div>
<br />
<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container *ngFor="let col of columns">
      <ng-container *ngIf="isAdmin && col.columnDef === 'ID';" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>ID</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter('ID'); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues('ID')">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'ID')">
                  {{ option }}
                </mat-checkbox>
              </button>
            </div>
            <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
            <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                Apply
            </button>
            <button mat-menu-item (click)="clearFilter('ID'); $event.stopPropagation();" class="filter-button">
                Clear
            </button>
        </div> -->
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" style="max-width: 200px; overflow: hidden; text-overflow: ellipsis; align-items: center;">
          <div style="max-width: 200px; overflow: hidden; text-overflow: ellipsis; align-items: center;display: flex;">
            <span matTooltip="{{element.ID}}" style="flex-grow: 1; display: block;" class="wrap-text">
              {{element.ID}}
            </span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon style="font-size: 16px;">more_horiz</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="editItem(element)">
                <span class="edit-menu">Edit Item</span>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'Created'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "Created" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText| date:'MMMM dd, yyyy' }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText| date:'MMMM dd, yyyy'}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'RequestorsNameLotusNotes'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "Requestor's Name (Lotus Note)" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option |replaceNullWithText}}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'WBS'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "WBS#" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'VerifyServiceOrganization'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef style="min-width:400px">
          <div style="display: flex; align-items: center; white-space: normal;">
            <span mat-sort-header>{{ "Verify you have validated if the Service Organization is a Deloitte Client" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option |replaceNullWithText}}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell style="text-align:center" *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'Cost'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ col.header }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText|currency:'USD' }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText |currency:'USD'}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'ServiceOrganizationDeloitteClient'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "Service Organization - Deloitte Client" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'AllottedToOld'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "Allotted To (Old)" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'DateTimeOfLastAction'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "Date/Time Of Last Action" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'ExclusiveToClient'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "Exclusive To Client?" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'Existing'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "Existing?" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText|booleantostring }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText|booleantostring}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'LocationsCoveredInReport'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "Location(s) Covered In Report" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'NumberOfReviewComments'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "No. of Review Comments" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText}} </td>
      </ng-container>

      <ng-container *ngIf="col.columnDef == 'RequestorsName'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "Requestor's Name" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'NeedByDate'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "Need by Date" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText|date:'MM/dd/yyyy' }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText|date:'MM/dd/yyyy'}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'NumberOfRequestsReceivedForPriorPeriodReview'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef style="min-width:400px">
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "#of rqsts received for prior period review" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'Publish'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "Publish?" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText|booleantostring }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText|booleantostring}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'PreviousPeriodReviewAvailable'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "Previous Period Review Available" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'WBSClientName'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "WBS#: ClientName" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'ReportPeriodEndDate'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "Report Period End Date" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText|date:'MM/dd/yyyy' }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText|date:'MM/dd/yyyy'}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'ReportPeriodStartDate'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "Report Period Start Date" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText|date:'MM/dd/yyyy' }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText|date:'MM/dd/yyyy'}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'RequestDate'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "Request Date" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText|date:'MM/dd/yyyy' }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText|date:'MM/dd/yyyy'}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'SRCD'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "SRCD" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText|date:'MM/dd/yyyy' }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText|date:'MM/dd/yyyy'}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'FRCD'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "FRCD" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText|date:'MM/dd/yyyy' }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText|date:'MM/dd/yyyy'}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef == 'FRRD'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ "FRRD" }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText|date:'MM/dd/yyyy' }}
                </mat-checkbox>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText|date:'MM/dd/yyyy'}} </td>
      </ng-container>
      <ng-container *ngIf="col.columnDef !== 'ID' && col.columnDef !== 'Cost' && col.columnDef!=='RequestorsNameLotusNotes' && col.columnDef!=='WBS' && col.columnDef !=='VerifyServiceOrganization' && col.columnDef !=='ServiceOrganizationDeloitteClient' && col.columnDef !=='AllottedToOld' && col.columnDef !=='DateTimeOfLastAction' && col.columnDef!=='ExclusiveToClient' && col.columnDef!=='Existing' && col.columnDef !=='LocationsCoveredInReport' && col.columnDef !=='NumberOfReviewComments' && col.columnDef !=='RequestorsName' && col.columnDef !=='NeedByDate' && col.columnDef !=='NumberOfRequestsReceivedForPriorPeriodReview' && col.columnDef !=='Created' && col.columnDef !=='Publish' && col.columnDef !=='PreviousPeriodReviewAvailable' && col.columnDef !='WBSClientName' && col.columnDef !='ReportPeriodEndDate' && col.columnDef !='RequestDate' && col.columnDef !='ReportPeriodStartDate' && col.columnDef !='SRCD' && col.columnDef !='FRCD'  && col.columnDef !='FRRD'" [matColumnDef]="col.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <div style="display: flex; align-items: center;">
            <span mat-sort-header>{{ col.header }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <!-- <mat-icon>filter_list</mat-icon> -->
              <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="customize">
            <div class="filter-options">
              <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
              <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                Clear All Filters
              </span>
              <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                  {{ option|replaceNullWithText }}
                </mat-checkbox>
              </button>
            </div>
            <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
            <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                Apply
            </button>
            <button mat-menu-item (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-button">
                Clear
            </button>
        </div> -->
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) |replaceNullWithText }} </td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<br>
<span class="noValues" *ngIf="!isGridDataAvailable">There are no items to show in this view</span>
<mat-paginator [length]="dataSource.data.length" [pageSize]="50" showFirstLastButtons></mat-paginator>
<div *ngIf="isLoading" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0); display: flex; justify-content: center; align-items: center;">
  <!-- <mat-spinner [diameter]="40"></mat-spinner> -->
</div>
