import { Component, OnInit } from '@angular/core';
import { AuthGuard } from './auth.guard';
import { SidebarService } from '../../sidebar.service';
import { SpinnerServiceService } from './services/spinner-service.service';
import { UserService } from './auth.details';
import { AuthService } from './auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isAuthenticated!: boolean;
  isSidebarOpen!: boolean;
  maintainencePage: boolean = false;
  whitelisted: boolean = false;
  whitelistgroup: string="";
  constructor(private http: HttpClient,private authGuard: AuthGuard, private authService: AuthService, private sidebarService: SidebarService, private spinnerService: SpinnerServiceService, private userService: UserService) {
    this.sidebarService.isSidebarOpen$.subscribe(isOpen => {
      this.isSidebarOpen = isOpen;
    });
  }

  async ngOnInit() {
    this.getdata();
    
   // var email = this.userService.userEmail;
    
  }

  async getdata() {
    this.isAuthenticated = await this.authGuard.canActivate();
    this.whitelisted = true;
    this.http.get<any>(environment.baseUrl + '/RequestList/GetMaintainencePageDetails').subscribe(data => {
      if (data.whitelistenable == "FALSE") {
        this.maintainencePage = false;
      } else {
        this.maintainencePage = true;
      }
      this.whitelistgroup = data.whitelistegroup;
      const account = this.authService.app.getActiveAccount();
      
      this.spinnerService.showSpinner();
    
      if (this.maintainencePage) {
        if (this.whitelistgroup && this.whitelistgroup.includes(account?.username || "")) {
          this.whitelisted = true;
        
          this.spinnerService.hideSpinner();
        } else {
        
          this.whitelisted = false;
          this.spinnerService.hideSpinner();

        }

      } else {
        if (this.isAuthenticated) {
          this.spinnerService.hideSpinner();
        }
      }
    });
  }
}
