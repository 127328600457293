import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';

import { AuthService } from './auth.service';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MasterDashboardComponent } from './master-dashboard/master-dashboard.component';
import { RequestFormComponent } from './request-form/request-form.component';
import { SearchFormComponent } from './search-form/search-form.component';
import { RequestDashboardComponent } from './request-dashboard/request-dashboard.component';
import { AuthGuard } from './auth.guard';
import { MasterFormComponent } from './master-form/master-form.component';
import { DatePipe, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ExistingRequestFormComponent } from './existing-request-form/existing-request-form.component';
import { AdminRequestEditFormComponent } from './admin-request-edit-form/admin-request-edit-form.component';
import { WbsModalComponent } from './wbs-modal/wbs-modal.component';
import { UserRequestDashboardComponent } from './user-request-dashboard/user-request-dashboard.component';
import { ClientModalComponent } from './client-modal/client-modal.component';
import { AlertPopupComponent } from './notify-popup/notify-popup.component';
import { RequestFilterComponent } from './request-filter/request-filter.component';
import { RequestReviewListViewComponent } from './request-review-list-view/request-review-list-view.component';
import { ReplaceNullWithTextPipe } from './replace-null-with-text.pipe';
import { ReplaceTextWithHiphenPipe } from './replace-Text-with-Hiphen.pipe';
import { GlobalMfWbsCodesDashboardComponent } from './globalMFWBSCodes-dashboard/globalMFWBSCodes-dashboard.component';
import { AddEditItemDialogComponent } from './globalMFWBSCodes-form/globalMFWBSCodes-form.component';
import { BooleantostringPipe } from './booleantostring.pipe';
import { LotusNotesComponent } from './lotus-notes/lotus-notes/lotus-notes.component';
import { WindowRefService} from './services/window.service';
import { MultiExistingRequestFormComponent } from './request-filter/multi-existing-request-form/multi-existing-request-form.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';

export function initializeMsal(authService: AuthService) {
  return (): Promise<void> => authService.initializeMsal();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MasterDashboardComponent,
    RequestFormComponent,
    SearchFormComponent,
    RequestDashboardComponent,
    MasterFormComponent,
    ExistingRequestFormComponent,
    AdminRequestEditFormComponent,
    WbsModalComponent,
    UserRequestDashboardComponent,
    ClientModalComponent,
    AlertPopupComponent,
    RequestFilterComponent,
    RequestReviewListViewComponent,
    ReplaceNullWithTextPipe,
    ReplaceTextWithHiphenPipe,
    GlobalMfWbsCodesDashboardComponent,
    AddEditItemDialogComponent,
    BooleantostringPipe,
    LotusNotesComponent,
    MultiExistingRequestFormComponent,
    MaintenanceComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    FormsModule,
    ReactiveFormsModule,

    MatIconModule,
    MatMenuModule,
    MatSortModule,
    MatButtonModule,
    MatInputModule,
    MatTableModule,
    MatSelectModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatListModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    WindowRefService,
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeMsal,
      deps: [AuthService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
