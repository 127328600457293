<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css">
<a class="pageTitle" title="SAR Master List">SAR Master List</a>
<br><br>
<div class="row">
  <div class="col-xs-6" style="display: inline-block;">
    <div style="margin-left:-1%;">
      <label for="Request Creation Year">Year Of Request Creation: </label>&nbsp;
      <select style="height: 25px;"  name="yearOfRequest" aria-placeholder="Select Year" [(ngModel)]="selectedYear" (change)="onYearChange()" id="yearOfRequest" class="form-control">
        <option value="" selected disabled>Select Year</option>
        <option *ngFor="let option of yearsList" [value]="option">
          {{option}}
        </option>
      </select>
    </div>
  </div>
  <div class="col-xs-6" style="display: inline-block;margin-left:44%">
    <div class="search-bar">
      <input type="text" [(ngModel)]="searchText" #filter>
      <button class="primary" type="button"
        (click)="applyGlobalFilter(filter.value)"><mat-icon>search</mat-icon></button>
      <button class="primary" type="button" (click)="resetFilters()"><mat-icon>clear all</mat-icon></button>
      <button *ngIf="isAdmin" class="primary" type="button"
        (click)="exportToExcel()"><mat-icon>save_alt</mat-icon></button>
    </div>
  </div>
</div>
<br>
<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="ServiceOrganization">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>Service Organization</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
                        <i [ngStyle]="{'color':getfiltercolour('ServiceOrganization')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options" >
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('ServiceOrganization'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('ServiceOrganization')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'ServiceOrganization')">
                        {{ option }}
                      </mat-checkbox>
                    </button>
                  </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                        <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                            Apply
                        </button>
                        <button mat-menu-item (click)="clearFilter('ServiceOrganization'); $event.stopPropagation();" class="filter-button">
                            Clear
                        </button>
                    </div> -->
                </mat-menu>
            </th>
            <!-- <td mat-cell *matCellDef="let element"> {{element.ServiceOrganization}} </td> -->
            <td mat-cell *matCellDef="let element" style="max-width: 200px; overflow: hidden; text-overflow: ellipsis; align-items: center;">
                <div style="max-width: 200px; overflow: hidden; text-overflow: ellipsis; align-items: center;display: flex;">
                  <span matTooltip="{{element.ServiceOrganization |replaceNullWithText}}" style="flex-grow: 1; display: block;" class="wrap-text">
                    {{element.ServiceOrganization |replaceNullWithText}}
                  </span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon style="font-size: 16px;">more_horiz</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngIf="isAdmin" (click)="editItem(element)">
                            <span class="edit-menu">Edit Item</span>
                        </button>
                        <button mat-menu-item *ngIf="isAdmin" (click)="deleteItem(element)">
                            <span class="edit-menu">Delete Item</span>
                        </button>
                        <button mat-menu-item (click)="viewItem(element)">
                            <span class="edit-menu">View Item</span>
                        </button>
                    </mat-menu>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="Application">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>Application</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
                       <i [ngStyle]="{'color':getfiltercolour('Application')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('Application'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('Application')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'Application')">
                        {{ option }}
                      </mat-checkbox>
                    </button>
                    </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                      <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                        Apply
                      </button>
                      <button mat-menu-item (click)="clearFilter('Application'); $event.stopPropagation();" class="filter-button">
                        Clear
                      </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element" class="wrap-text"> {{element.Application |replaceNullWithText}} </td>
        </ng-container>

        <ng-container matColumnDef="ServiceSector">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>Service Sector</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('ServiceSector')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('ServiceSector'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('ServiceSector')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'ServiceSector')">
                        {{ option |replaceNullWithText}}
                      </mat-checkbox>
                    </button>
                    </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                      <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                        Apply
                      </button>
                      <button mat-menu-item (click)="clearFilter('ServiceSector'); $event.stopPropagation();" class="filter-button">
                        Clear
                      </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element" class="wrap-text"> {{element.ServiceSector |replaceNullWithText}} </td>
        </ng-container>

        <ng-container matColumnDef="ServiceAuditor">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>Service Auditor</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('ServiceAuditor')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('ServiceAuditor'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('ServiceAuditor')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'ServiceAuditor')">
                        {{ option|replaceNullWithText }}
                      </mat-checkbox>
                    </button>
                    </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                      <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                        Apply
                      </button>
                      <button mat-menu-item (click)="clearFilter('ServiceAuditor'); $event.stopPropagation();" class="filter-button">
                        Clear
                      </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element" class="wrap-text"> {{element.ServiceAuditor |replaceNullWithText}} </td>
        </ng-container>

        <ng-container matColumnDef="ReportPeriodStartDate">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>Report Period Start Date</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('ReportPeriodStartDate')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('ReportPeriodStartDate'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('ReportPeriodStartDate')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'ReportPeriodStartDate')">
                        {{ option|replaceNullWithText| date:'MM/dd/yyyy' }}
                      </mat-checkbox>
                    </button>
                    </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                      <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                        Apply
                      </button>
                      <button mat-menu-item (click)="clearFilter('ReportPeriodStartDate'); $event.stopPropagation();" class="filter-button">
                        Clear
                      </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.ReportPeriodStartDate |replaceNullWithText | date:'MM/dd/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="ReportPeriodEndDate">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>Report Period End Date</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('ReportPeriodEndDate')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('ReportPeriodEndDate'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('ReportPeriodEndDate')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'ReportPeriodEndDate')">
                        {{ option|replaceNullWithText| date:'MM/dd/yyyy' }}
                      </mat-checkbox>
                    </button>
                    </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                      <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                        Apply
                      </button>
                      <button mat-menu-item (click)="clearFilter('ReportPeriodEndDate'); $event.stopPropagation();" class="filter-button">
                        Clear
                      </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.ReportPeriodEndDate|replaceNullWithText | date:'MM/dd/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="IDNumber">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>ID Number</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('IDNumber')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('IDNumber'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('IDNumber')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'IDNumber')">
                        {{ option|replaceNullWithText }}
                      </mat-checkbox>
                    </button>
                    </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                      <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                        Apply
                      </button>
                      <button mat-menu-item (click)="clearFilter('IDNumber'); $event.stopPropagation();" class="filter-button">
                        Clear
                      </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.IDNumber|replaceNullWithText}} </td>
        </ng-container>

        <ng-container matColumnDef="RequestReview">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span>Request Review?</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('RequestReview')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('RequestReview'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('RequestReview')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'RequestReview')">
                        {{ option |replaceNullWithText}}
                      </mat-checkbox>
                    </button>
                    </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                      <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                        Apply
                      </button>
                      <button mat-menu-item (click)="clearFilter('RequestReview'); $event.stopPropagation();" class="filter-button">
                        Clear
                      </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element">
              <a style="color: #337AB7;" [routerLink]="['/existingRequestForm', element.ID, element.IDNumber, element.SARReportType]">
                {{element.RequestReview|replaceNullWithText}}
              </a>
            </td>
        </ng-container>

        <ng-container matColumnDef="ExclusivetoClient">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>Exclusive to Client?</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('ExclusivetoClient')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                    <div class="filter-options">
                      <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                      <span (click)="clearFilter('ExclusivetoClient'); $event.stopPropagation();" class="filter-icon-style">
                        Clear All Filters
                      </span>
                        <button mat-menu-item *ngFor="let option of getUniqueColumnValues('ExclusivetoClient')">
                            <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'ExclusivetoClient')">
                                {{ option ? 'Yes' : 'No'}}
                            </mat-checkbox>
                        </button>
                    </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                        <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                            Apply
                        </button>
                        <button mat-menu-item (click)="clearFilter('ExclusivetoClient'); $event.stopPropagation();" class="filter-button">
                            Clear
                        </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.ExclusivetoClient ? 'Yes' : 'No'}} </td>
        </ng-container>

        <ng-container matColumnDef="SARReportType">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>SAR Report Type</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('SARReportType')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('SARReportType'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('SARReportType')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'SARReportType')">
                        {{ option|replaceNullWithText }}
                      </mat-checkbox>
                    </button>
                    </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                      <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                        Apply
                      </button>
                      <button mat-menu-item (click)="clearFilter('SARReportType'); $event.stopPropagation();" class="filter-button">
                        Clear
                      </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.SARReportType|replaceNullWithText}} </td>
        </ng-container>

        <ng-container matColumnDef="ComplianceAssetId">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>Compliance Asset Id</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('ComplianceAssetId')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('ComplianceAssetId'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('ComplianceAssetId')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'ComplianceAssetId')">
                        {{ option|replaceNullWithText }}
                      </mat-checkbox>
                    </button>
                    </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                      <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                        Apply
                      </button>
                      <button mat-menu-item (click)="clearFilter('ComplianceAssetId'); $event.stopPropagation();" class="filter-button">
                        Clear
                      </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.ComplianceAssetId|replaceNullWithText}} </td>
        </ng-container>

        <ng-container matColumnDef="Created">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>Created</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('Created')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('Created'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('Created')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'Created')">
                        {{ option |replaceNullWithText|date:'MMMM dd, yyyy'}}
                      </mat-checkbox>
                    </button>
                    </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                      <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                        Apply
                      </button>
                      <button mat-menu-item (click)="clearFilter('Created'); $event.stopPropagation();" class="filter-button">
                        Clear
                      </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.Created|replaceNullWithText | date:'MMMM dd, yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="CreatedBy">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>Created By</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('CreatedBy')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('CreatedBy'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('CreatedBy')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'CreatedBy')">
                        {{ option|replaceNullWithText }}
                      </mat-checkbox>
                    </button>
                  </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                      <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                        Apply
                      </button>
                      <button mat-menu-item (click)="clearFilter('CreatedBy'); $event.stopPropagation();" class="filter-button">
                        Clear
                      </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.CreatedByName|replaceNullWithText}} </td>
        </ng-container>

        <ng-container matColumnDef="ID">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>ID</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('ID')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('ID'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('ID')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'ID')">
                        {{ option|replaceNullWithText }}
                      </mat-checkbox>
                    </button>
                  </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                        <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                            Apply
                        </button>
                        <button mat-menu-item (click)="clearFilter('ID'); $event.stopPropagation();" class="filter-button">
                            Clear
                        </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.ID|replaceNullWithText}} </td>
        </ng-container>

        <ng-container matColumnDef="LocationsCovered">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>Location(s) Covered in Report</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('LocationsCovered')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('LocationsCovered'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('LocationsCovered')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'LocationsCovered')">
                        {{ option|replaceNullWithText }}
                      </mat-checkbox>
                    </button>
                  </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                        <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                            Apply
                        </button>
                        <button mat-menu-item (click)="clearFilter('LocationsCovered'); $event.stopPropagation();" class="filter-button">
                            Clear
                        </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element" class="wrap-text"> {{element.LocationsCovered|replaceNullWithText}} </td>
        </ng-container>

        <ng-container matColumnDef="Modified">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>Modified</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('Modified')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('Modified'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('Modified')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'Modified')">
                        {{ option |replaceNullWithText| date:'MMMM dd, yyyy'}}
                      </mat-checkbox>
                    </button>
                  </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                        <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                            Apply
                        </button>
                        <button mat-menu-item (click)="clearFilter('Modified'); $event.stopPropagation();" class="filter-button">
                            Clear
                        </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.Modified | replaceNullWithText | date:'MMMM dd, yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="ModifiedBy">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>Modified By</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('ModifiedBy')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('ModifiedBy'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('ModifiedBy')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'ModifiedBy')">
                        {{ option |replaceNullWithText}}
                      </mat-checkbox>
                    </button>
                  </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                        <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                            Apply
                        </button>
                        <button mat-menu-item (click)="clearFilter('ModifiedBy'); $event.stopPropagation();" class="filter-button">
                            Clear
                        </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.ModifiedByName|replaceNullWithText}} </td>
        </ng-container>

        <ng-container matColumnDef="SARAuthoritativeGuidance">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>SAR Authoritative Guidance</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('SARAuthoritativeGuidance')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('SARAuthoritativeGuidance'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('SARAuthoritativeGuidance')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'SARAuthoritativeGuidance')">
                        {{ option |replaceNullWithText}}
                      </mat-checkbox>
                    </button>
                  </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                        <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                            Apply
                        </button>
                        <button mat-menu-item (click)="clearFilter('SARAuthoritativeGuidance'); $event.stopPropagation();" class="filter-button">
                            Clear
                        </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.SARAuthoritativeGuidance|replaceNullWithText}} </td>
        </ng-container>

        <ng-container matColumnDef="TypeOfOpinion">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                    <span mat-sort-header>Type Of Opinion</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour('TypeOfOpinion')?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
                    </button>
                </div>

                <mat-menu #menu="matMenu" class="customize">
                  <div class="filter-options">
                    <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                    <span (click)="clearFilter('TypeOfOpinion'); $event.stopPropagation();" class="filter-icon-style">
                      Clear All Filters
                    </span>
                    <button mat-menu-item *ngFor="let option of getUniqueColumnValues('TypeOfOpinion')">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, 'TypeOfOpinion')">
                        {{ option|replaceNullWithText }}
                      </mat-checkbox>
                    </button>
                  </div>
                    <!-- <div style="display: flex; justify-content: space-between;" class="filter-buttons">
                        <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                            Apply
                        </button>
                        <button mat-menu-item (click)="clearFilter('TypeOfOpinion'); $event.stopPropagation();" class="filter-button">
                            Clear
                        </button>
                    </div> -->
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.TypeOfOpinion|replaceNullWithText}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
</div>
<br>
<span class="noValues" *ngIf="!isGridDataAvailable">There are no items to show in this view</span>
    <mat-paginator [length]="dataSource.data.length" [pageSize]="50" showFirstLastButtons></mat-paginator>
    <div *ngIf="isLoading" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0); display: flex; justify-content: center; align-items: center;">
        <!-- <mat-spinner [diameter]="40"></mat-spinner> -->
    </div>
