import { NgModule } from '@angular/core';
import { AuthGuard } from './auth.guard';
import { RouterModule, Routes } from '@angular/router';
import { MasterDashboardComponent } from './master-dashboard/master-dashboard.component';
import { SearchFormComponent } from './search-form/search-form.component';
import { RequestFormComponent } from './request-form/request-form.component';
import { RequestDashboardComponent } from './request-dashboard/request-dashboard.component';
import { ExistingRequestFormComponent } from './existing-request-form/existing-request-form.component';
import { UserRequestDashboardComponent } from './user-request-dashboard/user-request-dashboard.component';
import { RequestFilterComponent } from './request-filter/request-filter.component';
import { GlobalMfWbsCodesDashboardComponent } from './globalMFWBSCodes-dashboard/globalMFWBSCodes-dashboard.component';
import { LotusNotesComponent } from './lotus-notes/lotus-notes/lotus-notes.component';

const routes: Routes = [
  { path: '',redirectTo: '/searchForm', pathMatch: 'full' },
  { path: 'searchForm', canActivate:[AuthGuard], component: SearchFormComponent },
  { path: 'masterDashboard', component: MasterDashboardComponent },
  { path: 'requestForm', component: RequestFormComponent },
  { path: 'temporary', component: RequestFormComponent }, // Temporary route
  //{ path: 'existingRequestForm', component: ExistingRequestFormComponent }
  { path: 'ExtendedRequestForm', component: RequestFormComponent, data: { extended: true } },
  { path: 'requestDashboard', component: RequestDashboardComponent },
  { path: 'userRequestDashboard', component: UserRequestDashboardComponent },
  { path: 'existingRequestForm/:id/:idNumber/:sarReportType', component: ExistingRequestFormComponent },
  { path: 'customfilter/:filtercriteria', component: RequestFilterComponent },
  { path: 'globalMFWBSCodesDashboard', component: GlobalMfWbsCodesDashboardComponent },
  { path: 'LotusNotes', component: LotusNotesComponent },
  { path: '**', redirectTo: '/searchForm', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
