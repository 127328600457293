import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleantostring'
})
export class BooleantostringPipe implements PipeTransform {

  transform(value: any, repleceText: string = 'Yes'): any {
    if (typeof value === 'undefined' || value === null || value == true || value == "true") {
      return repleceText;
    }

    return '';
  }

}
