import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerServiceService {

  constructor() { }
  showSpinner(){
    let spinnerId=document.getElementById('loadingAuto',) as HTMLInputElement;
    spinnerId.style.display = 'block';
    document.body.style.overflowY='hidden';
  }
  hideSpinner(){
    let spinnerId=document.getElementById('loadingAuto',) as HTMLInputElement;
    spinnerId.style.display = 'none';
    document.body.style.overflowY='auto';
  }
}
