import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalHelperServiceService {

  constructor() { }
  public currentAccessPage:any="";
}
