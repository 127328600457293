<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css">
<a class="pageTitle" title="Request Review List">Request Review List</a>
<!--<div style="margin-left:-1%;">
  <label for="Request Creation Year">Year Of Request Creation </label>&nbsp;
  <select style="height: 25px;"  name="yearOfRequest" aria-placeholder="Select Year" [(ngModel)]="selectedYear" (change)="onYearChange()" id="yearOfRequest" class="form-control">
    <option value="" selected disabled>Select Year</option>
    <option *ngFor="let option of yearsList" [value]="option">
      {{option}}
    </option>
  </select>
</div>-->
<div class="search-bar">
    <input type="text" [(ngModel)]="searchText" #filter>
    <button class="primary" type="button" (click)="applyGlobalFilter(filter.value)"><mat-icon>search</mat-icon></button>
    <button class="primary" type="button" (click)="resetFilters()"><mat-icon>clear all</mat-icon></button>
</div>
<!-- <div style="display: flex;text-align: left; color:  #337AB7;"><mat-icon>add</mat-icon><a style="width: 20%;color:  #337AB7;" href="#" (click)="openDialog(); $event.preventDefault()">Add new item</a></div> -->
<div style="display: flex;text-align: left;color: #337AB7;">
  <button class="primary icon-button blue-button" type="button" (click)="openDialog(); $event.preventDefault()">
    Add New Review
  </button></div>
  <br>
<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container *ngFor="let col of columns">
        <ng-container *ngIf="col.columnDef == 'Created'" [matColumnDef]="col.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            <div style="display: flex; align-items: center;">
              <span mat-sort-header>{{ col.header }}</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
              </button>
            </div>

            <mat-menu #menu="matMenu" class="customize">
              <div class="filter-options">
                <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                  Clear All Filters
                </span>
                <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                    {{ option }}
                  </mat-checkbox>
                </button>
              </div>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) | date:'MMMM dd, yyyy'}} </td>
        </ng-container>
        <ng-container *ngIf="col.columnDef !== 'ID' && col.columnDef !== 'Created'" [matColumnDef]="col.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            <div style="display: flex; align-items: center;">
              <span mat-sort-header>{{ col.header }}</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <!-- <mat-icon>filter_list</mat-icon> -->
       <i [ngStyle]="{'color':getfiltercolour(col.columnDef)?'#007cb0':null}" class="fas fa-filter filter-icon-size"></i>
              </button>
            </div>

            <mat-menu #menu="matMenu" class="customize">
              <div class="filter-options">
                <i class="fas fa-filter filter-icon" style="margin-left: 30px;"></i>
                <span (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-icon-style">
                  Clear All Filters
                </span>
                <button mat-menu-item *ngFor="let option of getUniqueColumnValues(col.columnDef)">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="applyFilter($event, option, col.columnDef)">
                    {{ option }}
                  </mat-checkbox>
                </button>
              </div>
              <!-- <div style="display: flex; justify-content: space-between;">
            <button mat-menu-item (click)="applyFilters(); $event.stopPropagation();" class="filter-button">
                Apply
            </button>
            <button mat-menu-item (click)="clearFilter(col.columnDef); $event.stopPropagation();" class="filter-button">
                Clear
            </button>
        </div> -->
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element" class="wrap-text"> {{ col.cell(element) }} </td>
        </ng-container>
      </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
<br>
<span class="noValues" *ngIf="!isGridDataAvailable">There are no items to show in this view</span>
<mat-paginator [length]="dataSource.data.length" [pageSize]="10" showFirstLastButtons></mat-paginator>
<div *ngIf="isLoading" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0); display: flex; justify-content: center; align-items: center;">
    <!-- <mat-spinner [diameter]="40"></mat-spinner> -->
</div>
