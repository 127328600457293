<div mat-dialog-content>
  <div class="ms-dlgTitle" style="cursor: move;" >
    <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;" >
      <div style="flex-grow: 1; text-align: center;">
        <h1 title="Request a New Review" mat-dialog-title class="review-form-title">Request a New Review</h1>
      </div>
      <button class="icon-button" (click)="onCloseClick()"><mat-icon class="ms-icon">highlight_off</mat-icon></button>
    </div>
  </div>
    <form  *ngIf="showForm" [formGroup]="form">
      <table>
        <tr style="display: flex; align-items: center;">
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel content-type-label">
            <label for="contentType" class="ms-h3 ms-standardheader label">Content Type</label>
          </td>
          <td valign="top" class="ms-formbody" width="350px" [ngStyle]="{'padding-left': form.get('contentType')?.value === 'Existing Request' ? '133px' : '323px'}">
            <select formControlName="contentType">
              <option value="New Request">New Request</option>
              <option value="Existing Request" selected="selected">Existing Request</option>
            </select>
          </td>
        </tr>
        <div *ngIf="form.get('contentType')?.value === 'Existing Request'" [formGroup]="existingRequestForm">
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="idNumberEx" class="ms-h3 ms-standardheader label required">ID Number </label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" id="idNumberEx" formControlName="idNumberEx" class="wide-input">
            <div *ngIf="existingRequestForm.controls['idNumberEx']?.invalid && existingRequestForm.controls['idNumberEx']?.touched" class="error">
              You can't leave this blank.
            </div>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="businessContactEx" class="ms-h3 ms-standardheader label required">Business Contact </label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" id="businessContactEx" formControlName="businessContactEx" placeholder="Enter a name or email address..." class="wide-input" [matAutocomplete]="auto1" [readOnly]="autoCompleteUserSelected.businessContactExUserSelected">
            <span *ngIf="autoCompleteUserSelected.businessContactExUserSelected" class="clearBtn" (click)="removeSelectedUserExistingRequest('businessContactEx')" [ngStyle]="getCorssMarkStylesExistingRequest('businessContactEx')">X</span>
            <mat-autocomplete #auto1="matAutocomplete" (optionSelected)="onOptionSelectedExistingRequestEx($event, 'businessContactEx')">
              <mat-option *ngFor="let user of filteredUsersBussinessContact | async" [value]="user">
                <div>{{user.displayName}}</div>
                <small>{{user.jobTitle}}</small><br />
                <small>{{user.mail}}</small>
              </mat-option>
              <mat-option *ngIf="showNoResults">
                <div>{{"No Results Found.."}}</div>
              </mat-option>
            </mat-autocomplete>
            <div *ngIf="existingRequestForm.controls['businessContactEx']?.invalid && existingRequestForm.controls['businessContactEx']?.touched" class="error">
              You can't leave this blank.
            </div>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="needByDateEx" class="ms-h3 ms-standardheader label required">Need by Date </label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="date" id="needByDateEx" formControlName="needByDateEx" class="form-control" #needByDateEx>
            <div *ngIf="existingRequestForm.controls['needByDateEx']?.invalid && existingRequestForm.controls['needByDateEx']?.touched" class="error">
              You can't leave this blank.
            </div>
            <span *ngIf="isDateValid(needByDateEx,'needByDateEx')" class="error">Date is Invalid</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="requestorsNameEx" class="ms-h3 ms-standardheader label required">Requestor's Name </label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" id="requestorsNameEx" formControlName="requestorsNameEx" placeholder="Enter a name or email address..." class="wide-input" [matAutocomplete]="auto2" [readOnly]="autoCompleteUserSelected.requestorsExUserSelected">
            <span *ngIf="autoCompleteUserSelected.requestorsExUserSelected" class="clearBtn" (click)="removeSelectedUserExistingRequest('requestorsNameEx')" [ngStyle]="getCorssMarkStylesExistingRequest('requestorsNameEx')">X</span>
            <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="onOptionSelectedExistingRequest($event, 'requestorsNameEx')">
              <mat-option *ngFor="let option of filteredUsersRequestors | async" [value]="option.displayName">
                <div>{{option.displayName}}</div>
                <small>{{option.jobTitle}}</small><br />
                <small>{{option.mail}}</small>
              </mat-option>
              <mat-option *ngIf="showNoResults">
                <div>{{"No Results Found.."}}</div>
              </mat-option>
            </mat-autocomplete>
            <div *ngIf="existingRequestForm.controls['requestorsNameEx']?.invalid && existingRequestForm.controls['requestorsNameEx']?.touched" class="error">
              You can't leave this blank.
            </div>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="AuditSigningPartnerEx" class="ms-h3 ms-standardheader label required">Audit Signing Partner </label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" id="AuditSigningPartnerEx" formControlName="AuditSigningPartnerEx" placeholder="Enter a name or email address..." class="wide-input" [matAutocomplete]="auto6" [readOnly]="autoCompleteUserSelected.auditSigningExUserSelected">
            <span *ngIf="autoCompleteUserSelected.auditSigningExUserSelected" class="clearBtn" (click)="removeSelectedUserExistingRequest('AuditSigningPartnerEx')" [ngStyle]="getCorssMarkStylesExistingRequest('AuditSigningPartnerEx')">X</span>
            <mat-autocomplete #auto6="matAutocomplete" (optionSelected)="onOptionSelectedExistingRequestEx($event, 'AuditSigningPartnerEx')">
              <mat-option *ngFor="let option of filteredAuditSigningPartner | async" [value]="option">
                <div>{{option.displayName}}</div>
                <small>{{option.jobTitle}}</small><br />
                <small>{{option.mail}}</small>
              </mat-option>
              <mat-option *ngIf="showNoResults">
                <div>{{"No Results Found.."}}</div>
              </mat-option>
            </mat-autocomplete>
            <div *ngIf="existingRequestForm.controls['AuditSigningPartnerEx']?.invalid && existingRequestForm.controls['AuditSigningPartnerEx']?.touched" class="error">
              You can't leave this blank.
            </div>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="typeOfAuditEx" class="ms-h3 ms-standardheader label required">Type Of Audit </label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <select formControlName="typeOfAuditEx">
              <option value="Financial Audit" selected="selected">Financial Audit</option>
              <option value="Employee Benefit Plan (EBP) Audit">Employee Benefit Plan (EBP) Audit</option>
              <option value="Investment Audit">Investment Audit</option>
              <option value="SOX Audit">SOX Audit</option>
              <option value="Integrated Audit">Integrated Audit</option>
              <option value="Other">Other</option>
            </select>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="titleEx" class="ms-h3 ms-standardheader label required">Title </label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" id="titleEx" formControlName="titleEx" class="wide-input">
            <div *ngIf="existingRequestForm.controls['titleEx']?.invalid && existingRequestForm.controls['titleEx']?.touched" class="error">
              You can't leave this blank.
            </div>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="billingStatusEx" class="ms-h3 ms-standardheader label">Billing Status</label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <select formControlName="billingStatusEx">
              <option value="Review in Process">Review in Process</option>
              <option value="Bill in Process">Bill in Process</option>
              <option value="Billed">Billed</option>
              <option value="Research">Research</option>
              <option value="Cancelled">Cancelled</option>
              <option value="Only for Record Creation" selected="selected">Only for Record Creation</option>
            </select>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="requestDateEx" class="ms-h3 ms-standardheader label required">Request Date </label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="date" id="requestDateEx" formControlName="requestDateEx" class="form-control" #requestDateEx>
            <div *ngIf="existingRequestForm.controls['requestDateEx']?.invalid && existingRequestForm.controls['requestDateEx']?.touched" class="error">
              You can't leave this blank.
            </div>
            <span *ngIf="isDateValid(requestDateEx,'requestDateEx')" class="error">Date is Invalid</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="sarReportTypeEx" class="ms-h3 ms-standardheader label">SAR Report Type.</label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <select formControlName="sarReportTypeEx">
              <option value="Type I" selected="selected">Type I</option>
              <option value="Type II">Type II</option>
            </select>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="WBSEx" class="ms-h3 ms-standardheader label required">WBS# </label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <div style="display: flex; align-items: center;">
              <input type="text" id="WBSEx" formControlName="WBSEx" class="wide-input" style="margin-right: 10px;" (blur)="onMouseLeave()">
              <img title="Check if External Item exists" src="../../assets/img/EntityInstanceResolver.png" alt="Check if External Item exists" style="margin-right: 5px;" (click)="searchWBSRecords(existingRequestForm, 'WBSEx','existingrequest')">
              <img title="Select External Item(s)" src="../../assets/img/EntityInstancePicker.png" alt="Select External Item(s)" (click)="openDialog(existingRequestForm, 'WBSEx')">
            </div>
            <div *ngIf="existingRequestForm.controls['WBSEx']?.invalid && existingRequestForm.controls['WBSEx']?.touched && !checkClickedWithoutValueWBSEx && !validateWBSCode" class="error">
              You can't leave this blank (or) The WBS Code you entered is invalid.
            </div>
            <div *ngIf="checkClickedWithoutValueWBSEx" class="error">
              You must specify a value before using the Check button. You can also use Select button to choose External Data.
            </div>
            <div *ngIf="validateWBSCode && !checkClickedWithoutValueWBSEx" class="error">
              No exact match was found. Click the item(s) that did not resolve for more options. You can also use Select button to choose External Data
            </div>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="deloitteClientEx" class="ms-h3 ms-standardheader label">Service Organization - Deloitte Client</label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <div style="display: flex; align-items: center;">
              <input type="text" id="deloitteClientEx" formControlName="deloitteClientEx" class="wide-input" style="margin-right: 10px;">
              <img title="Check if External Item exists" src="../../assets/img/EntityInstanceResolver.png" alt="Check if External Item exists" style="margin-right: 5px;" (click)="searchClientRecords(existingRequestForm, 'deloitteClientEx')">
              <img title="Select External Item(s)" src="../../assets/img/EntityInstancePicker.png" alt="Select External Item(s)" (click)="openClientDialog(existingRequestForm, 'deloitteClientEx')">
            </div>
            <div *ngIf="checkClickedWithoutValueDeloitteClientEx" class="error">
              You must specify a value before using the Check button. You can also use Select button to choose External Data.
            </div>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="deliveryDateEx" class="ms-h3 ms-standardheader label">Delivery Date</label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="date" id="deliveryDateEx" formControlName="deliveryDateEx" class="form-control" #deliveryDateEx>
            <span *ngIf="isDateValid(deliveryDateEx,'deliveryDateEx')" class="error">Date is Invalid</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="mfEngagementNameEx" class="ms-h3 ms-standardheader label">MF Engagement Name</label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" id="mfEngagementNameEx" formControlName="mfEngagementNameEx" class="wide-input">
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="mfPartnerNameEx" class="ms-h3 ms-standardheader label">MF Partner Name</label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" id="mfPartnerNameEx" formControlName="mfPartnerNameEx" class="wide-input">
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <label for="mfWBSCodeEx" class="ms-h3 ms-standardheader label">MF WBS Code</label>
          </td>
          <td valign="top" class="ms-formbody" width="350px">
            <input type="text" id="mfWBSCodeEx" formControlName="mfWBSCodeEx" class="wide-input">
          </td>
        </tr>
        <!--<tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label">Attachments</label></td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="file" multiple style="display: none;" (change)="onFileSelectedEx($event)" #fileInputEx>
      <button mat-raised-button (click)="fileInputEx.click()" style="font-size: 1em; padding: 5px 10px;">Choose Files</button>
      <label>  {{selectedFilesEx.length ? selectedFilesEx.length + ' file(s) selected' : 'No file chosen'}}</label>

      <div *ngFor="let file of selectedFilesEx; let j = index">
        {{file.name}}
        <a href="#" (click)="removeFileEx(j); $event.preventDefault()">Delete</a>
      </div>
    </td>
  </tr>-->
</div>
<div *ngIf="form.get('contentType')?.value === 'New Request'" [formGroup]="newRequestForm">
  <tr>
    <td style="width:40%" nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label" for="Note">NOTE: </label></td>
    <td align="left" valign="top" class="ms-formbody" width="350px">
      <span>
        <select name="note" id="note">
          <option value="New Request">New Request</option>
        </select>
        <br>
      </span>
      <span class="ms-metadata" id="notetext" style="font-size:11.7px;">
        Attach Service Auditor Report :  <br>
        Use the Attach File feature above to attach the report.&nbsp;
        Without the report attachment the review team will not be able to initiate and process the request for the review. Attach only one report per request.  <br> <br>
        Do not use special characters (% &amp; * .) or any spaces within the file name.
      </span>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label" for="VerifyServiceOrganization">Verify you have validated if the ServiceOrganization is a Deloitte Client</label></td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="checkbox" class="form-control" formControlName="VerifyServiceOrganization">
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label required" for="ServiceOrganization">Service Organization  </label></td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="text" class="form-control" formControlName="ServiceOrganization" required class="wide-input">
      <div *ngIf="newRequestForm.controls['ServiceOrganization']?.invalid && newRequestForm.controls['ServiceOrganization']?.touched" class="error">
        You can't leave this blank.
      </div>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label required" for="ServiceSector">Service Sector  </label></td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="text" class="form-control" formControlName="ServiceSector" required class="wide-input">
      <div *ngIf="newRequestForm.controls['ServiceSector']?.invalid && newRequestForm.controls['ServiceSector']?.touched" class="error">
        You can't leave this blank.
      </div>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label required" for="ServiceAuditor">Service Auditor  </label></td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="text" class="form-control" formControlName="ServiceAuditor" required class="wide-input">
      <div *ngIf="newRequestForm.controls['ServiceAuditor']?.invalid && newRequestForm.controls['ServiceAuditor']?.touched" class="error">
        You can't leave this blank.
      </div>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label required" for="Application">Application  </label></td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="text" class="form-control" formControlName="Application" required class="wide-input">
      <div *ngIf="newRequestForm.controls['Application']?.invalid && newRequestForm.controls['Application']?.touched" class="error">
        You can't leave this blank.
      </div>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label" for="ExclusiveToClient">Exclusive to Client?</label></td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="checkbox" class="form-control" formControlName="ExclusiveToClient">
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="TypeOfAudit" class="ms-h3 ms-standardheader label">Type Of Audit</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <select formControlName="TypeOfAudit">
        <option value="Financial Audit" selected="selected">Financial Audit</option>
        <option value="Employee Benefit Plan (EBP) Audit">Employee Benefit Plan (EBP) Audit</option>
        <option value="Investment Audit">Investment Audit</option>
        <option value="SOX Audit">SOX Audit</option>
        <option value="Integrated Audit">Integrated Audit</option>
        <option value="Other">Other</option>
      </select>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label" for="LocationsCoveredInReport">Location(s) Covered in Report </label></td>
    <td valign="top" class="ms-formbody" width="350px"><input type="text" class="form-control" formControlName="LocationsCoveredInReport" class="wide-input"></td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label" for="TypeOfOpinion">Type of Opinion </label></td>
    <td valign="top" class="ms-formbody" width="350px">
      <select name="TypeOfOpinion" id="TypeofOpinion" class="form-control" formControlName="TypeOfOpinion">
        <option value="Qualified">Qualified</option>
        <option value="Unqualified">Unqualified</option>
      </select>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label" for="ReportPeriodStartDate">Report Period Start Date </label></td>
    <td valign="top" class="ms-formbody" width="350px"><input type="date" class="form-control" formControlName="ReportPeriodStartDate" #ReportPeriodStartDate>
      <span *ngIf="isDateValid(ReportPeriodStartDate,'ReportPeriodStartDate')" class="error">Date is Invalid</span>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label required" for="ReportPeriodEndDate">Report Period End Date </label></td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="date" class="form-control" formControlName="ReportPeriodEndDate" #ReportPeriodEndDate>
      <div *ngIf="newRequestForm.controls['ReportPeriodEndDate']?.invalid && newRequestForm.controls['ReportPeriodEndDate']?.touched" class="error">
        You can't leave this blank.
      </div>
      <span *ngIf="isDateValid(ReportPeriodEndDate,'ReportPeriodEndDate')" class="error">Date is Invalid</span>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label class="ms-h3 ms-standardheader label">SAR Authoritative Guidance</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <span>
        <table>
          <tbody>
            <tr>
              <td>
                <input id="SARAuthoritativeGuidanceDropDownButton" type="radio" name="SARAuthoritativeGuidanceChecked" value="DropDown" formControlName="SARAuthoritativeGuidanceChecked" checked>
                <select id="SARAuthoritativeGuidanceDropDownChoice" title="SAR Authoritative Guidance" class="ms-RadioText form-control" formControlName="SARAuthoritativeGuidanceDropDown" onclick="document.getElementById('SARAuthoritativeGuidanceDropDownButton').checked = true;">
                  <option value="SSAE 18">SSAE 18</option>
                  <option value="CSAE 3416">CSAE 3416</option>
                  <option value="ISAE 3402">ISAE 3402</option>
                  <option value="SOC 2">SOC 2</option>
                </select>
              </td>
            </tr>

            <tr>
              <td>
                <span class="ms-RadioText" title="SAR Authoritative Guidance: Specify your own value:">
                  <input id="SARAuthoritativeGuidanceFillInButton" type="radio" name="SARAuthoritativeGuidanceChecked" value="Text" formControlName="SARAuthoritativeGuidanceChecked">
                  <label class="ms-h3 ms-standardheader label" for="SARAuthoritativeGuidanceFillInButton">Specify your own value:</label>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <input type="text" class="form-control" maxlength="255" id="SARAuthoritativeGuidanceFillInChoice" tabindex="-1" value="" title="SAR Authoritative Guidance: Specify your own value:" formControlName="SARAuthoritativeGuidanceText" onfocus="document.getElementById('SARAuthoritativeGuidanceFillInButton').checked = true;">
              </td>
            </tr>
          </tbody>
        </table>
      </span>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label">SAR Report Type. </label></td>
    <td valign="top" class="ms-formbody" width="350px">
      <select id="DropDownChoice" title="SAR Report Type." class="form-control" formControlName="SARReportType">
        <option value="Type I">Type I</option>
        <option value="Type II">Type II</option>
      </select>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="RequestorsName" class="ms-h3 ms-standardheader label required">Requestor's Name </label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="text" id="RequestorsName" formControlName="RequestorsName" placeholder="Enter a name or email address..." class="wide-input" [matAutocomplete]="auto2" [readOnly]="autoCompleteUserSelected.requestorsUserSelected">
      <span *ngIf="autoCompleteUserSelected.requestorsUserSelected" class="clearBtn" (click)="removeSelectedUserNewRequest('RequestorsName')" [ngStyle]="getCorssMarkStylesNewRequest('RequestorsName')">X</span>
      <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="onOptionSelectedNewRequest($event, 'RequestorsName')">
        <mat-option *ngFor="let option of newfilteredUsersRequestors | async" [value]="option.displayName">
          <div>{{option.displayName}}</div>
          <small>{{option.jobTitle}}</small><br />
          <small>{{option.mail}}</small>
        </mat-option>
        <mat-option *ngIf="showNoResults">
          <div>{{"No Results Found.."}}</div>
        </mat-option>
      </mat-autocomplete>
      <div *ngIf="newRequestForm.controls['RequestorsName']?.invalid && newRequestForm.controls['RequestorsName']?.touched" class="error">
        You can't leave this blank.
      </div>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="AuditSigningPartner" class="ms-h3 ms-standardheader label required">Audit Signing Partner </label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="text" id="AuditSigningPartner" formControlName="AuditSigningPartner" placeholder="Enter a name or email address..." class="wide-input" [matAutocomplete]="auto7" [readOnly]="autoCompleteUserSelected.auditSigningUserSelected">
      <span *ngIf="autoCompleteUserSelected.auditSigningUserSelected" class="clearBtn" (click)="removeSelectedUserNewRequest('AuditSigningPartner')" [ngStyle]="getCorssMarkStylesNewRequest('AuditSigningPartner')">X</span>
      <mat-autocomplete #auto7="matAutocomplete" (optionSelected)="onOptionSelectedNewRequestEx($event, 'AuditSigningPartner')">
        <mat-option *ngFor="let option of newfilteredAuditSigningPartner | async" [value]="option">
          <div>{{option.displayName}}</div>
          <small>{{option.jobTitle}}</small><br />
          <small>{{option.mail}}</small>
        </mat-option>
        <mat-option *ngIf="showNoResults">
          <div>{{"No Results Found.."}}</div>
        </mat-option>
      </mat-autocomplete>
      <div *ngIf="newRequestForm.controls['AuditSigningPartner']?.invalid && newRequestForm.controls['AuditSigningPartner']?.touched" class="error">
        You can't leave this blank.
      </div>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label">Requestor's Name (Lotus Notes) </label></td>
    <td valign="top" class="ms-formbody" width="350px"><input type="text" class="form-control" formControlName="RequestorsNameLotusNotes" class="wide-input"></td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label">Requestor Department</label></td>
    <td valign="top" class="ms-formbody" width="350px"><input type="text" class="form-control" formControlName="RequestorDepartment" class="wide-input"></td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label">Requestor Title </label></td>
    <td valign="top" class="ms-formbody" width="350px"><input type="text" class="form-control" formControlName="RequestorTitle" class="wide-input"></td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="BusinessContact" class="ms-h3 ms-standardheader label required">Business Contact </label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="text" id="BusinessContact" formControlName="BusinessContact" placeholder="Enter a name or email address..." class="wide-input" [matAutocomplete]="auto1" [readOnly]="autoCompleteUserSelected.businessContactUserSelected">
      <span *ngIf="autoCompleteUserSelected.businessContactUserSelected" class="clearBtn" (click)="removeSelectedUserNewRequest('BusinessContact')" [ngStyle]="getCorssMarkStylesNewRequest('BusinessContact')">X</span>
      <mat-autocomplete #auto1="matAutocomplete" (optionSelected)="onOptionSelectedNewRequestEx($event, 'BusinessContact')">
        <mat-option *ngFor="let user of newfilteredUsersBussinessContact | async" [value]="user">
          <div>{{user.displayName}}</div>
          <small>{{user.jobTitle}}</small><br />
          <small>{{user.mail}}</small>
        </mat-option>
        <mat-option *ngIf="showNoResults">
          <div>{{"No Results Found.."}}</div>
        </mat-option>
      </mat-autocomplete>
      <div *ngIf="newRequestForm.controls['BusinessContact']?.invalid && newRequestForm.controls['BusinessContact']?.touched" class="error">
        You can't leave this blank.
      </div>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="RequestDate" class="ms-h3 ms-standardheader label required">Request Date </label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="date" id="RequestDate" formControlName="RequestDate" class="form-control" #requestDate>
      <div *ngIf="newRequestForm.controls['RequestDate']?.invalid && newRequestForm.controls['RequestDate']?.touched" class="error">
        You can't leave this blank.
      </div>
      <span *ngIf="isDateValid(requestDate,'requestDate')" class="error">Date is Invalid</span>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="RequestStatus" class="ms-h3 ms-standardheader label">Request Status</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <select formControlName="RequestStatus">
        <option value="Report for allotment">Report for allotment</option>
        <option value="First Review in Process">First Review in Process</option>
        <option value="Managerial Review in Process">Managerial Review in Process</option>
        <option value="Quality Review in Process">Quality Review in Process</option>
        <option value="Reviewed">Reviewed</option>
        <option value="To be Delivered">To be Delivered</option>
        <option value="Only for record creation" selected="selected">Only for record creation</option>
      </select>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="BillingStatus" class="ms-h3 ms-standardheader label">Billing Status</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <select formControlName="BillingStatus">
        <option value="Review in Process">Review in Process</option>
        <option value="Bill in Process">Bill in Process</option>
        <option value="Billed">Billed</option>
        <option value="Research">Research</option>
        <option value="Cancelled">Cancelled</option>
        <option value="Only for Record Creation" selected="selected">Only for Record Creation</option>
      </select>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="NeedByDate" class="ms-h3 ms-standardheader label required">Need by Date </label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="date" id="NeedByDate" formControlName="NeedByDate" class="form-control" #NeedByDate>
      <div *ngIf="newRequestForm.controls['NeedByDate']?.invalid && newRequestForm.controls['NeedByDate']?.touched" class="error">
        You can't leave this blank.
      </div>
      <span *ngIf="isDateValid(NeedByDate,'NeedByDate')" class="error">Date is Invalid</span>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="FirstPreparer" class="ms-h3 ms-standardheader label">First Preparer</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="text" id="FirstPreparer" formControlName="FirstPreparer" placeholder="Enter a name or email address..." class="wide-input" [matAutocomplete]="auto3" [readOnly]="autoCompleteUserSelected.firstPreparerUserSelected">
      <span *ngIf="autoCompleteUserSelected.firstPreparerUserSelected" class="clearBtn" (click)="removeSelectedUserNewRequest('FirstPreparer')" [ngStyle]="getCorssMarkStylesNewRequest('FirstPreparer')">X</span>
      <mat-autocomplete #auto3="matAutocomplete" (optionSelected)="onOptionSelectedNewRequestEx($event, 'FirstPreparer')">
        <mat-option *ngFor="let user of filteredUsersfirstPreparer | async" [value]="user">
          <div>{{user.displayName}}</div>
          <small>{{user.jobTitle}}</small><br />
          <small>{{user.mail}}</small>
        </mat-option>
        <mat-option *ngIf="showNoResults">
          <div>{{"No Results Found.."}}</div>
        </mat-option>
      </mat-autocomplete>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="SecondPreparer" class="ms-h3 ms-standardheader label">Second Preparer</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="text" id="SecondPreparer" formControlName="SecondPreparer" placeholder="Enter a name or email address..." class="wide-input" [matAutocomplete]="auto4" [readOnly]="autoCompleteUserSelected.secondPreparerUserSelected">
      <span *ngIf="autoCompleteUserSelected.secondPreparerUserSelected" class="clearBtn" (click)="removeSelectedUserNewRequest('SecondPreparer')" [ngStyle]="getCorssMarkStylesNewRequest('SecondPreparer')">X</span>
      <mat-autocomplete #auto4="matAutocomplete" (optionSelected)="onOptionSelectedNewRequestEx($event, 'SecondPreparer')">
        <mat-option *ngFor="let user of filteredUserssecondPreparer | async" [value]="user">
          <div>{{user.displayName}}</div>
          <small>{{user.jobTitle}}</small><br />
          <small>{{user.mail}}</small>
        </mat-option>
        <mat-option *ngIf="showNoResults">
          <div>{{"No Results Found.."}}</div>
        </mat-option>
      </mat-autocomplete>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="ThirdPreparer" class="ms-h3 ms-standardheader label">Third Preparer</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="text" id="ThirdPreparer" formControlName="ThirdPreparer" placeholder="Enter a name or email address..." class="wide-input" [matAutocomplete]="auto5" [readOnly]="autoCompleteUserSelected.thirdPreparerUserSelected">
      <span *ngIf="autoCompleteUserSelected.thirdPreparerUserSelected" class="clearBtn" (click)="removeSelectedUserNewRequest('ThirdPreparer')" [ngStyle]="getCorssMarkStylesNewRequest('ThirdPreparer')">X</span>
      <mat-autocomplete #auto5="matAutocomplete" (optionSelected)="onOptionSelectedNewRequestEx($event, 'ThirdPreparer')">
        <mat-option *ngFor="let user of filteredUsersthirdPreparer | async" [value]="user">
          <div>{{user.displayName}}</div>
          <small>{{user.jobTitle}}</small><br />
          <small>{{user.mail}}</small>
        </mat-option>
        <mat-option *ngIf="showNoResults">
          <div>{{"No Results Found.."}}</div>
        </mat-option>
      </mat-autocomplete>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="AllottedDate" class="ms-h3 ms-standardheader label">Alloted Date</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="date" id="AllottedDate" formControlName="AllottedDate" class="form-control" #AllottedDate>
      <span *ngIf="isDateValid(AllottedDate,'AllottedDate')" class="error">Date is Invalid</span>
    </td>
  </tr>
  <tr>
    <td style="vertical-align:text-top" nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label">Comments for Workflow </label></td>
    <td valign="top" class="ms-formbody" width="350px">
      <table>
        <tr><td valign="top" class="ms-formbody" width="350px"><textarea rows="6" cols="20" id="TextField" title="Rich text editor Comments for Workflow" class="form-control" formControlName="CommentsForWorkflow" class="wide-input"></textarea></td></tr>
        <!--<tr id="notetext"><td><a href="javascript:HelpWindowKey('nsrichtext')">Click for help about adding basic HTML formatting.</a></td></tr>-->
        <tr id="notetext"><td><span class="ms-metadata" style="font-size:11.7px;">(ID Number to be completed by SAR Review team Only)</span></td></tr>
      </table>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="IDNumber" class="ms-h3 ms-standardheader label required">ID Number </label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="text" id="IDNumber" formControlName="IDNumber" class="wide-input">
      <div *ngIf="newRequestForm.controls['IDNumber']?.invalid && newRequestForm.controls['IDNumber']?.touched" class="error">
        You can't leave this blank.
      </div>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="FRRD" class="ms-h3 ms-standardheader label">FRRD</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="date" id="FRRD" formControlName="FRRD" class="form-control" #FRRD>
      <span *ngIf="isDateValid(FRRD,'FRRD')" class="error">Date is Invalid</span>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="FRCD" class="ms-h3 ms-standardheader label" >FRCD</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="date" id="FRCD" formControlName="FRCD" class="form-control" #FRCD>
      <span *ngIf="isDateValid(FRCD,'FRCD')" class="error">Date is Invalid</span>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="SRCD" class="ms-h3 ms-standardheader label">SRCD</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="date" id="SRCD" formControlName="SRCD" class="form-control" #SRCD>
      <span *ngIf="isDateValid(SRCD,'SRCD')" class="error">Date is Invalid</span>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="DeliveryDate" class="ms-h3 ms-standardheader label">Delivery Date</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="date" id="DeliveryDate" formControlName="DeliveryDate" class="form-control" #DeliveryDate>
      <span *ngIf="isDateValid(DeliveryDate,'DeliveryDate')" class="error">Date is Invalid</span>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="XYZCode" class="ms-h3 ms-standardheader label">XYZ Code</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="text" id="XYZCode" formControlName="XYZCode" class="wide-input">
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label" for="PreviousPeriodReviewAvailable">Previous Period Review W/P Available</label></td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="checkbox" class="form-control" formControlName="PreviousPeriodReviewAvailable">
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="EmphasisOfMatter" class="ms-h3 ms-standardheader label">Emphasis of Matter</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <select formControlName="EmphasisOfMatter">
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="OpinionDate" class="ms-h3 ms-standardheader label">Opinion Date</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="date" id="OpinionDate" formControlName="OpinionDate" class="form-control" #OpinionDate>
      <span *ngIf="isDateValid(OpinionDate,'OpinionDate')" class="error">Date is Invalid</span>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="NumberOfRequestsReceivedForPriorPeriodReview" class="ms-h3 ms-standardheader label"># of rqsts received for prior period review</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="text" id="NumberOfRequestsReceivedForPriorPeriodReview" formControlName="NumberOfRequestsReceivedForPriorPeriodReview" class="wide-input">
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="ReportLength" class="ms-h3 ms-standardheader label">Report Length</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="text" id="ReportLength" formControlName="ReportLength">
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label" for="Publish">Publish?</label></td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="checkbox" class="form-control" formControlName="Publish">
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="DateTimeOfLastAction" class="ms-h3 ms-standardheader label">Date/ Time of Last Action</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="datetime-local" id="DateTimeOfLastAction" formControlName="DateTimeOfLastAction" class="form-control">
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="Cost" class="ms-h3 ms-standardheader label">Cost</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="text" id="Cost" formControlName="Cost">
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="WBS" class="ms-h3 ms-standardheader label required">WBS# </label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <div style="display: flex; align-items: center;">
        <input type="text" id="WBS" formControlName="WBS" class="wide-input" style="margin-right: 10px;" (blur)="onMouseLeavenew()">
        <img title="Check if External Item exists" src="../../assets/img/EntityInstanceResolver.png" alt="Check if External Item exists" style="margin-right: 5px;" (click)="searchWBSRecords(newRequestForm, 'WBS','newrequest')">
        <img title="Select External Item(s)" src="../../assets/img/EntityInstancePicker.png" alt="Select External Item(s)" (click)="openDialog(newRequestForm, 'WBS')">
      </div>
      <div *ngIf="newRequestForm.controls['WBS']?.invalid && newRequestForm.controls['WBS']?.touched && !checkClickedWithoutValue && !validateWBSCode" class="error">
        You can't leave this blank (or) The WBS Code you entered is invalid.
      </div>
      <div *ngIf="validateWBSCode && !checkClickedWithoutValueWBSEx" class="error">
        No exact match was found. Click the item(s) that did not resolve for more options. You can also use Select button to choose External Data
      </div>
      <div *ngIf="checkClickedWithoutValueWBSEx" class="error">
        You must specify a value before using the Check button. You can also use Select button to choose External Data.
      </div>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="ServiceOrganizationDeloitteClient" class="ms-h3 ms-standardheader label">Service Organization - Deloitte Client</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <div style="display: flex; align-items: center;">
        <input type="text" id="ServiceOrganizationDeloitteClient" formControlName="ServiceOrganizationDeloitteClient" class="wide-input" style="margin-right: 10px;">
        <img title="Check if External Item exists" src="../../assets/img/EntityInstanceResolver.png" alt="Check if External Item exists" style="margin-right: 5px;" (click)="searchClientRecords(newRequestForm, 'ServiceOrganizationDeloitteClient')">
        <img title="Select External Item(s)" src="../../assets/img/EntityInstancePicker.png" alt="Select External Item(s)" (click)="openClientDialog(newRequestForm, 'ServiceOrganizationDeloitteClient')">
      </div>
      <div *ngIf="checkClickedWithoutValueDeloitteClientEx" class="error">
        You must specify a value before using the Check button. You can also use Select button to choose External Data.
      </div>
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="MFEngagementName" class="ms-h3 ms-standardheader label">MF Engagement Name</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="text" id="MFEngagementName" formControlName="MFEngagementName" class="wide-input">
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="MFPartnerName" class="ms-h3 ms-standardheader label">MF Partner Name</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="text" id="MFPartnerName" formControlName="MFPartnerName" class="wide-input">
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
      <label for="MFWBSCode" class="ms-h3 ms-standardheader label">MF WBS Code</label>
    </td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="text" id="MFWBSCode" formControlName="MFWBSCode" class="wide-input">
    </td>
  </tr>
  <tr>
    <td nowrap="true" valign="top" width="113px" class="ms-formlabel"><label class="ms-h3 ms-standardheader label">Attachments</label></td>
    <td valign="top" class="ms-formbody" width="350px">
      <input type="file" multiple style="display: none;" (change)="onFileSelected($event)" #fileInput>
      <button mat-raised-button (click)="fileInput.click()" style="font-size: 1em; padding: 5px 10px;">Choose Files</button>
      <label>  {{selectedFiles.length ? selectedFiles.length + ' file(s) selected' : 'No file chosen'}}</label>

      <div *ngFor="let file of selectedFiles; let i = index">
        <a href="#" (click)="downloadSelectedFile(file.name);$event.preventDefault()">{{file.name}}</a>&nbsp;&nbsp;
        <a href="#" (click)="removeFile(i); $event.preventDefault()">Delete</a>
      </div>
    </td>
  </tr>
</div>
        </table>
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <span class="ms-formbody">
            Created at {{responseData.Created | date:'MM/dd/yyyy, h:mm a'}} by {{responseData.CreatedBy}}<br />
            Last modified at {{responseData.Modified | date:'MM/dd/yyyy, h:mm a'}} by {{responseData.ModifiedBy}}
          </span>
            <div mat-dialog-actions>
                <button mat-button (click)="form.get('contentType')?.value === 'Existing Request' ? saveExistingRequestChanges() : saveNewRequest()" class="ms-button" style="color: #FFFFFF;">Save</button>
                <button mat-button mat-dialog-close (click)="onCancelClick()" class="ms-button" style="color: #FFFFFF;">Cancel</button>
            </div>
        </div>
    </form>
</div>
