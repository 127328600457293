<div class="mat-dialog-container">
    <div class="ms-dlgBorder" style="width:613px; height:523px;">
        <div class="ms-dlgTitle" style="cursor: move;">
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <h1 title="Choose SAR.Client" class="ms-dlgTitleText ms-accentText ms-dlg-heading" id="dialogTitleSpan" style="width:525px;">Choose SAR.Client</h1>
                <button class="icon-button" (click)="closeDialog()"><mat-icon class="ms-icon">highlight_off</mat-icon></button>
            </div>
        </div>
        <div>
            <table>
                <tr>
                    <td class="ms-descriptiontext"> <b> Find </b> &nbsp; </td>
                    <td class="ms-descriptiontext">
                        <select>
                            <option value="SelectFilter" disabled>Select Filter</option>
                            <option value="ClientName" selected="selected">ClientName</option>
                        </select>
                    </td>
                    <td>
                        <input type="text" placeholder="Search" [(ngModel)]="searchTerm" class="wide-input">
                        <button (click)="searchClientRecords(searchTerm)">Search</button>
                    </td>
                </tr>
            </table>
        </div>
        <div *ngIf="!clientRecords || clientRecords.length === 0" class="ms-descriptiontext" style="color:red;">No results were found for '{{searchTerm}}'. Ensure words are spelled correctly and make your query as concise as possible.</div>
        <div *ngIf="clientRecords.length!==0" class="ms-descriptiontext" style="color:red;">Warning: Too many results have been returned. This result set may be incomplete or truncated.</div>
        <br>
        <div class="ms-pickerresultdiv" style="height: 377px;">
            <table class="ms-pickerresulttable" cellspacing="0" cellpadding="0" hidefocus="true" style="background-color:White;width:100%;border-collapse:collapse;">
                <thead>
                    <tr class="ms-pickerresultheadertr">
                        <th class="ms-ph">ClientName</th>
                        <th class="ms-ph">ClientNumber</th>
                        <th class="ms-ph">IndustryDesc</th>
                        <th class="ms-ph">ParentClientName</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let record of clientRecords" class="record" [class.selected]="selectedRecord === record" (click)="selectRecord(record)">
                        <td class="ms-pb" nowrap="true" style="width:14%;"> <div (click)="selectRecord(record)">{{ record.ClientName }}</div></td>
                        <td class="ms-pb" nowrap="true" style="width:14%;">{{ record.ClientNumber }}</td>
                        <td class="ms-pb" nowrap="true" style="width:14%;">{{ record.IndustryDesc }}</td>
                        <td class="ms-pb" nowrap="true" style="width:14%;">{{ record.ParentClientName }}</td>
                    </tr>
                    <br>
                    <tr *ngIf="!clientRecords || clientRecords.length === 0" class="ms-descriptiontext">
                        <td colspan="7">Type into the search box above then press "Enter" to start your search.</td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="isLoading" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0); display: flex; justify-content: center; align-items: center;">
                <div class="spinner"></div>
            </div>
        </div>
        <div style="display: flex; justify-content: right; align-items: flex-end; padding-bottom: 0;">
            <div mat-dialog-actions>
                <button mat-button (click)="selectRecordAndClose()" class="ms-button" style="color: #FFFFFF;">OK</button>
                <button mat-button (click)="closeDialog()" class="ms-button" style="color: #FFFFFF;" >Cancel</button>
            </div>
        </div>
    </div>
</div>


