<div>
    <div class="header">
        <div class="left-section">
            <!-- <div class="hamburger-menu" (click)="onMenuClick()"> 
                <div></div>
                <div></div>
                <div></div>
            </div>-->
            <img src="../../assets/img/Deloitte_SEC.png" alt="Site Icon" class="site-icon">
        </div>

        <div class="right-section">
            <div *ngIf="userName" class="user-info">
                <img [src]="userPhoto" alt="User Photo">
                <p>Hi, {{ userName }}</p>
                <p></p>
                <p></p>
            </div>
        </div>
    </div>

    <div class="sidebar" [ngClass]="{'sidebar-open': isSidebarOpen}">
      <a class="sidemenumain-font">SAR Home</a>
      <div class="sub-link">
        <a class="sidemenu-font" routerLink="/searchForm" routerLinkActive="active-link">Search SAR Master List</a>
        <a class="sidemenu-font" routerLink="/masterDashboard" routerLinkActive="active-link">SAR Master List</a>
        <a class="sidemenu-font" *ngIf="isAdmin" routerLink="/requestDashboard" routerLinkActive="active-link">Request Review List</a>
        <a class="sidemenu-font" (click)="navigateToNewRequest($event)" routerLink="/requestForm" routerLinkActive="active-link">Request a New Review</a>
        <a class="sidemenu-font" style="display: none;" routerLink="/existingRequestForm" routerLinkActive="active-link">Request a Existing Review</a>
        <a class="sidemenu-font" routerLink="/userRequestDashboard" routerLinkActive="active-link">My Requests</a>
      </div>
      <div *ngIf="isAdmin">
        <a class="sidemenumain-font">SAR Admin View</a>
        <div class="sub-link">
          <a class="sidemenu-font" [routerLink]="['/customfilter','Report for Allotment']" routerLinkActive="active-link">Report for Allotment</a>
          <a class="sidemenu-font" [routerLink]="['/customfilter','Review in Process']" routerLinkActive="active-link">Reviews in Process</a>
          <a class="sidemenu-font" [routerLink]="['/customfilter','Existing Requests']" routerLinkActive="active-link">Existing Requests</a>
          <a class="sidemenu-font" [routerLink]="['/customfilter','dashboard']" routerLinkActive="active-link">Dashboard</a>
          <a class="sidemenu-font" [routerLink]="['/customfilter','To be billed']" routerLinkActive="active-link">To Be Billed</a>
          <a class="sidemenu-font" [routerLink]="['/LotusNotes']" routerLinkActive="active-link">Lotus Notes</a>
          
         
        </div>
        <a class="sidemenumain-font" routerLink="/globalMFWBSCodesDashboard" routerLinkActive="active-link">GlobalMF WBSCodes</a>
      </div>
      <!-- <a class="hide-button" (click)="onSidenavClose()">
    <i class="material-icons">chevron_left</i>
  </a> -->
      <br> <br>
    </div>
</div>



