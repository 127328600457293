<div *ngIf="data.ContentType ==='Existing Request'" class="mat-dialog-container">
  <div class="ms-dlgBorder">
    <div class="ms-dlgTitle" style="cursor: move;">
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <h1 title="Request Review List - View Details" mat-dialog-title class="master-form-title">Request Review List - View Details</h1>
        <button class="icon-button" (click)="onCloseClick()"><mat-icon class="ms-icon">highlight_off</mat-icon></button>
      </div>
    </div>
    <div mat-dialog-content>
      <table>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">ID Number</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.IDNumber}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Business Contact</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.BusinessContact}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Need By Date</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.NeedByDate| date:'MM/dd/yyyy'}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Requestor's Name</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.RequestorsName}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Audit Signing Partner</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.AuditSigningPartner}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Type of Audit</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.TypeOfAudit}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Billing Status</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.BillingStatus}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Request Date</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.RequestDate | date:'MM/dd/yyyy'}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">SAR Report Type</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.SARReportType}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">WBS#</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.WBS}}</span>
          </td>
        </tr>


        <tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">DeliveryDate</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.DeliveryDate}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">MF Engagement Name</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.MFEngagementName}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">MF Partner Name</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.MFPartnerName}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">MF WBS Code</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.MFWBSCode}}</span>
          </td>
        </tr>




      </table>
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <span class="ms-formbody">
          Content Type: {{data.ContentType}}<br />
          Created at {{data.Created | date:'MM/dd/yyyy, h:mm a'}} by {{data.CreatedBy}}<br />
          Last modified at {{data.Modified | date:'MM/dd/yyyy, h:mm a'}} by {{data.ModifiedBy}}
        </span>
        <div mat-dialog-actions>
          <button mat-button mat-dialog-close class="ms-button" (click)="onCloseClick()" style="color: #FFFFFF;" >Close</button>
        </div>
      </div>

    </div>
  </div>
</div>
<div *ngIf="data.ContentType ==='New Request'" class="mat-dialog-container">
  <div class="ms-dlgBorder">
    <div class="ms-dlgTitle" style="cursor: move;">
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <h1 title="Request Review List - View Details" mat-dialog-title class="master-form-title">Request Review List - View Details</h1>
        <button class="icon-button" (click)="onCloseClick()"><mat-icon class="ms-icon">highlight_off</mat-icon></button>
      </div>
    </div>
    <div mat-dialog-content>
      <table>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">NOTE:</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.ContentType}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Verify you have validated if the Service Organization is a Deloitte Client</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{'yes'}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Service Organization</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.ServiceOrganization}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Service Sector</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.ServiceSector}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Service Auditor</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.ServiceAuditor}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Application</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.Application}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Exclusive to Client?</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.ExclusivetoClient}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Type of Audit</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.TypeOfAudit}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Location(s) Covered in Report </span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.LocationsCoveredInReport}}</span>
          </td>
        </tr>


        <tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Type Of Opinion</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.TypeOfOpinion}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Report Period Start Date</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.ReportPeriodStartDate| date:'MM/dd/yyyy'}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Report Period End Date</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.ReportPeriodEndDate| date:'MM/dd/yyyy'}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">SAR Authoritative Guidance</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.SARAuthoritativeGuidance}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">SAR Report Type</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.SARReportType}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Requestor's Name</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.RequestorsName}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Audit Signing Partner</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.AuditSigningPartner}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Requestor's Name(LotusNotes)</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.RequestorsNameLotusNotes}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Requestor Department</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.RequestorDepartment}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Requestor Title</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.RequestorTitle}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Business Contact</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.BusinessContact}}</span>
          </td>
        </tr>

        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Request Date</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.RequestDate| date:'MM/dd/yyyy'}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Request Status</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.RequestStatus}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Billing Status</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.BillingStatus}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Need By Date</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.NeedByDate| date:'MM/dd/yyyy'}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">First Preparer</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.FirstPreparer}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Second Preparer</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.SecondPreparer}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Third Preparer</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.ThirdPreparer}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Alloted Date</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.AllotedDate| date:'MM/dd/yyyy'}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Comments for Workflow</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.CommentsForWorkflow}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">ID Number</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.IDNumber}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">FRRD</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.FRRD}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">FRCD</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.FRCD}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">SRCD</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.SRCD}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Delivery Date</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.DeliveryDate| date:'MM/dd/yyyy'}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">XYZ Code</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.XYZCode}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Previous Period Review W/P Available</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{'Previous Period Review W/P Available'}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Emphasis of Matter</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.EmphasisOfMatter}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Opinion Date</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.OpinionDate}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">#of rqsts received for prior period review</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.NumberOfRequestsReceivedForPriorPeriodReview}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Report Length</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.ReportLength}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Publish?</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.Publish}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Date/ Time of Last Action</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.DateTimeOfLastAction}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Request Priority</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.RequestPriority}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Report Period</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.ReportPeriod}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Cost</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.Cost}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">WBS#</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.WBS}}</span>
          </td>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">IsWBSLevelUpdated</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.IsWBSLevelUpdated}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">MF Engagement Name</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.MFEngagementName}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">MF Partner Name</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.MFPartnerName}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">MF WBS Name</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>{{data.MFWBSName}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap="true" valign="top" width="113px" class="ms-formlabel">
            <span class="ms-h3 ms-standardheader">Attachments</span>
          </td>
          <td valign="top" class="ms-formbody" width="350px">

            <span>
              <div *ngFor="let file of selectedFiles; let i = index">
                {{file.name}}

              </div>
            </span>
          </td>
        </tr>
      </table>
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <span class="ms-formbody">
          Content Type: {{data.ContentType}}<br />
          Created at {{data.Created | date:'MM/dd/yyyy, h:mm a'}} by {{data.CreatedBy}}<br />
          Last modified at {{data.Modified | date:'MM/dd/yyyy, h:mm a'}} by {{data.ModifiedBy}}
        </span>
        <div mat-dialog-actions>
          <button mat-button mat-dialog-close class="ms-button" (click)="onCloseClick()" style="color: #FFFFFF;" >Close</button>
        </div>
      </div>

    </div>
  </div>
</div>

