<h1 mat-dialog-title style="text-align:center;">{{ data.isEdit ? 'Edit Item' : 'Add New GlobalMF WBSCodes' }}</h1>
<mat-dialog-content>
  <form [formGroup]="formGroup">
    <div>
      <div class="form-group">
        <label>Title <span class="required">*</span>:</label>
        <input type="text" formControlName="title" class="small-input">
      </div>
      <div *ngIf="formGroup.controls['title'].invalid && formGroup.controls['title'].touched" class="error">Title is required</div>
      <br />
      <div class="form-group">
        <label>WBS Code <span class="required">*</span>:</label>
        <input type="text" formControlName="wbsCode" class="small-input">
      </div>
      <div *ngIf="formGroup.controls['wbsCode'].invalid && formGroup.controls['wbsCode'].touched" class="error">WBS Code is required</div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="align-end">
  <button mat-button mat-dialog-close (click)="onCancel()" class="ms-button" style="color: #FFFFFF;left: -29%">Cancel</button>
  <button mat-button *ngIf="!data.isEdit" [disabled]="formGroup.invalid|| isSubmit" (click)="onSave()" class="ms-button" style="color: #FFFFFF; left: -26%;">Save</button>
  <button mat-button *ngIf="data.isEdit" [disabled]="formGroup.invalid || isSubmit" (click)="onSave()" class="ms-button" style="color: #FFFFFF; left: -26%;" >Update</button>
</mat-dialog-actions>

<style>
  .align-end {
    display: flex;
    justify-content: flex-end;
  }

  .form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
  }

    .form-group label {
      margin-right: 10px;
      width: 100px;
    }

  .small-input {
    width: 200px;
  }
  .ms-button {
    min-width: 6em;
    padding: 7px 10px;
    border: 1px solid #ababab;
    /* background-color: #fdfdfd;
    background-color: #fdfdfd;*/
    background-color: #337AB7;
    border: 2px solid #337AB7;
    /*margin-left: 10px;*/
    font-family: "Segoe UI", Segoe, Tahoma, Helvetica, Arial, sans-serif;
    font-size: 11px;
  }

    .ms-button:hover {
      background-color: #286090;
      border-color: #204d74;
    }

  .error {
    color: red;
    font-size: 12px;
    margin-left: 110px;
    margin-top: -5px;
  }

  .required {
    color: red;
  }
</style>
