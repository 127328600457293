import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceTextWithHyphen'
})
export class ReplaceTextWithHiphenPipe implements PipeTransform {

  transform(value: any, repleceText: string = '-'): any {
    if (typeof value === 'undefined' || value === null || value=='null'||value=="NULL") {
      return repleceText;
    }
    if(value === ''){
      return repleceText;
    }
    return value;
  }
  

}
