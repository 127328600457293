import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../auth.details';
import { environment } from '../../environments/environment';
import { AppInsightsService } from '../app-insights.service';
import { Observable, of, from } from 'rxjs';
import { map, publish, startWith, switchMap } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AuthService } from '../auth.service';
import { WbsModalComponent } from '../wbs-modal/wbs-modal.component';
import { WBSRecords } from '../wbs-modal/wbs-records.interface';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { ClientModalComponent } from '../client-modal/client-modal.component';
import { ClientRecords } from '../client-modal/client-records.interface';
import { SpinnerServiceService } from '../services/spinner-service.service';
import { AlertPopupComponent } from '../notify-popup/notify-popup.component';
import { CommonHelperService } from '../services/common-helper.service';
import { ResponseType } from '@microsoft/microsoft-graph-client';

@Component({
  selector: 'app-admin-request-edit-form',
  templateUrl: './admin-request-edit-form.component.html',
  styleUrl: './admin-request-edit-form.component.css'
})
export class AdminRequestEditFormComponent implements OnInit {
  //_SARAuthoritativeGuidance: boolean = false;
  isSaving: boolean = false;
  checkClickedWithoutValueWBSEx = false;
  checkClickedWithoutValueDeloitteClientEx = false;
  form!: FormGroup;
  existingRequestForm!: FormGroup;
  newRequestForm!: FormGroup;
  id!: string;
  idNumber!: string;
  _isRequestorNameValidEx: boolean = false;
  _isBusinessNameValidEx: boolean = false;
  _isAuditNameValidEx: boolean = false;
  _isRequestorNameValidNew: boolean = false;
  _isBusinessNameValidNew: boolean = false;
  _isAuditNameValidNew: boolean = false;
  dateObjectValid:any={
    reportPeriodStartDate:false,
    reportPeriodEndDate:false,
    deliveryDate:false,
    deliveryDateEx:false,
    reqDate:false,
    needByDate:false,
    reqDateEx:false,
    needByDateEx:false,
    allottedDate:false,
    FRRD:false,
    FRCD:false,
    SRCD:false,
    optionalDate:false
  };
  showNoResults:boolean=false;
  sarReportType!: string;
  isAdmin!: boolean;
  validateWBSCode: boolean = false;
  selectedFiles: File[] = [];
  selectedFilesEx: File[] = [];
  users: { displayName: string, jobTitle: string,mail:string,department:string }[] = [];
  filteredUsersBussinessContact: Observable<{ displayName: string, jobTitle: string,mail:string }[]> = of([]);
  filteredUsersRequestors: Observable<{ displayName: string, jobTitle: string, mail: string, department: string }[]> = of([]);
  newfilteredUsersBussinessContact: Observable<{ displayName: string, jobTitle: string ,mail:string}[]> = of([]);
  newfilteredUsersRequestors: Observable<{ displayName: string, jobTitle: string, mail: string }[]> = of([]);
  filteredUsersfirstPreparer: Observable<{ displayName: string, jobTitle: string, mail: string }[]> = of([]);
  filteredUserssecondPreparer: Observable<{ displayName: string, jobTitle: string, mail: string }[]> = of([]);
  filteredUsersthirdPreparer: Observable<{ displayName: string, jobTitle: string, mail: string }[]> = of([]);
  filteredAuditSigningPartner: Observable<{ displayName: string, jobTitle: string, mail: string }[]> = of([]);
  newfilteredAuditSigningPartner: Observable<{ displayName: string, jobTitle: string, mail: string }[]> = of([]);
  checkClickedWithoutValue = false;

  created:string ="";
  createdBy: string = "";
  createdByName: string = "";

  autoCompleteUserSelected:any={
    requestorsUserSelected:false,
    requestorsExUserSelected:false,
    businessContactUserSelected:false,
    businessContactExUserSelected:false,
    auditSigningUserSelected:false,
    auditSigningExUserSelected:false,
    firstPreparerUserSelected:false,
    secondPreparerUserSelected:false,
    thirdPreparerUserSelected:false
  };
  responseData: any;
  showForm: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, public dialogRef: MatDialogRef<AdminRequestEditFormComponent>,
    private appInsightsService: AppInsightsService, @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder,
    private http: HttpClient, private userService: UserService, private authService: AuthService,
    private dialog: MatDialog, private datePipe: DatePipe, private spinnerService: SpinnerServiceService,private commonHelperService:CommonHelperService) { }

  ngOnInit() {
    this.appInsightsService.logPageView('AdminRequestEditFormComponent');
    this._isRequestorNameValidEx = false;
    this._isBusinessNameValidEx = false;
    this._isAuditNameValidEx = false;
    this._isRequestorNameValidNew = false;
    this._isBusinessNameValidNew = false;
    this._isAuditNameValidNew = false;
    this.isAdmin = this.userService.isAdmin;
    this.form = this.formBuilder.group({
      contentType: [this.data.ContentType]
    });

    this.getRequestDataById(this.data.ID).subscribe((responseData: any) => {
      this.responseData = responseData; // Store the response data in a separate variable
      this.showForm = true;
      // Initialize forms based on contentType
      if (this.form.get('contentType')?.value === 'Existing Request') {
        this.initializeExistingRequestForm();
        this.applyPeoplepickerConditionsForExistingRequest();
        this.initializeFilteredUsersForExistingRequest();
      } else if (this.form.get('contentType')?.value === 'New Request') {
        this.initializeNewRequestForm();
        this.setSARAuthoritativeGuidanceValues();
        this.applyPeoplepickerConditionsForNewRequest();
        this.initializeFilteredUsersForNewRequest();
      }
      this.form.get('contentType')?.valueChanges.subscribe(value => {
        if (value === 'New Request') {
          this.initializeNewRequestForm();
          this.applyPeoplepickerConditionsForNewRequest();
          this.initializeFilteredUsersForNewRequest();
        } else if (value === 'Existing Request') {
          this.initializeExistingRequestForm();
          this.applyPeoplepickerConditionsForExistingRequest();
          this.initializeFilteredUsersForExistingRequest();
        }
      });

      this.fetchFiles();

      this.created = responseData.Created;
      this.createdBy = responseData.CreatedBy;
      this.createdByName = responseData.CreatedByName;

      this.authService.getUsers().then((users: any[]) => {
        this.users = users.map((user: any) => ({ displayName: user.displayName, jobTitle: user.jobTitle, mail: user.mail, department: user.department }));
        if (this.form.get('contentType')?.value === 'Existing Request') {
          this.initializeFilteredUsersForExistingRequest();
        } else if (this.form.get('contentType')?.value === 'New Request') {
          this.initializeFilteredUsersForNewRequest();
        }
      });
    });
  }
  setSARAuthoritativeGuidanceValues() {
    if (this.responseData.SARAuthoritativeGuidance == 'SSAE 18'
      || this.responseData.SARAuthoritativeGuidance == 'CSAE 3416'
      || this.responseData.SARAuthoritativeGuidance == 'ISAE 3402'
      || this.responseData.SARAuthoritativeGuidance == 'SOC 2') {
      this.newRequestForm.patchValue({
        SARAuthoritativeGuidanceChecked: 'DropDown',
        SARAuthoritativeGuidanceDropDown: [this.responseData.SARAuthoritativeGuidance]
      });
    }
    else
    {      
      this.newRequestForm.patchValue({
        SARAuthoritativeGuidanceChecked: 'Text',
        SARAuthoritativeGuidanceText: [this.responseData.SARAuthoritativeGuidance && this.responseData.SARAuthoritativeGuidance.toString().toLowerCase() !== 'null' ? this.responseData.SARAuthoritativeGuidance : '']
      });
      this.newRequestForm.controls['SARAuthoritativeGuidanceText'].setValidators([Validators.required]);
      this.newRequestForm.controls['SARAuthoritativeGuidanceText'].updateValueAndValidity();
    }
  }
  initializeExistingRequestForm() {
    this.existingRequestForm = this.formBuilder.group({
      idNumberEx: [this.responseData.IDNumber && this.responseData.IDNumber.toString().toLowerCase() !== 'null' ? this.responseData.IDNumber : '', Validators.required],
      businessContactEx: [this.responseData.BusinessContact && this.responseData.BusinessContact.toString().toLowerCase() !== 'null' ? this.responseData.BusinessContact : '', Validators.required],
      businessContactExEmail: [{ value: this.responseData.BusinessContactEmail && this.responseData.BusinessContactEmail.toString().toLowerCase() !== 'null' ? this.responseData.BusinessContactEmail : '', disabled: true }],
      needByDateEx: [this.formatDate(this.responseData.NeedByDate), Validators.required],
      requestorsNameEx: [this.responseData.RequestorsName && this.responseData.RequestorsName.toString().toLowerCase() !== 'null' ? this.responseData.RequestorsName : '', Validators.required],
      requestorExEmail: [{value:this.responseData.RequestorEmail && this.responseData.RequestorEmail.toString().toLowerCase() !== 'null' ? this.responseData.RequestorEmail : '',disabled:true}],
      typeOfAuditEx: [this.responseData.TypeOfAudit && this.responseData.TypeOfAudit.toString().toLowerCase() !== 'null' ? this.responseData.TypeOfAudit : ''],
      titleEx: [this.responseData.Title && this.responseData.Title.toString().toLowerCase() !== 'null' ? this.responseData.Title : 'View Details', Validators.required],
      billingStatusEx: [this.responseData.BillingStatus && this.responseData.BillingStatus.toString().toLowerCase() !== 'null' ? this.responseData.BillingStatus : ''],
      requestDateEx: [this.formatDate(this.responseData.RequestDate), Validators.required],
      sarReportTypeEx: [this.responseData.SARReportType && this.responseData.SARReportType.toString().toLowerCase() !== 'null' ? this.responseData.SARReportType : ''],
      WBSEx: [this.responseData.WBS && this.responseData.WBS.toString().toLowerCase() !== 'null' ? this.responseData.WBS : '', Validators.required],
      deloitteClientEx: [this.responseData.ServiceOrganizationDeloitteClient && this.responseData.ServiceOrganizationDeloitteClient.toString().toLowerCase() !== 'null' ? this.responseData.ServiceOrganizationDeloitteClient : ''],
      deliveryDateEx: [this.formatDate(this.responseData.DeliveryDate)],
      mfEngagementNameEx: [this.responseData.MFEngagementName && this.responseData.MFEngagementName.toString().toLowerCase() !== 'null' ? this.responseData.MFEngagementName : ''],
      mfPartnerNameEx: [this.responseData.MFPartnerName && this.responseData.MFPartnerName.toString().toLowerCase() !== 'null' ? this.responseData.MFPartnerName : ''],
      mfWBSCodeEx: [this.responseData.MFWBSCode && this.responseData.MFWBSCode.toString().toLowerCase() !== 'null' ? this.responseData.MFWBSCode : ''],
      AuditSigningPartnerEx: [this.responseData.AuditSigningPartner && this.responseData.AuditSigningPartner.toString().toLowerCase() !== 'null' ? this.responseData.AuditSigningPartner : '', Validators.required],
      AuditSigningPartnerExEmail: [{ value: this.responseData.AuditSigningPartnerEmail && this.responseData.AuditSigningPartnerEmail.toString().toLowerCase() !== 'null' ? this.responseData.AuditSigningPartnerEmail : '',disabled:true }],
      requestorTitleEx: [{ value: this.responseData.RequestorTitle && this.responseData.RequestorTitle.toString().toLowerCase() !== 'null' ? this.responseData.RequestorTitle : '' ,disabled:true}],
      requestorDepartmentEx: [{ value: this.responseData.RequestorDepartment && this.responseData.RequestorDepartment.toString().toLowerCase() !== 'null' ? this.responseData.RequestorDepartment : '' ,disabled:true}],
    });
  }

  initializeNewRequestForm() {    
    this.newRequestForm = this.formBuilder.group({
      VerifyServiceOrganization: [this.responseData.VerifyServiceOrganization === 'Yes' || this.responseData.VerifyServiceOrganization === true],
      ServiceOrganization: [this.responseData.ServiceOrganization && this.responseData.ServiceOrganization.toString().toLowerCase() !== 'null' ? this.responseData.ServiceOrganization : '', Validators.pattern('.*\\S.*')],
      ServiceSector: [this.responseData.ServiceSector && this.responseData.ServiceSector.toString().toLowerCase() !== 'null' ? this.responseData.ServiceSector : '', Validators.pattern('.*\\S.*')],
      ServiceAuditor: [this.responseData.ServiceAuditor && this.responseData.ServiceAuditor.toString().toLowerCase() !== 'null' ? this.responseData.ServiceAuditor : '', Validators.pattern('.*\\S.*')],
      Application: [this.responseData.Application && this.responseData.Application.toString().toLowerCase() !== 'null' ? this.responseData.Application : '', Validators.pattern('.*\\S.*')],
      ExclusiveToClient: [this.responseData.ExclusiveToClient === 'Yes' || this.responseData.ExclusiveToClient === true],
      TypeOfAudit: [this.responseData.TypeOfAudit],
      LocationsCoveredInReport: [this.responseData.LocationsCoveredInReport && this.responseData.LocationsCoveredInReport.toString().toLowerCase() !== 'null' ? this.responseData.LocationsCoveredInReport : ''],
      TypeOfOpinion: [this.responseData.TypeOfOpinion],
      ReportPeriodStartDate: [this.formatDate(this.responseData.ReportPeriodStartDate)],
      ReportPeriodEndDate: [this.formatDate(this.responseData.ReportPeriodEndDate), Validators.required],
      SARAuthoritativeGuidance: new FormControl('', Validators.required),
      SARAuthoritativeGuidanceChecked: [''],
      SARAuthoritativeGuidanceDropDown: ['SSAE 18'],
      SARAuthoritativeGuidanceText: ['', Validators.required],
      SARReportType: [this.responseData.SARReportType],
      RequestorsName: [this.responseData.RequestorsName && this.responseData.RequestorsName.toString().toLowerCase() !== 'null' ? this.responseData.RequestorsName : '', Validators.compose([Validators.required])],
      RequestorEmail: [{ value: this.responseData.RequestorEmail && this.responseData.RequestorEmail.toString().toLowerCase() !== 'null' ? this.responseData.RequestorEmail : '',disabled:true }],
      RequestorsNameLotusNotes: [this.responseData.RequestorsNameLotusNotes && this.responseData.RequestorsNameLotusNotes.toString().toLowerCase() !== 'null' ? this.responseData.RequestorsNameLotusNotes : ''],
      RequestorDepartment: [{ value:this.responseData.RequestorDepartment && this.responseData.RequestorDepartment.toString().toLowerCase() !== 'null' ? this.responseData.RequestorDepartment : '',disabled:true }],
      RequestorTitle: [{ value:this.responseData.RequestorTitle && this.responseData.RequestorTitle.toString().toLowerCase() !== 'null' ? this.responseData.RequestorTitle : '',disabled:true }],
      BusinessContact: [this.responseData.BusinessContact && this.responseData.BusinessContact.toString().toLowerCase() !== 'null' ? this.responseData.BusinessContact : '', Validators.compose([Validators.required])],
      BusinessContactEmail: [{ value: this.responseData.BusinessContactEmail && this.responseData.BusinessContactEmail.toString().toLowerCase() !== 'null' ? this.responseData.BusinessContactEmail : '',disabled:true }],
      RequestDate: [this.formatDate(this.responseData.RequestDate), Validators.required],
      RequestStatus: [this.responseData.RequestStatus],
      BillingStatus: [this.responseData.BillingStatus],
      NeedByDate: [this.formatDate(this.responseData.NeedByDate), Validators.required],
      FirstPreparer: [this.responseData.FirstPreparer && this.responseData.FirstPreparer.toString().toLowerCase() !== 'null' ? this.responseData.FirstPreparer : ''],
      FirstPreparerEmail: [this.responseData.FirstPreparerEmail],
      SecondPreparer: [this.responseData.SecondPreparer && this.responseData.SecondPreparer.toString().toLowerCase() !== 'null' ? this.responseData.SecondPreparer : ''],
      SecondPreparerEmail: [this.responseData.SecondPreparerEmail],
      ThirdPreparer: [this.responseData.ThirdPreparer && this.responseData.ThirdPreparer.toString().toLowerCase() !== 'null' ? this.responseData.ThirdPreparer : ''],
      ThirdPreparerEmail: [this.responseData.ThirdPreparerEmail],
      AllottedDate: [this.formatDate(this.responseData.AllottedDate)],
      CommentsForWorkflow: [this.responseData.CommentsForWorkflow && this.responseData.CommentsForWorkflow.toString().toLowerCase() !== 'null' ? this.responseData.CommentsForWorkflow : ''],
      IDNumber: [this.responseData.IDNumber && this.responseData.IDNumber.toString().toLowerCase() !== 'null' ? this.responseData.IDNumber : ''],
      FRRD: [this.formatDate(this.responseData.FRRD)],
      FRCD: [this.formatDate(this.responseData.FRCD)],
      SRCD: [this.formatDate(this.responseData.SRCD)],
      DeliveryDate: [this.formatDate(this.responseData.DeliveryDate)],
      XYZCode: [this.responseData.XYZCode && this.responseData.XYZCode.toString().toLowerCase() !== 'null' ? this.responseData.XYZCode : ''],
      PreviousPeriodReviewAvailable: [this.responseData.PreviousPeriodReviewAvailable === 'Yes' || this.responseData.PreviousPeriodReviewAvailable === true],
      EmphasisOfMatter: [this.responseData.EmphasisOfMatter],
      OpinionDate: [this.formatDate(this.responseData.OpinionDate)],
      NumberOfRequestsReceivedForPriorPeriodReview: [this.responseData.NumberOfRequestsReceivedForPriorPeriodReview && this.responseData.NumberOfRequestsReceivedForPriorPeriodReview.toString().toLowerCase() !== 'null' ? this.responseData.NumberOfRequestsReceivedForPriorPeriodReview : ''],
      ReportLength: [this.responseData.ReportLength && this.responseData.ReportLength.toString().toLowerCase() !== 'null' ? this.responseData.ReportLength : ''],
      Publish: [this.responseData.Publish === 'true' || this.responseData.Publish === true],
      DateTimeOfLastAction: [this.formatDateTime(this.responseData.DateTimeOfLastAction)],
      Cost: [this.responseData.Cost != null && this.responseData.Cost.toString().toLowerCase() !== 'null' ? this.responseData.Cost : 500],
      WBS: [this.responseData.WBS, Validators.required],
      ServiceOrganizationDeloitteClient: [this.responseData.ServiceOrganizationDeloitteClient && this.responseData.ServiceOrganizationDeloitteClient.toString().toLowerCase() !== 'null' ? this.responseData.ServiceOrganizationDeloitteClient : ''],
      MFEngagementName: [this.responseData.MFEngagementName && this.responseData.MFEngagementName.toString().toLowerCase() !== 'null' ? this.responseData.MFEngagementName : ''],
      MFPartnerName: [this.responseData.MFPartnerName && this.responseData.MFPartnerName.toString().toLowerCase() !== 'null' ? this.responseData.MFPartnerName : ''],
      MFWBSCode: [this.responseData.MFWBSCode && this.responseData.MFWBSCode.toString().toLowerCase() !== 'null' ? this.responseData.MFWBSCode : ''],
      ContentType: [this.responseData.ContentType],
      AuditSigningPartner: [this.responseData.AuditSigningPartner && this.responseData.AuditSigningPartner.toString().toLowerCase() !== 'null' ? this.responseData.AuditSigningPartner : '', Validators.compose([Validators.required])],
      AuditSigningPartnerEmail: [{ value: this.responseData.AuditSigningPartnerEmail && this.responseData.AuditSigningPartnerEmail.toString().toLowerCase() !== 'null' ? this.responseData.AuditSigningPartnerEmail : '' ,disabled:true}]
    });
  }


  getRequestDataById(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/RequestList/GetRequestById/${id}`);
  }

  applyPeoplepickerConditionsForNewRequest() {
    if (this.newRequestForm.controls['RequestorsName'].value) {
      this.autoCompleteUserSelected.requestorsUserSelected = true;
    }
    if (this.newRequestForm.controls['BusinessContact'].value) {
      this.autoCompleteUserSelected.businessContactUserSelected = true;
    }
    if (this.newRequestForm.controls['AuditSigningPartner'].value) {
      this.autoCompleteUserSelected.auditSigningUserSelected = true;
    }
    if (this.newRequestForm.controls['FirstPreparer'].value) {
      this.autoCompleteUserSelected.firstPreparerUserSelected = true;
    }
    if (this.newRequestForm.controls['SecondPreparer'].value) {
      this.autoCompleteUserSelected.secondPreparerUserSelected = true;
    }
    if (this.newRequestForm.controls['ThirdPreparer'].value) {
      this.autoCompleteUserSelected.thirdPreparerUserSelected = true;
    }
  }

  applyPeoplepickerConditionsForExistingRequest() {
    if (this.existingRequestForm.controls['requestorsNameEx'].value) {
      this.autoCompleteUserSelected.requestorsExUserSelected = true;
    }
    if (this.existingRequestForm.controls['businessContactEx'].value) {
      this.autoCompleteUserSelected.businessContactExUserSelected = true;
    }
    if (this.existingRequestForm.controls['AuditSigningPartnerEx'].value) {
      this.autoCompleteUserSelected.auditSigningExUserSelected = true;
    }
  }
  initializeFilteredUsersForExistingRequest() {
    this.authService.getUsers().then((users: any[]) => {
      this.users = users.map((user: any) => ({ displayName: user.displayName, jobTitle: user.jobTitle,mail:user.mail, department: user.department }));
      this.filteredUsersBussinessContact = this.existingRequestForm.controls['businessContactEx'].valueChanges.pipe(
        startWith(''),
        switchMap((value: string | null) => this._filter(value || ''))
      );
      this.filteredUsersRequestors = this.existingRequestForm.controls['requestorsNameEx'].valueChanges.pipe(
        startWith(''),
        switchMap((value: string | null) => this._filter(value || ''))
      );
      this.filteredAuditSigningPartner = this.existingRequestForm.controls['AuditSigningPartnerEx'].valueChanges.pipe(
        startWith(''),
        switchMap((value: string | null) => this._filter(value || ''))
      );
    });
  }
  initializeFilteredUsersForNewRequest() {
    this.authService.getUsers().then((users: any[]) => {
      this.users = users.map((user: any) => ({ displayName: user.displayName, jobTitle: user.jobTitle, mail: user.mail, department: user.department }));
      this.newfilteredUsersBussinessContact = this.newRequestForm.controls['BusinessContact'].valueChanges.pipe(
        startWith(''),
        switchMap((value: string | null) => this._filter(value || ''))
      );
      this.newfilteredUsersRequestors = this.newRequestForm.controls['RequestorsName'].valueChanges.pipe(
        startWith(''),
        switchMap((value: string | null) => this._filter(value || ''))
      );
      this.filteredUsersfirstPreparer = this.newRequestForm.controls['FirstPreparer'].valueChanges.pipe(
        startWith(''),
        switchMap((value: string | null) => this._filter(value || ''))
      );
      this.filteredUserssecondPreparer = this.newRequestForm.controls['SecondPreparer'].valueChanges.pipe(
        startWith(''),
        switchMap((value: string | null) => this._filter(value || ''))
      );
      this.filteredUsersthirdPreparer = this.newRequestForm.controls['ThirdPreparer'].valueChanges.pipe(
        startWith(''),
        switchMap((value: string | null) => this._filter(value || ''))
      );
      this.newfilteredAuditSigningPartner = this.newRequestForm.controls['AuditSigningPartner'].valueChanges.pipe(
        startWith(''),
        switchMap((value: string | null) => this._filter(value || ''))
      );
    });
  }

  formatDate(date: string): string {
    if (date && Date.parse(date)) {
      return this.datePipe.transform(date, 'yyyy-MM-dd') || '';
    } else {
      return '';
    }
  }

  formatDateTime(dateTime: any): string {
    if (dateTime && Date.parse(dateTime)) {
      return this.datePipe.transform(dateTime, 'yyyy-MM-ddTHH:mm') || '';
    } else {
      return '';
    }
  }

  private _filter(value: string): Observable<any[]> {
    this.showNoResults=false;
    if (value.length >= 3) { // Only search if the user has typed at least 3 characters
      return from(this.authService.searchUsers(value)).pipe(
        switchMap((users: any[]) => {
          if (users.length === 0) {
            this.showNoResults=true;
          }
          return of(users.map((user: any) => ({ displayName: user.displayName, jobTitle: user.jobTitle,mail:user.mail, department: user.department })));
        })
      );
    } else {
      // If the user hasn't typed enough characters, return an empty array
      return of([]);
    }
  }
  setUserSelectionDynamic(field:string,action:string){
    if(field=="businessContactEx"){
      this.autoCompleteUserSelected.businessContactExUserSelected=action=="remove"?false:true;
    }
    else if(field=="requestorsNameEx"){
      this.autoCompleteUserSelected.requestorsExUserSelected=action=="remove"?false:true;
    }
    else if(field=="AuditSigningPartnerEx"){
      this.autoCompleteUserSelected.auditSigningExUserSelected=action=="remove"?false:true;
    }
    else if(field=="RequestorsName"){
      this.autoCompleteUserSelected.requestorsUserSelected=action=="remove"?false:true;
    }
    else if(field=="AuditSigningPartner"){
      this.autoCompleteUserSelected.auditSigningUserSelected=action=="remove"?false:true;
    }
    else if(field=="BusinessContact"){
      this.autoCompleteUserSelected.businessContactUserSelected=action=="remove"?false:true;
    }
    else if(field=="FirstPreparer"){
      this.autoCompleteUserSelected.firstPreparerUserSelected=action=="remove"?false:true;
    }
    else if(field=="SecondPreparer"){
      this.autoCompleteUserSelected.secondPreparerUserSelected=action=="remove"?false:true;
    }
    else if(field=="ThirdPreparer"){
      this.autoCompleteUserSelected.thirdPreparerUserSelected=action=="remove"?false:true;
    }
  }
  onOptionSelectedExistingRequestEx(event: MatAutocompleteSelectedEvent, field: string): void {
    const selectedUser = event.option.value;
    this.setUserSelectionDynamic(field, "add");
    // this.form.controls[field as keyof typeof this.form.controls].setValue(displayName); Old Logic
    this.existingRequestForm.controls[field as keyof typeof this.existingRequestForm.controls].setValue(selectedUser.displayName);
    this.existingRequestForm.controls[field as keyof typeof this.existingRequestForm.controls].setErrors(null);
    //saving the mail also for the below given fields
    if (field === 'requestorsNameEx') {
      this.existingRequestForm.controls['requestorTitleEx'].setValue(selectedUser.jobTitle);
      this.existingRequestForm.controls['requestorDepartmentEx'].setValue(selectedUser.department);
      this.existingRequestForm.controls['requestorExEmail'].setValue(selectedUser.mail);
      this._isRequestorNameValidEx = true;
    } else if (field === 'businessContactEx') {
      this.existingRequestForm.controls['businessContactExEmail'].setValue(selectedUser.mail);
      this._isBusinessNameValidEx = true;
    } else if (field === 'AuditSigningPartnerEx') {
      this.existingRequestForm.controls['AuditSigningPartnerExEmail'].setValue(selectedUser.mail);
      this._isAuditNameValidEx = true;
    }
  }
  onOptionSelectedExistingRequest(event: MatAutocompleteSelectedEvent, field: string): void {
    const displayName = event.option.value;
    this.setUserSelectionDynamic(field,"add"); 
    // this.form.controls[field as keyof typeof this.form.controls].setValue(displayName); Old Logic
      this.existingRequestForm.controls[field as keyof typeof this.existingRequestForm.controls].setValue(displayName);
    this.existingRequestForm.controls[field as keyof typeof this.existingRequestForm.controls].setErrors(null);
  }
  onOptionSelectedNewRequest(event: MatAutocompleteSelectedEvent, field: string): void {
    const selectedUser = event.option.value;
    this.setUserSelectionDynamic(field, "add");
    // this.form.controls[field as keyof typeof this.form.controls].setValue(displayName); Old Logic
    this.newRequestForm.controls[field as keyof typeof this.newRequestForm.controls].setValue(selectedUser.displayName);
    this.newRequestForm.controls[field as keyof typeof this.newRequestForm.controls].setErrors(null);
    if (field === "RequestorsName") {
      this.newRequestForm.controls['RequestorEmail'].setValue(selectedUser.mail);
      this.newRequestForm.controls['RequestorTitle'].setValue(selectedUser.jobTitle);
      this.newRequestForm.controls['RequestorDepartment'].setValue(selectedUser.department);
      this._isRequestorNameValidNew = true;
    }
    else if (field === 'AuditSigningPartner')
    {
      this._isAuditNameValidNew = true;
    }
    else if (field === 'BusinessContact')
    {
      this._isBusinessNameValidNew = true;
    }
  } 

  onOptionSelectedNewRequestEx(event: MatAutocompleteSelectedEvent, field: string): void {
    const selectedUser = event.option.value;
    this.setUserSelectionDynamic(field, "add");
    if (field === 'AuditSigningPartner') {
      this._isAuditNameValidNew = true;
    }
    else if (field === 'BusinessContact') {
      this._isBusinessNameValidNew = true;
    }
    // this.form.controls[field as keyof typeof this.form.controls].setValue(displayName); Old Logic
    this.newRequestForm.controls[field as keyof typeof this.newRequestForm.controls].setValue(selectedUser.displayName);
    this.newRequestForm.controls[field as keyof typeof this.newRequestForm.controls].setErrors(null);
    //saving the mail also for the below given fields
    if (field === 'FirstPreparer' || field === 'SecondPreparer' || field === 'ThirdPreparer' || field === 'BusinessContact' || field === 'AuditSigningPartner') {
      if (selectedUser) {
        const emailField = field + 'Email';
        this.newRequestForm.controls[emailField].setValue(selectedUser.mail);
      }
    }
  }
  removeSelectedUserNewRequest(field: string) {
    this.setUserSelectionDynamic(field, "remove");
    if (field === 'RequestorsName') {
      this.newRequestForm.controls['RequestorsName'].setValue(null);
      this.newRequestForm.controls['RequestorEmail'].setValue(null);
      this._isRequestorNameValidNew = false;
      this.newRequestForm.get('RequestorsName')?.markAsTouched();
    } else if (field === 'BusinessContact') {
      this.newRequestForm.controls['BusinessContact'].setValue(null);
      this.newRequestForm.controls['BusinessContactEmail'].setValue(null);
      this._isBusinessNameValidNew = false;
      this.newRequestForm.get('BusinessContact')?.markAsTouched();
    } else if (field === 'AuditSigningPartner') {
      this.newRequestForm.controls['AuditSigningPartner'].setValue(null);
      this.newRequestForm.controls['AuditSigningPartnerEmail'].setValue(null);
      this._isAuditNameValidNew = false;
      this.newRequestForm.get('AuditSigningPartner')?.markAsTouched();
    }
    this.newRequestForm.controls[field as keyof typeof this.newRequestForm.controls].setValue(null);
  }
  removeSelectedUserExistingRequest(field: string) {
    this.setUserSelectionDynamic(field, "remove");
    if (field === 'requestorsNameEx') {
      this.existingRequestForm.controls['requestorsNameEx'].setValue(null);
      this.existingRequestForm.controls['requestorExEmail'].setValue(null);
      this._isRequestorNameValidEx = false;
      this.existingRequestForm.get('requestorsNameEx')?.markAsTouched();
    } else if (field === 'businessContactEx') {
      this.existingRequestForm.controls['businessContactEx'].setValue(null);
      this.existingRequestForm.controls['businessContactExEmail'].setValue(null);
      this._isBusinessNameValidEx = false;
      this.existingRequestForm.get('businessContactEx')?.markAsTouched();
    } else if (field === 'AuditSigningPartnerEx') {
      this.existingRequestForm.controls['AuditSigningPartnerEx'].setValue(null);
      this.existingRequestForm.controls['AuditSigningPartnerExEmail'].setValue(null);
      this._isAuditNameValidEx = false;
      this.existingRequestForm.get('AuditSigningPartnerEx')?.markAsTouched();
    }
    this.existingRequestForm.controls[field as keyof typeof this.existingRequestForm.controls].setValue(null);
  }

  onMouseLeave() {
    const wbsControl = this.existingRequestForm.controls['WBSEx']
    if (wbsControl && wbsControl.value && wbsControl.value.trim() != '') {

      this.isWbsExists(wbsControl.value,'existingrequest');
    }
  }
  onMouseLeavenew() {
    const wbsControl = this.newRequestForm.controls['WBS']
    if (wbsControl && wbsControl.value && wbsControl.value.trim() != '') {

      this.isWbsExists(wbsControl.value,'newrequest');
    }
  }

  isWbsExists(wbscode: string,formtype:string) {
    this.spinnerService.showSpinner();
    this.http.get(environment.baseUrl + '/GlobalMFWBSCodes/IsWBSCodeExist?WBSCode=' + wbscode, { responseType: 'text' })
      .subscribe(
        response => {
          console.log(response);
          this.spinnerService.hideSpinner();
          if (response != null) {
            if (formtype === 'newrequest') {
              this.newRequestForm.controls['WBS'].setErrors(null);
            } else if (formtype === 'existingrequest') {
              this.existingRequestForm.controls['WBSEx'].setErrors(null);
            }
            if (response.toLowerCase() === "global wbs code already exists") {
             // this.isGlobalwbscode = true;
             // this.validateWBSCode = false;

            }
          } else {
            this.validateWBSCode = true;
            if (formtype === 'newrequest') {
              this.newRequestForm.controls['WBS'].setErrors({ 'incorrect': true });
            } else if (formtype ==='existingrequest') {
              this.existingRequestForm.controls['WBSEx'].setErrors({ 'incorrect': true });
            }
          }
        },
        error => {
          // Handle the error here
          console.error(error);
        }
      );


  }

  onCloseClick(): void {
    this.dialogRef.close();
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }

  @ViewChild('fileInput') fileInput: ElementRef | undefined;


  saveExistingRequestChanges(): void {

    if (this.isSaving) {
      return; // Prevent multiple submissions
    }

    this.isSaving = true; // Disable the save button

    this.validateWBSCode = false;
    var requestormail: any;
    if (this.existingRequestForm.controls['WBSEx'].value.trim() == "") {
      this.existingRequestForm.controls['WBSEx'].setErrors({ required: true });
    }
    if (!this.existingRequestForm.controls['requestorExEmail'].value)
    {
      this.existingRequestForm.get('requestorsNameEx')?.setErrors({ required: true });     
    }
    if (!this.existingRequestForm.controls['businessContactExEmail'].value) {
      this.existingRequestForm.get('businessContactEx')?.setErrors({ required: true });
    }
    if (!this.existingRequestForm.controls['AuditSigningPartnerExEmail'].value) {
      this.existingRequestForm.get('AuditSigningPartnerEx')?.setErrors({ required: true });
    }
    this.spinnerService.showSpinner(); // show spinner
    // Check if any file is attached
    //if (this.selectedFilesEx.length === 0) {
    //  this.openAlertDialog('Warning', 'This request cannot be submitted without the report attached.');
    //  this.spinnerService.hideSpinner(); // Hide spinner if no file is attached
    //  return;
    //}    
    if (this.existingRequestForm.valid && this.isDatesValidOnExistingForm()) {
      let requestPriority: any = this.commonHelperService.GetRequestPriorityData(this.existingRequestForm.value.requestDateEx, this.existingRequestForm.value.deliveryDateEx);
      const formValues: { [key: string]: any } = {
        IDNumber: this.existingRequestForm.value.idNumberEx,
        BusinessContact: this.existingRequestForm.value.businessContactEx,
        NeedByDate: this.existingRequestForm.value.needByDateEx,
        RequestorsName: this.existingRequestForm.value.requestorsNameEx,
        RequestorEmail: this.existingRequestForm.controls['requestorExEmail'].value,
        TypeOfAudit: this.existingRequestForm.value.typeOfAuditEx,
        Title: this.existingRequestForm.value.titleEx,
        RequestDate: this.existingRequestForm.value.requestDateEx,
        DeliveryDate: this.existingRequestForm.value.deliveryDateEx,
        SARReportType: this.existingRequestForm.value.sarReportTypeEx,
        WBS: this.existingRequestForm.value.WBSEx,
        ServiceOrganizationDeloitteClient: this.existingRequestForm.value.deloitteClientEx,
        MFEngagementName: this.existingRequestForm.value.mfEngagementNameEx,
        MFPartnerName: this.existingRequestForm.value.mfPartnerNameEx,
        MFWBSCode: this.existingRequestForm.value.mfWBSCodeEx,
        BillingStatus: this.existingRequestForm.value.billingStatusEx,
        RequestPriority: requestPriority,
        AuditSigningPartner: this.existingRequestForm.value.AuditSigningPartnerEx,
        AuditSigningPartnerEmail: this.existingRequestForm.controls['AuditSigningPartnerExEmail'].value,
        BusinessContactEmail: this.existingRequestForm.controls['businessContactExEmail'].value,
        modifiedByName: this.userService.userName,
        RequestorTitle: this.existingRequestForm.controls['requestorTitleEx'].value,
        RequestorDepartment: this.existingRequestForm.controls['requestorDepartmentEx'].value

        
      };

      this._filter(this.existingRequestForm.controls['requestorsNameEx'].value).subscribe((users: any[]) => {
        //requestormail = users[0].mail;

        const formData = new FormData();
       // formData.append('RequestorEmail', requestormail);
        for (const key in formValues) {
          formData.append(key, formValues[key]);
        }

        // Append all the selected files to the form data
        //let isFileTooLargeEx = false;
        //this.selectedFilesEx.forEach((fileEx) => {
        //  const fileSizeMBEx = fileEx.size / (1024 * 1024);
        //  if (fileSizeMBEx > 4) {
        //    isFileTooLargeEx = true;
        //    return;
        //  }
        //  formData.append('files', fileEx);
        //});
        //if (isFileTooLargeEx) {
        //  this.openAlertDialog('Warning', 'File is too large. Maximum file size is 4MB.');
        //  this.spinnerService.hideSpinner(); // Hide spinner if file is too large
        //  return; // Don't post the form data if any file is too large
        //}
        // Submit the form
        
        this.updateAdminViewExistingRequestReviewList(formData, Number(this.data.ID), this.userService.userEmail).subscribe({
          next: response => {
            this.appInsightsService.logEvent("Request Form Save:", { Response: response });
            //alert('Data saved');
            this.spinnerService.hideSpinner(); // Hide spinner after data is saved
            location.reload();
          },
          error: error => {
            this.appInsightsService.logException(error);
            this.openAlertDialog('Warning', 'Failed to save data');
            this.spinnerService.hideSpinner(); // Hide spinner in case of error
          }
        });
       
      });
    } else {
      // Mark the form as touched to trigger the error message
      this.existingRequestForm.markAllAsTouched();
      this.isSaving = false; // Re-enable the save button
      if (this.existingRequestForm.controls['requestorExEmail'].value ) {
        this.existingRequestForm.get('requestorsNameEx')?.markAsUntouched();
      }
      if (this.existingRequestForm.controls['businessContactExEmail'].value ) {
        this.existingRequestForm.get('businessContactEx')?.markAsUntouched();
      }
      if (this.existingRequestForm.controls['AuditSigningPartnerExEmail'].value )
      {
        this.existingRequestForm.get('AuditSigningPartnerEx')?.markAsUntouched();
      }
      this.spinnerService.hideSpinner(); // Hide spinner if form is invalid
    }
  }

  openAlertDialog(title: string, message: string,msglength:string ="2"): void {
    this.dialog.open(AlertPopupComponent, {
      data: { title, message },
      width: '43%' ,
      height: msglength == "2" ? '30%' : msglength == "1" ? "35%" : '48%',
      disableClose: true
    });
  }
  //saveNewRequest(): void {
  //  var requestormail:any;
  //  // Before submitting
  //  if (this.newRequestForm.controls['SARAuthoritativeGuidanceChecked'].value == 'DropDown') {
  //    this.newRequestForm.controls['SARAuthoritativeGuidance'].setValue(this.newRequestForm.controls['SARAuthoritativeGuidanceDropDown'].value);
  //  } else if (this.newRequestForm.controls['SARAuthoritativeGuidanceChecked'].value == 'Text') {
  //    this.newRequestForm.controls['SARAuthoritativeGuidance'].setValue(this.newRequestForm.controls['SARAuthoritativeGuidanceText'].value);
  //  }
  //  this._filter(this.newRequestForm.controls['RequestorsName'].value).subscribe((users: any[]) => {
  //     requestormail = users[0].mail;
  //    console.log(users);
  //  });

  //  // Check if any file is attached
  //  if (this.selectedFiles.length === 0) {
  //    this.openAlertDialog('Warning', 'This request cannot be submitted without the report attached.');
  //    return;
  //  }

  //  // Show the note about the standard turnaround time
  //  //this.openAlertDialog('Warning', 'Please Note that the Standard Turnaround Time for New Request is Two weeks. While Need by Date is considered for scheduling resources, it is not a commitment from SAR Review Team. USI will also not be providing interim status updates on this. You may have to write to HYDSAS-70RA@deloitte.com separately for having a status update');
  //  //validate the form
  //  if (this.newRequestForm.valid) {
  //    const formData = new FormData();
  //    Object.keys(this.newRequestForm.controls).forEach(key => {
  //      const control = this.newRequestForm.get(key);
  //      if (control) {
  //        formData.append(key, key === 'Cost' && (control.value === null || control.value === undefined) ? '0' : control.value);
  //      }
  //    });
  //    // Append all the selected files to the form data
  //    let isFileTooLarge = false;
  //    this.selectedFiles.forEach((file) => {
  //      const fileSizeMB = file.size / (1024 * 1024);
  //      if (fileSizeMB > 4) {
  //        isFileTooLarge = true;
  //        return;
  //      }
  //      formData.append('files', file);
  //    });
  //    let reportPeriod:any= this.commonHelperService.GetReportPeriodData(this.newRequestForm.controls['ReportPeriodEndDate'].value);
  //    let requestPriority:any= this.commonHelperService.GetRequestPriorityData(this.newRequestForm.controls['RequestDate'].value,this.newRequestForm.controls['DeliveryDate'].value);
  //    formData.append('createdBy', this.userService.userEmail);
  //    formData.append('created', new Date().toISOString());
  //    formData.append('ReportPeriod', reportPeriod);
  //    formData.append('RequestPriority', requestPriority);
  //    formData.append('RequestorEmail', requestormail);
  //    // formData.append('ContentType', "New Request");

  //    if (isFileTooLarge) {
  //      this.openAlertDialog('Warning', 'File is too large. Maximum file size is 4MB.');
  //      return; // Don't post the form data if any file is too large
  //    }

  //    //publish value
  //    const publishValue: boolean = this.newRequestForm.get('Publish')?.value ?? false;
  //    let params = new HttpParams().set('publish', publishValue.toString());
  //    params = params.append('ID', Number(this.data.ID));

  //    // Submit the form
  //    this.http.post(environment.baseUrl + '/Admin/CreateMasterRecord', formData, { params })
  //      .subscribe(response => {
  //        this.appInsightsService.logEvent("Request Form Save:", { Response: response });
  //        // alert('Data saved');
  //        this.dialogRef.close();
  //        this.router.navigate(['/userRequestDashboard']);
  //      }, error => {
  //        this.appInsightsService.logException(error);
  //        this.openAlertDialog('Warning', 'Failed to save data');
  //      });
  //  } else {
  //    // Mark the form as touched to trigger the error message
  //    this.newRequestForm.markAllAsTouched();
  //  }
  //}

  saveNewRequest(): void {
    if (this.isSaving) {
      return; // Prevent multiple submissions
    }

    //trim "comments for workflow"" textfield
    const str = this.newRequestForm.get('CommentsForWorkflow')?.value;
    if (str && str.includes('\n')) {
      const result = str.replace(/\n/g, '');
      this.newRequestForm.get('CommentsForWorkflow')?.setValue(result);
    }

    this.isSaving = true; // Disable the save button
    this.newRequestForm.controls['SARAuthoritativeGuidanceText'].clearValidators();
    this.newRequestForm.controls['SARAuthoritativeGuidanceText'].updateValueAndValidity();
    // Before submitting
    this.validateWBSCode = false;
    if (this.newRequestForm.controls['WBS'].value.trim() == "") {
      this.newRequestForm.controls['WBS'].setErrors({ required: true });
    }
    if (!(this.newRequestForm.controls['Publish'].value == true && this.newRequestForm.controls['IDNumber'].value == '')) {
      if (this.newRequestForm.controls['SARAuthoritativeGuidanceChecked'].value == 'DropDown') {
        this.newRequestForm.controls['SARAuthoritativeGuidance'].setValue(this.newRequestForm.controls['SARAuthoritativeGuidanceDropDown'].value);
      } else if (this.newRequestForm.controls['SARAuthoritativeGuidanceChecked'].value == 'Text') {
        this.newRequestForm.controls['SARAuthoritativeGuidance'].setValue(this.newRequestForm.controls['SARAuthoritativeGuidanceText'].value);
      }

      //this._SARAuthoritativeGuidance = false;
      if (this.newRequestForm.controls['SARAuthoritativeGuidanceChecked'].value == 'Text' && this.newRequestForm.controls['SARAuthoritativeGuidanceText'].value == "") {
        //this._SARAuthoritativeGuidance = true;
        this.newRequestForm.controls['SARAuthoritativeGuidanceText'].setValidators([Validators.required]);
        this.newRequestForm.controls['SARAuthoritativeGuidanceText'].updateValueAndValidity();
      }
      if (this.newRequestForm.controls['SARAuthoritativeGuidanceChecked'].value == 'DropDown' && this.newRequestForm.controls['SARAuthoritativeGuidanceDropDown'].value == "") {
        //this._SARAuthoritativeGuidance = true;
        this.newRequestForm.controls['SARAuthoritativeGuidanceDropDown'].setValidators([Validators.required]);
        this.newRequestForm.controls['SARAuthoritativeGuidanceDropDown'].updateValueAndValidity();
      }
      //else {
      //  this.newRequestForm.controls['SARAuthoritativeGuidanceText'].clearValidators();
      //  this.newRequestForm.controls['SARAuthoritativeGuidanceText'].updateValueAndValidity();
      //}


      if (this.newRequestForm.controls['VerifyServiceOrganization'].value == true) {
        this.newRequestForm.controls['VerifyServiceOrganization'].setValue('Yes');
      } else {
        this.newRequestForm.controls['VerifyServiceOrganization'].setValue('No');
      }

      if (this.newRequestForm.controls['PreviousPeriodReviewAvailable'].value == true) {
        this.newRequestForm.controls['PreviousPeriodReviewAvailable'].setValue('Yes');
      } else {
        this.newRequestForm.controls['PreviousPeriodReviewAvailable'].setValue('No');
      }
      if (this.newRequestForm.controls['ExclusiveToClient'].value == true) {
        this.newRequestForm.controls['ExclusiveToClient'].setValue('Yes');
      } else {
        this.newRequestForm.controls['ExclusiveToClient'].setValue('No');
      }
      if (!this.newRequestForm.controls['RequestorEmail'].value) {
        this.newRequestForm.get('RequestorsName')?.setErrors({ required: true });
      }
      if (!this.newRequestForm.controls['BusinessContactEmail'].value) {
        this.newRequestForm.get('BusinessContact')?.setErrors({ required: true });
      }
      if (!this.newRequestForm.controls['AuditSigningPartnerEmail'].value) {  
        this.newRequestForm.get('AuditSigningPartner')?.setErrors({ required: true });
      }
      if (!this.newRequestForm.controls['RequestorsName'].value) {
        this.isSaving = false;
        return;
      }
      this._filter(this.newRequestForm.controls['RequestorsName'].value).pipe(
        switchMap((users: any[]) => {
          if (users.length > 0) {
            //const requestormail = users[0].mail;
            //const requestorDepartment = users[0].department;
            //const requestorTitle = users[0].jobTitle;

            // Seting values of RequestorDepartment and RequestorTitle 
           // this.newRequestForm.controls['RequestorDepartment'].setValue(requestorDepartment);
           // this.newRequestForm.controls['RequestorTitle'].setValue(requestorTitle);

            if (!this.isDatesValidOnNewForm()) {
              this.openAlertDialog('Warning', 'Date is Invalid.');
              this.isSaving = false; // Re-enable the save button
              return of(null); // Return an observable that emits null
            }
            // Check if any file is attached
            if (this.selectedFiles.length === 0) {
              this.openAlertDialog('Warning', 'This request cannot be submitted without the report attached.');
              this.isSaving = false; // Re-enable the save button
              return of(null); // Return an observable that emits null
            }            
            
            // Validate the form
            if (this.newRequestForm.valid) {
              const formData = new FormData();
              Object.keys(this.newRequestForm.controls).forEach(key => {
                const control = this.newRequestForm.get(key);
                if (control) {
                  formData.append(key, key === 'Cost' && (control.value === null || control.value === undefined) ? '0' : control.value);
                }
              });

              // Append all the selected files to the form data
              let isFileTooLarge = false;
              this.selectedFiles.forEach((file) => {
                const fileSizeMB = file.size / (1024 * 1024);
                //if (fileSizeMB > 4) {
                //  isFileTooLarge = true;
                //  return;
                //}
                formData.append('files', file);
              });

              if (isFileTooLarge) {
                this.openAlertDialog('Warning', 'File is too large. Maximum file size is 4MB.');
                this.isSaving = false; // Re-enable the save button
                return of(null); // Return an observable that emits null
              }

              let reportPeriod: any = this.commonHelperService.GetReportPeriodData(this.newRequestForm.controls['ReportPeriodEndDate'].value);
              let requestPriority: any = this.commonHelperService.GetRequestPriorityData(this.newRequestForm.controls['RequestDate'].value, this.newRequestForm.controls['DeliveryDate'].value);
              formData.append('createdBy', this.createdBy);
              formData.append('createdByName', this.createdByName);
              formData.append('created', this.created);
              formData.append('modifiedBy', this.userService.userEmail);
              formData.append('modifiedByName', this.userService.userName);
              formData.append('modified', new Date().toISOString());
              formData.append('ReportPeriod', reportPeriod);
              formData.append('RequestPriority', requestPriority);
              //formData.append('RequestorEmail', requestormail);
              //formData.append('RequestorTitle', requestorTitle);
              //formData.append('RequestorDepartment', requestorDepartment);
              formData.append('FirstPreparerEmail', this.newRequestForm.controls['FirstPreparerEmail'].value);
              formData.append('SecondPreparerEmail', this.newRequestForm.controls['SecondPreparerEmail'].value);
              formData.append('ThirdPreparerEmail', this.newRequestForm.controls['ThirdPreparerEmail'].value);
              formData.append('BusinessContactEmail', this.newRequestForm.controls['BusinessContactEmail'].value);
              formData.append('AuditSigningPartnerEmail', this.newRequestForm.controls['AuditSigningPartnerEmail'].value);

              // Publish value
              const publishValue: boolean = this.newRequestForm.get('Publish')?.value ?? false;
              let params = new HttpParams().set('publish', publishValue.toString());
              params = params.append('ID', Number(this.data.ID));
              //params = params.append('modifiedByName', this.userService.userName );

              // Show spinner
              this.spinnerService.showSpinner();

              // Submit the form
              
                return this.http.post(environment.baseUrl + '/Admin/CreateMasterRecord', formData, { params, responseType: 'text' });
             
            } else {
              // Mark the form as touched to trigger the error message
              this.newRequestForm.markAllAsTouched();
              if (this.newRequestForm.controls['RequestorEmail'].value) {
                this.newRequestForm.get('RequestorsName')?.markAsUntouched();
              }
              if (this.newRequestForm.controls['BusinessContactEmail'].value) {
                this.newRequestForm.get('BusinessContact')?.markAsUntouched();
              }
              if (this.newRequestForm.controls['AuditSigningPartnerEmail'].value) {
                this.newRequestForm.get('AuditSigningPartner')?.markAsUntouched();
              }
              this.isSaving = false; // Re-enable the save button
              return of(null); // Return an observable that emits null
            }
          } else {
            this.openAlertDialog('Warning', 'No users found with the provided Requestor\'s Name.');
            this.isSaving = false; // Re-enable the save button
            return of(null); // Return an observable that emits null
          }
        })
      ).subscribe(response => {
        // Hide spinner
        this.spinnerService.hideSpinner();
        this.isSaving = false; // Re-enable the save button
        if (response != null) {
          if (response?.toLocaleLowerCase() === "record already exists in master list") {
            this.openAlertDialog('Warning', 'Record already exists in Master List');
          } else {
            console.log('Request saved successfully:', response);
            this.appInsightsService.logEvent("Request Form Save:", { Response: response });
            this.dialogRef.close(response);
            // Check if the current page is one of the specified pages
            const specifiedPages = [
              'Report for Allotment',
              'Review in Process',
              'Existing Requests',
              'Lotus Notes',
              'To be billed',
              'dashboard'
            ];
            if (!specifiedPages.includes(this.data.pagename)) {
              this.router.navigate(['/userRequestDashboard']);
            }
          }
        }
      }, error => {
        // Hide spinner
        this.spinnerService.hideSpinner();
        this.isSaving = false; // Re-enable the save button

        console.error('Error saving request:', error);
        this.appInsightsService.logException(error);
        this.openAlertDialog('Warning', 'Failed to save data');
      });      
    } else {
      this.openAlertDialog('Warning', 'If you want to publish please provide ID Number');
      this.isSaving = false; // Re-enable the save button
    }
  }


  openDialog(form: FormGroup, controlName: string): void {
    this.checkClickedWithoutValueWBSEx = false;
    const dialogRef = this.dialog.open(WbsModalComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const control = form.get(controlName);
        if (control) {
          control.setValue((result as WBSRecords).WBSElement);
        }
      }
    });
  }


  searchWBSRecords(form: FormGroup, controlName: string, formtype: string): void {
    if (!form.controls[controlName].value) {
      this.checkClickedWithoutValueWBSEx = true;
    } else {
      this.checkClickedWithoutValueWBSEx = false;
      const wbsControl = form.controls[controlName];
      if (wbsControl && wbsControl.value) {
        this.isWbsExists(wbsControl.value, formtype);
      }

      //const wbsControl = form.get(controlName);
      //if (wbsControl && wbsControl.value) {
      //  const wbselement = wbsControl.value;
      //  const dialogRef = this.dialog.open(WbsModalComponent);
      //  if (typeof wbselement === 'string') {
      //    dialogRef.componentInstance.searchWbsRecords(wbselement);
      //  }

      //  dialogRef.afterClosed().subscribe(result => {
      //    if (result) {
      //      wbsControl.setValue((result as WBSRecords).WBSElement);
      //    }
      //  });
      //}
    }
  }

  openClientDialog(form: FormGroup, controlName: string): void {
    this.checkClickedWithoutValueDeloitteClientEx = false;
    const dialogRef = this.dialog.open(ClientModalComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const control = form.get(controlName);
        if (control) {
          control.setValue((result as ClientRecords).ClientName);
        }
      }
    });
  }

  searchClientRecords(form: FormGroup, controlName: string): void {
    if (!form.controls[controlName].value) {
      this.checkClickedWithoutValueDeloitteClientEx = true;
    } else {
      this.checkClickedWithoutValueDeloitteClientEx = false;
      const clientControl = form.get(controlName);
      if (clientControl && clientControl.value) {
        const clientelement = clientControl.value;
        const dialogRef = this.dialog.open(ClientModalComponent);
        if (typeof clientelement === 'string') {
          dialogRef.componentInstance.searchClientRecords(clientelement);
        }

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            clientControl.setValue((result as ClientRecords).ClientName);
          }
        });
      }
    }
  }

  public updateAdminViewExistingRequestReviewList = (requestParameters: any, ID: number, createdByEmail: string) => {
    let params = new HttpParams();
    params = params.append('createdByEmail', createdByEmail.toString());
    //params = params.append('modifiedByName', modifiedByName.toString())
    params = params.append('ID', ID);

    return this.http.post(environment.baseUrl + '/Admin/updateAdminViewExistingRequestReviewList', requestParameters, { params: params }).pipe(
      tap({
        next: response => {
          this.appInsightsService.logEvent('Existing Request Form Save:', { Response: response });
        },
        error: error => {
          alert(error.error);
          this.appInsightsService.logException(error);
          this.appInsightsService.logEvent('There was an error while fetching data!', error);
        }
      })
    );
  }
  isDateValid(selectedDate: any,controlName:string): any {
    const isValidDate=this.commonHelperService.ValidateDates(selectedDate);
    if(controlName=="OpinionDate"){
      this.dateObjectValid.optionalDate=isValidDate;
    }
    else if(controlName=="SRCD"){
      this.dateObjectValid.SRCD=isValidDate;
    }
   else if(controlName=="FRCD"){
    this.dateObjectValid.FRCD=isValidDate;
    }
    else if(controlName=="FRRD"){
      this.dateObjectValid.FRRD=isValidDate;
    }
    else if(controlName=="AllottedDate"){
      this.dateObjectValid.allottedDate=isValidDate;
    }
   else if(controlName=="needByDateEx"){
    this.dateObjectValid.needByDateEx=isValidDate;
    }
    else if(controlName=="requestDateEx"){
      this.dateObjectValid.reqDateEx=isValidDate;
    }
    else if(controlName=="NeedByDate"){
      this.dateObjectValid.needByDate=isValidDate;
    }
   else if(controlName=="requestDate"){
    this.dateObjectValid.reqDate=isValidDate;
    }
    else if(controlName=="deliveryDateEx"){
      this.dateObjectValid.deliveryDateEx=isValidDate;
    }
    else if(controlName=="DeliveryDate"){
      this.dateObjectValid.deliveryDate=isValidDate;
    }
   else if(controlName=="ReportPeriodEndDate"){
    this.dateObjectValid.reportPeriodEndDate=isValidDate;
    }
    else if(controlName=="ReportPeriodStartDate"){
      this.dateObjectValid.reportPeriodStartDate=isValidDate;
    }
    return isValidDate;
  }
  isDatesValidOnNewForm(){
    return !this.dateObjectValid.optionalDate && !this.dateObjectValid.SRCD && !this.dateObjectValid.FRCD && !this.dateObjectValid.FRRD && !this.dateObjectValid.allottedDate && !this.dateObjectValid.needByDate && !this.dateObjectValid.reqDate && !this.dateObjectValid.deliveryDate && !this.dateObjectValid.reportPeriodEndDate && !this.dateObjectValid.reportPeriodStartDate;
  }
  isDatesValidOnExistingForm(){
    return  !this.dateObjectValid.needByDateEx && !this.dateObjectValid.reqDateEx && !this.dateObjectValid.deliveryDateEx;
  }
  onFileSelected(event: Event):any {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files) {
         //Validate the file Extension
    let isExtValid=this.commonHelperService.IsFileExtensionValid(inputElement.files[0].name);
    //Validatate the Attachment
    if(isExtValid){
     let isFileNameInValid=this.commonHelperService.IsFileNameInValid(inputElement.files[0].name);
     if(isFileNameInValid){
      this.openAlertDialog('Warning',this.commonHelperService.fileNameInvalidMsg,"1");
      inputElement.value = '';
      return false;
     }
    }
    else{
      this.openAlertDialog('Warning','Invalid file format. Please upload a valid file format.');
      inputElement.value = '';
      return false;
    }
      // Add the newly selected files to the existing ones
      this.selectedFiles.push(...Array.from(inputElement.files));
      // Clear the input to allow selecting the same file again if needed
      inputElement.value = '';
    }
  }
  //onFileSelectedEx(event: Event):any {
  //  const inputElementEx = event.target as HTMLInputElement;
  //  if (inputElementEx.files) {
  //       //Validate the file Extension
  //  let isExtValid=this.commonHelperService.IsFileExtensionValid(inputElementEx.files[0].name);
  //  //Validatate the Attachment
  //  if(isExtValid){
  //   let isFileNameInValid=this.commonHelperService.IsFileNameInValid(inputElementEx.files[0].name);
  //   if(isFileNameInValid){
  //    this.openAlertDialog('Warning',this.commonHelperService.fileNameInvalidMsg,"1");
  //    return false;
  //   }
  //  }
  //  else{
  //    this.openAlertDialog('Warning','Invalid file format. Please upload a valid file format.');
  //    return false;
  //  }
  //    // Add the newly selected files to the existing ones
  //    this.selectedFilesEx.push(...Array.from(inputElementEx.files));
  //    // Clear the input to allow selecting the same file again if needed
  //    inputElementEx.value = '';
  //  }
  //}

  removeFile(index: number) {
    this.selectedFiles.splice(index, 1);
  }
  removeFileEx(index: number) {
    this.selectedFilesEx.splice(index, 1);
  }
  downloadSelectedFile(fileName:any)
  {
    this.http.get(environment.baseUrl + '/Email/DownloadFile/' + this.data.ID+'/'+fileName,{ responseType: 'arraybuffer' })
    .subscribe({
      next:(content:any) => {
        const blob = new Blob([content]);
        const fileObj = new File([blob], fileName);
        this.downloadFile(fileObj);
      },
      error:err => {
        this.appInsightsService.logEvent('Error fetching files', err);
      }
    }
      
    );
  }

  downloadFile(file:File)
  {
    let url = window.URL.createObjectURL(file);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = file.name;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  
  fetchFiles() {
    this.selectedFiles = [];
    this.selectedFilesEx = [];
    this.http.get<any>(environment.baseUrl + '/Email/FetchFiles/' + this.data.ID)
      .subscribe(
        files => {
          if (files && Array.isArray(files)) {
            files.forEach(file => {
              const blob = new Blob([]);
              const fileObj = new File([blob], file);
              // Check if the file with the same name already exists in selectedFiles
              if (!this.selectedFiles.some(f => f.name === fileObj.name)) {
                this.selectedFiles.push(fileObj);
              }

              // Check if the file with the same name already exists in selectedFilesEx
              if (!this.selectedFilesEx.some(f => f.name === fileObj.name)) {
                this.selectedFilesEx.push(fileObj);
              }
            });
          } else {
            this.appInsightsService.logEvent('Error: files is not an array', files);
          }
        },
        error => {
          this.appInsightsService.logEvent('Error fetching files', error);
        }
      );
  }
  getCorssMarkStylesExistingRequest(field:string):any{
    let selectedData="";
      selectedData= this.existingRequestForm.controls[field as keyof typeof this.existingRequestForm.controls].value?.toString();
    selectedData=selectedData==null||selectedData==undefined?"":selectedData;
    return this.commonHelperService.getCorssMarkStyles(selectedData);
    }
    getCorssMarkStylesNewRequest(field:string):any{
      let selectedData="";
        selectedData= this.newRequestForm.controls[field as keyof typeof this.newRequestForm.controls].value?.toString();
      selectedData=selectedData==null||selectedData==undefined?"":selectedData;
      return this.commonHelperService.getCorssMarkStyles(selectedData);
  }
  onRadioButtonChange(value: string): void {
    // Reset other values if needed when radio button changes
    if (value === 'DropDown')
    {
      // Clear the textbox value when switching to dropdown
      //this.newRequestForm.get('SARAuthoritativeGuidanceText')?.reset();
      this.newRequestForm.get('SARAuthoritativeGuidanceChecked')?.setValue('DropDown');
    }
    else if (value === 'Text')
    {
      // Clear the dropdown value when switching to textbox
      //this.newRequestForm.get('SARAuthoritativeGuidanceDropDown')?.reset();
      this.newRequestForm.get('SARAuthoritativeGuidanceChecked')?.setValue('Text');
    }
  } onSelectChange(value: string): void
  {   // When a dropdown item is selected, update the radio button selection
    this.newRequestForm.get('SARAuthoritativeGuidanceChecked')?.setValue('DropDown');
  }
  OnTextFocus(): void {
    this.newRequestForm.patchValue({ SARAuthoritativeGuidanceChecked: 'Text' });
  }

}
