import { Injectable } from '@angular/core';
import { PublicClientApplication, AuthError } from '@azure/msal-browser';
import { Client } from '@microsoft/microsoft-graph-client';
import { environment } from '../environments/environment';
import { AppInsightsService } from './app-insights.service';

const msalConfig = {
  auth: {
    clientId: environment.clientId,
    authority: environment.authority,
    redirectUri: environment.redirectUri
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

@Injectable({
  providedIn: 'root',
})

export class AuthService {
  public app: PublicClientApplication;
  public isMsalInitialized: boolean = false;

  constructor(private appInsightsService: AppInsightsService) {
    this.app = new PublicClientApplication(msalConfig);
    this.initializeMsal();
  }

  public async initializeMsal(): Promise<void> {
    try {
      await this.app.initialize();
      const response = await this.app.handleRedirectPromise();
      if (response !== null) {
        this.app.setActiveAccount(response.account);
      } else {
        const accounts = this.app.getAllAccounts();
        if (accounts.length > 0) {
          this.app.setActiveAccount(accounts[0]);
        }
      }
      this.isMsalInitialized = true;
    } catch (error: any) {
      this.appInsightsService.logException(error);
      this.appInsightsService.logEvent('MSAL initialization error:', error);
    }
  }

  private timeoutId: any;
   
  async login(): Promise<void> {
    if (!this.isMsalInitialized) {
      await this.initializeMsal();
    }

    // Check if the user is already authenticated
    const accounts = this.app.getAllAccounts();
    if (accounts.length > 0) {
      this.appInsightsService.logEvent('User is already authenticated');
      return;
    }

    try {
      const loginResponse:any = await this.app.loginRedirect({
        scopes: ['User.Read'],
      });
      this.appInsightsService.logEvent('Login successful', loginResponse);
    }
    catch (error: any) {
      if (error instanceof AuthError) {
        this.appInsightsService.logException(error);
        this.appInsightsService.logEvent('Authentication error:', error);
      }
      else {
        this.appInsightsService.logException(error);
        this.appInsightsService.logEvent('Unexpected error during login:', error);
      }
    }
  }

  async getUserDetails(): Promise<any> {
    try {
      let account = this.app.getActiveAccount();
      if (account) {
        let client = Client.init({
          authProvider: async (done) => {
            let token = await this.app.acquireTokenSilent({
              scopes: ['User.Read', 'User.ReadBasic.All'],
              account: account
            });
            done(null, token.accessToken);
          }
        });
        let userDetails = await client.api('/me').get();
        let userPhoto;
        try {
          userPhoto = await client.api('/me/photo/$value').get();
        } catch (error: any) {
            userPhoto = null;
        }
        return { userDetails, userPhoto};
      }
    } catch (error: any) {
      this.appInsightsService.logException(error);
      this.appInsightsService.logEvent('Error getting user details:', error);
    }
  }

  async getUsers(): Promise<any> {
    try {
    let account = this.app.getActiveAccount();
    if (account) {
      let client = Client.init({
        authProvider: async (done) => {
          let token = await this.app.acquireTokenSilent({
            scopes: ['User.Read.All'],
            account: account
          });
          done(null, token.accessToken);
        }
      });

      let users: any[] = [];
      let domain = 'deloitte.com'; 
      let url = `/users?$count=true&$filter=endsWith(mail, '${domain}')`;
      let response = await client.api(url)
        .header("ConsistencyLevel", "eventual")
        .get();
      users = users.concat(response.value);

      return users;
    }
    } catch (error: any) {
        this.appInsightsService.logException(error);
        this.appInsightsService.logEvent('Error getting users:', error);
      }
  }

  async searchUsers(query: string): Promise<any> {
    let account = this.app.getActiveAccount();
    if (account) {
      let client = Client.init({
        authProvider: async (done) => {
          let token = await this.app.acquireTokenSilent({
            scopes: ['User.Read.All'],
            account: account
          });
          done(null, token.accessToken);
        }
      });

      let domain = 'deloitte.com'; // replace with your company's domain
      let url = `/users?$count=true&$filter=(startsWith(displayName, '${query}') or startsWith(mail, '${query}') or startsWith(surname, '${query}') or startsWith(givenName, '${query}')) `;

      let response = await client.api(url)
        .header("ConsistencyLevel", "eventual")
        .get();
      return response.value;
    }
  }



}

